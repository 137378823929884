/** @format */

import React from "react";
import { Element } from "react-scroll";
import Tooltip from "antd/es/tooltip";
import { openNotificationWithIcon } from "../../../Components/Notification/Success.tsx";
import { useDispatch, useSelector } from "react-redux";
import LazyImage from "./LazyImage.jsx";

const ProductListing = ({
  checkOutOfStock,
  setActiveFood,
  addToCartHandler,
  productsWithCategories,
  setIsProductDetailModalOpen,
  isSearchList,
  setReserverTableModalOpen,
  homePageData,
  alertMessage,
}) => {
  const dispatch = useDispatch();
  const currencyCode = useSelector(
    (state) => state.storeReducer.storeData.currencyCode
  );

  return (
    <div className="main-body">
      <div className="container-fluid">
        {alertMessage && (
          <div
            class="alert alert2 alert-danger mt-2 d-flex align-items-center justify-content-between"
            role="alert"
          >
            <span className="reserve-message">
              {" "}
              {homePageData.reservationMessage}
            </span>

            <p
              class="btn-first green-btn text-custom-white fw-500 enqbtn"
              onClick={() => {
                setReserverTableModalOpen(true);
              }}
            >
              Reserve Table
            </p>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <section className="pt-2">
              <div className="">
                <div className="row">
                  <div className="col-md-12">
                    <div className="scroll_tab">
                      <div
                        className="tab-content restro-content"
                        // style={{ display: "none" }}
                      >
                        {productsWithCategories?.map((item, index) => {
                          if (!isSearchList) {
                            return (
                              <Element
                                key={index}
                                id={item.categoryId}
                                name={item.categoryId}
                                className="tab-pane"
                              >
                                <div
                                  className=" fooditems card-title"
                                  id={item.categoryId}
                                >
                                  <div className="section-header-left mb-3">
                                    <h4
                                      className="text-dark header-title fw-bold"
                                      id={index}
                                    >
                                      {item.categoryName}
                                    </h4>
                                  </div>

                                  <div className="row">
                                    {item.products.map((item, index) => {
                                      if (item?.productVariations?.length > 0) {
                                        return (
                                          <div
                                            className="col-md-4 col-6 col-lg-2 col-xxl-2 "
                                            key={index}
                                          >
                                            <div className="product-box p-0 mb-xl-20 border-0 product-card ">
                                              <a
                                                onClick={() => {
                                                  setActiveFood(item);
                                                  // setIsProductDetailModalOpen(
                                                  //   true
                                                  // );
                                                }}
                                              >
                                                <div className="product-img">
                                                  <LazyImage
                                                    src={item.productImage}
                                                    alt="product-img"
                                                    className="img-fluid full-width"
                                                    placeholder={
                                                      "https://via.placeholder.com/300x300.png?text=Loading..."
                                                    }
                                                  />
                                                </div>
                                              </a>
                                              <div className="product-caption ">
                                                <div className="title-box">
                                                  <h6 className="product-title">
                                                    <a
                                                      href=""
                                                      className="text-light-black "
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#foodModal"
                                                    >
                                                      {" "}
                                                      {item.productName
                                                        ?.length > 20 ? (
                                                        <Tooltip
                                                          title={
                                                            item.productName
                                                          }
                                                        >
                                                          {item.productName?.substring(
                                                            0,
                                                            20
                                                          ) + "..."}
                                                        </Tooltip>
                                                      ) : (
                                                        item.productName?.substring(
                                                          0,
                                                          20
                                                        )
                                                      )}
                                                    </a>
                                                  </h6>
                                                  <Tooltip title="Add To Cart">
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          checkOutOfStock(item)
                                                            ?.isOutOfStock
                                                        ) {
                                                          openNotificationWithIcon(
                                                            "info",
                                                            "Out of Order.Please click item for other variations of product "
                                                          );
                                                          return;
                                                        }
                                                        addToCartHandler(item);
                                                      }}
                                                      className="tags"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <i className="fas fa-shopping-cart fs-base ms-1 text-danger" />
                                                    </div>
                                                  </Tooltip>
                                                </div>
                                                <p className="text-light-white description-product">
                                                  {item.productDescription
                                                    ?.length > 20 ? (
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          item.productDescription?.substring(
                                                            0,
                                                            25
                                                          ) + "...",
                                                      }}
                                                    />
                                                  ) : (
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html: item.productDescription?.substring(
                                                          0,
                                                          25
                                                        ),
                                                      }}
                                                    />
                                                  )}
                                                </p>

                                                <div className="d-flex align-items-center justify-content-between mt-2">
                                                  <p className="aud">
                                                    {currencyCode}{" "}
                                                    {
                                                      item.productVariations?.find(
                                                        (item) => item.isDefault
                                                      )?.price
                                                    }
                                                    {item.productVariations?.find(
                                                      (item) => item.isDefault
                                                    ).actualPrice !==
                                                      item.productVariations?.find(
                                                        (item) => item.isDefault
                                                      )?.price && (
                                                      <span class="text-stroke">
                                                        <s>
                                                          {" "}
                                                          {currencyCode}{" "}
                                                          {
                                                            item.productVariations?.find(
                                                              (item) =>
                                                                item.isDefault
                                                            )?.actualPrice
                                                          }
                                                        </s>{" "}
                                                      </span>
                                                    )}
                                                    <span className="text-muted fw-normal">
                                                      <small>
                                                        {" "}
                                                        {"(" +
                                                          item.productVariations?.find(
                                                            (item) =>
                                                              item.isDefault
                                                          )?.name +
                                                          ")"}
                                                      </small>
                                                    </span>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                </div>
                              </Element>
                            );
                          } else {
                            return (
                              <div
                                className=" fooditems card-title"
                                id={item.categoryId}
                              >
                                <div className="section-header-left mb-3">
                                  <h4
                                    className="text-dark header-title fw-bold"
                                    id={index}
                                  >
                                    {item.categoryName}
                                  </h4>
                                </div>

                                <div className="row">
                                  {item.products.map((item, index) => {
                                    if (item?.productVariations?.length > 0) {
                                      return (
                                        <div
                                          className="col-md-4 col-6 col-lg-3 col-xxl-3"
                                          key={index}
                                        >
                                          <div className="product-box mb-xl-20 border-0 product-card">
                                            <a
                                              onClick={() => {
                                                setActiveFood(item);
                                                setIsProductDetailModalOpen(
                                                  true
                                                );
                                                dispatch({
                                                  type: "TOGGLE_SEARCH_MODAL",
                                                });
                                              }}
                                            >
                                              <div className="product-img">
                                                <LazyImage
                                                  src={item.productImage}
                                                  alt="product-img"
                                                  className="img-fluid full-width"
                                                  placeholder={
                                                    "https://via.placeholder.com/300x300.png?text=Loading..."
                                                  }
                                                />
                                              </div>
                                            </a>
                                            <div className="product-caption">
                                              <div className="title-box">
                                                <h6 className="product-title">
                                                  <a
                                                    href=""
                                                    className="text-light-black "
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#foodModal"
                                                  >
                                                    {" "}
                                                    {item.productName}
                                                  </a>
                                                </h6>
                                                <Tooltip title="Add To Cart">
                                                  <div
                                                    onClick={() => {
                                                      if (
                                                        checkOutOfStock(item)
                                                          ?.isOutOfStock
                                                      ) {
                                                        openNotificationWithIcon(
                                                          "info",
                                                          "Out of Order.Please click item for other variations of product "
                                                        );
                                                        return;
                                                      }
                                                      addToCartHandler(item);
                                                    }}
                                                    className="tags"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <i className="fas fa-shopping-cart fs-base ms-1 text-danger" />
                                                  </div>
                                                </Tooltip>
                                              </div>
                                              <p className="text-light-white description-product">
                                                {item.productDescription
                                                  ?.length > 20 ? (
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        item.productDescription?.substring(
                                                          0,
                                                          25
                                                        ) + "...",
                                                    }}
                                                  />
                                                ) : (
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: item.productDescription?.substring(
                                                        0,
                                                        25
                                                      ),
                                                    }}
                                                  />
                                                )}
                                              </p>
                                              <div className="d-flex align-items-center justify-content-between mt-2">
                                                <p className="aud">
                                                  {currencyCode}{" "}
                                                  {
                                                    item.productVariations?.find(
                                                      (item) => item.isDefault
                                                    ).price
                                                  }
                                                  {item.productVariations?.find(
                                                    (item) => item.isDefault
                                                  ).actualPrice !==
                                                    item.productVariations?.find(
                                                      (item) => item.isDefault
                                                    ).price && (
                                                    <span class="text-stroke">
                                                      <s>
                                                        {" "}
                                                        {currencyCode}{" "}
                                                        {
                                                          item.productVariations?.find(
                                                            (item) =>
                                                              item.isDefault
                                                          ).actualPrice
                                                        }
                                                      </s>{" "}
                                                    </span>
                                                  )}
                                                  <span className="text-muted fw-normal">
                                                    <small>
                                                      {" "}
                                                      {"(" +
                                                        item.productVariations?.find(
                                                          (item) =>
                                                            item.isDefault
                                                        ).name +
                                                        ")"}
                                                    </small>
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListing;
