/** @format */

import { Typography } from "antd";
import React from "react";

const OrderItemSpiceChoice = ({ product }) => {
  return (
    <>
      {product?.productSpiceChoices?.name && (
        <>
          {" "}
          <Typography.Text
            type="success"
            className="me-2 fw-bold"
            style={{ fontSize: "13px", lineHeight: "1" }}
          >
            Spice Choice :
          </Typography.Text>
          <Typography.Text
            type="success"
            style={{ fontSize: "13px", lineHeight: "1" }}
          >
            {product?.productSpiceChoices?.name}
          </Typography.Text>
        </>
      )}
    </>
  );
};

export default OrderItemSpiceChoice;
