/** @format */

const Subscribe = ({ homePageData, isLoadingHome, setSubscribeModalOpen }) => {
  return (
    <>
      {" "}
      <div>
        <div>
          <div className="row text-center">
            {homePageData?.popUps?.imageUrl ? (
              <img src={homePageData?.popUps?.imageUrl} alt="popup-image" />
            ) : (
              <>
                <div className="col-md-12 ">
                  {/* <img src="/assets_bakery/images/pop.jpeg" class="img-fluid" alt=""> */}

                  <h3 className="text-center ">
                    {homePageData?.popUps?.title}
                  </h3>
                  <br />
                </div>
                <br />
                <p
                  style={{ color: "#3D3D4E" }}
                  className=" text-center fs-6 mb-3 "
                >
                  {homePageData?.popUps?.description}
                </p>
                <span className="input-group-btn" data-bs-dismiss="modal">
                  {homePageData?.popUps?.buttonLink == "" ||
                  homePageData?.popUps?.buttonLink == "#" ? (
                    <a
                      className="btn btn-success"
                      onClick={() => {
                        setSubscribeModalOpen(false);
                      }}
                    >
                      {" "}
                      {homePageData?.popUps?.buttonLabel}
                    </a>
                  ) : (
                    <a
                      target=" _blank"
                      // rel="noopener noreferrer"
                      href={homePageData?.popUps?.buttonLink}
                      className="btn btn-success"
                    >
                      {" "}
                      {homePageData?.popUps?.buttonLabel}
                    </a>
                  )}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
