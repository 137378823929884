/** @format */

import { Form, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Button, Alert } from "antd";

const ForgotPassword = ({ setIsForgotPasswordOpen }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { showErrorOnForm, message } = useSelector(
    (state) => state.authentication
  );
  const authenticationState = useSelector((state) => state.authentication);

  const onForgotPasswordFromSubmit = (values) => {
    dispatch({
      type: "FORGOT_PASSWORD_REQUEST",
      payload: values,
    });
  };

  useEffect(() => {
    if (authenticationState.isSendLinkSuccess) {
      form.resetFields();
    }
  }, [authenticationState.isSendLinkSuccess]);
  return (
    <div className="forgot-password">
      <Form
        className="login"
        name="form"
        onFinish={onForgotPasswordFromSubmit}
        autoComplete="off"
        initialValues={{
          remember: true,
        }}
        form={form}
      >
        {authenticationState.isSendLinkSuccess && (
          <Alert
            message="Password change link has been sent to you mail !"
            type="success"
            showIcon={true}
          />
        )}
        {showErrorOnForm && (
          <Alert
            message={message}
            type="error"
            showIcon={true}
            style={{ marginBottom: "1rem" }}
          />
        )}
        <Form.Item
          label="Email Address"
          name="Email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
            {
              type: "email",
              message: "Please enter valid email!",
            },
          ]}
        >
          <Input placeholder="Enter Your Email" class="form-control" />
        </Form.Item>

        <Form.Item
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Button
            loading={authenticationState.isLoading}
            data-bs-dismiss={authenticationState.isLoggedIn ? "modal" : ""}
            type="primary"
            htmlType="submit"
            style={{
              height: "35px",
              marginRight: "7px",
              border: "none",
              marginTop: "0.4rem",
            }}
            className="primary-button"
          >
            Submit
          </Button>
          <Button
            className="secondary-button"
            style={{
              height: "35px",
              border: "none",
              marginTop: "0.4rem",
            }}
            onClick={() => {
              setIsForgotPasswordOpen(false);
            }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ForgotPassword;
