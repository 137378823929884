import React from "react";
import { Button as B } from "antd";
import "./Button.scss";

const Button = (props) => {
  return (
    <B
      {...props}
      onClick={props.onClick}
      type="primary"
      className={`custom-primary-button ${props.className}`}
      icon={props.icon}
      loading={props.loading}
      size={props.size}
    >
      {props.children}
    </B>
  );
};

export default Button;
