/** @format */

import { LeftCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";
import { useEffect } from "react";
import { Alert } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import {
  validateAustralianNumber,
  validateNumber,
} from "../../Helpers/CreditCardValidation";
import { sectionConstants } from "./AddToCart";
const { Option } = Select;

const prefixSelector = (countryList) => (
  <Form.Item name="prefix" noStyle>
    <Select
      style={{
        width: 120,
      }}
    >
      {countryList?.length > 0 &&
        countryList?.map((item) => (
          <Option value={item.additionalValue} key={item.id}>
            {" "}
            <span style={{ fontSize: "14px" }}>
              <img
                src={item.image}
                style={{
                  height: "20px",
                  width: "30px",
                  marginRight: "4px",
                }}
              />
              {item.additionalValue}
            </span>
          </Option>
        ))}
    </Select>
  </Form.Item>
);

export const OTPForm = ({
  setSectionToShow,
  navigateAfterPhoneValidationGuestCheckout,
}) => {
  const dispatch = useDispatch();
  const {
    validatePhoneNumberLoading,
    isOTPValidationSuccessful,
    validatePhoneNumberFailureMessage,
    isResendOTPPhoneNumberValidationSuccess,
    sendOtpPhoneNumberLoading,
  } = useSelector((state) => state.authentication);

  const [validatePhoneNumberForm] = Form.useForm();

  const validateFormHandler = (values) => {
    dispatch({
      type: "VALIDATE_PHONE_NUMBER_REQUEST",
      payload: {
        PhoneNumber: localStorage.getItem("phoneNumber"),
        Code: values.code,
        PhoneNumberPrefix: localStorage.getItem("phoneNumberPrefix"),
      },
    });
  };

  useEffect(() => {
    if (isOTPValidationSuccessful) {
      setSectionToShow(sectionConstants.cartItmes);
      dispatch({
        type: "CLEAR_SEND_OTP_PHONE_NUMBER_SUCCESSFUL",
      });
      navigateAfterPhoneValidationGuestCheckout();
    }
  }, [isOTPValidationSuccessful]);
  return (
    <div className="pt-3 ps-3 pe-3 mb-2">
      <Form onFinish={validateFormHandler}>
        <LeftCircleOutlined
          onClick={() => setSectionToShow(sectionConstants.phonerNumberForm)}
          style={{ fontSize: 28, cursor: "pointer" }}
          className="mb-3 cursor-pointer"
        />{" "}
        <h5 class="fw-bold">Please enter Code ?</h5>
        {validatePhoneNumberFailureMessage ? (
          <Alert variant="danger" className="mt-3">
            {validatePhoneNumberFailureMessage}
          </Alert>
        ) : (
          <Alert variant="info" className="mt-3">
            Code has been {isResendOTPPhoneNumberValidationSuccess && "Re-"}{" "}
            sent to your number. The code will be valid for the next 2 minutes.
          </Alert>
        )}
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please enter  code !",
            },
            { validator: validateNumber },
          ]}
          name={"code"}
          label="Code"
          className="mt-3"
        >
          <OtpInput
            className="verification-container"
            inputStyle={"ant-input"}
            // value={otp}
            // onChange={(e) => setOTP(e)}
            numInputs={6}
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={validatePhoneNumberLoading}
          className="primary-button"
          style={{
            border: "none",
            color: "white",
          }}
        >
          Validate Code
        </Button>
        <small
          className="ms-2 add-items-button rounded corner"
          style={{ padding: "5px 15px", cursor: "pointer" }}
          onClick={() => {
            dispatch({
              type: "SEND_OTP_PHONE_NUMBER_VALIDATION_REQUEST_RESEND",
              payload: {
                PhoneNumber: localStorage.getItem("phoneNumber"),
                PhoneNumberPrefix: localStorage.getItem("phoneNumberPrefix"),
                isResend: true,
              },
            });
          }}
        >
          {sendOtpPhoneNumberLoading ? "Loading..." : "Resend Code"}
        </small>
      </Form>
    </div>
  );
};

const PhoneNumberForm = ({ setSectionToShow }) => {
  const dispatch = useDispatch();
  const {
    sendOtpPhoneNumberLoading,
    isSendOtpPhoneNumberSuccessful,
    sendOtpFailureMessage,
  } = useSelector((state) => state.authentication);

  const countryList = useSelector(
    (state) => state.storeReducer.homePageData?.listCountry
  );

  const [sendOTPForm] = Form.useForm();

  const sendOTPHandler = (values) => {
    localStorage.setItem("phoneNumber", values.phoneNumber);
    localStorage.setItem("phoneNumberPrefix", values.prefix);
    dispatch({
      type: "SEND_OTP_PHONE_NUMBER_VALIDATION_REQUEST",
      payload: {
        PhoneNumber: values.phoneNumber,
        PhoneNumberPrefix: values.prefix,
      },
    });
  };

  useEffect(() => {
    sendOTPForm.setFields([
      {
        name: "prefix",
        value: countryList?.find((item) => item.isSelected)?.additionalValue,
      },
    ]);
  }, []);

  useEffect(() => {
    if (isSendOtpPhoneNumberSuccessful) {
      dispatch({
        type: "CLEAR_SEND_OTP_PHONE_NUMBER_SUCCESSFUL",
      });
      setSectionToShow(sectionConstants.otpForm);
    }
  }, [isSendOtpPhoneNumberSuccessful]);

  return (
    <div className="pt-3 ps-3 pe-3 mb-2">
      <Form form={sendOTPForm} onFinish={sendOTPHandler}>
        <LeftCircleOutlined
          onClick={() => setSectionToShow(sectionConstants.cartItmes)}
          style={{ fontSize: 28, cursor: "pointer" }}
          className="mb-3 cursor-pointer"
        />{" "}
        <h5 class="fw-bold">What's Your Phone Number ?</h5>
        {sendOtpFailureMessage && (
          <Alert variant="info" className="mt-3">
            {sendOtpFailureMessage}
          </Alert>
        )}
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please enter mobile number !",
            },
            // { validator: validateNumber },
            { validator: validateAustralianNumber },
          ]}
          name={"phoneNumber"}
          label="Mobile Number"
          className="mt-3"
        >
          <Input
            addonBefore={prefixSelector(countryList)}
            placeholder="Enter your mobile number"
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={sendOtpPhoneNumberLoading}
          className="primary-button"
          style={{
            border: "none",
            color: "white",
          }}
        >
          Send Code
        </Button>
      </Form>
    </div>
  );
};

export default PhoneNumberForm;
