import { Checkbox, Typography } from "antd";
import React from "react";

const ProductRemoveIngredients = ({
  removedProductIngredients,
  setRemovedProductIngredients,
  selectedProductVariation,
}) => {
  const isIngredientRemoved = (ingredient) => {
    return removedProductIngredients?.some(
      (ri) => JSON.parse(ri).name === ingredient.name
    );
  };
  return (
    <div className="row">
      <p className="d-block fw-bold mb-2 light-color-text">
        Remove Ingredients
      </p>
      <div className="d-block  fs-6 ">
        <Checkbox.Group
          options={selectedProductVariation?.productIngredients?.map(
            (item) => ({
              label: (
                <Typography.Text delete={isIngredientRemoved(item)}>
                  {item.name}
                </Typography.Text>
              ),
              value: JSON.stringify({
                name: item.name,
              }),
            })
          )}
          value={removedProductIngredients} // Use [0] to get the single selected value
          onChange={(value) => setRemovedProductIngredients(value)}
          className="spice-choices-container"
        />
      </div>
    </div>
  );
};

export default ProductRemoveIngredients;
