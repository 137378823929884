/** @format */

import React, { useState, useEffect } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorage, isAuth } from "../../Helpers/FrontendHelper";
import { Button, Alert } from "antd";
function AddAddress({ isGuest, value, setValue, setIsAddressModalVisible }) {
  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);
  const storeState = useSelector(
    (state) => state.storeReducer.storeData.templateName
  );
  const addDeliveryLocationLoading = useSelector(
    (state) => state.checkout.addDeliveryLocationLoading
  );
  const { showErrorOnForm, message } = useSelector((state) => state.checkout);

  const isAddDeliverySuccess = useSelector(
    (state) => state.checkout.isAddDeliverySuccess
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const userId = getLocalStorage(`${storeId}-${storeState}`)?.userInformation
    ?.userId;
  const dispatch = useDispatch();
  const handleAddAddress = () => {
    if (!value.DeliveryLocation) {
      return;
    }
    if (!isAuth(storeId)) {
      dispatch({
        type: "DELIVERY_LOCATION_ADD_REQUEST_GUEST",
        payload: {
          Id: value.Id,
          storeId,
          Latitude: String(value.Latitude),
          Longitude: String(value.Longitude),
          DeliveryLocation: value.DeliveryLocation,
        },
        isGuest: true,
      });
      return;
    }
    dispatch({
      type: "DELIVERY_LOCATION_ADD_REQUEST",
      payload: {
        userId: userId,
        Id: value.Id,
        storeId,
        Latitude: String(value.Latitude),
        Longitude: String(value.Longitude),
        DeliveryLocation: value.DeliveryLocation,
      },
    });
  };
  useEffect(() => {
    if (isAddDeliverySuccess) {
      setIsAddressModalVisible(false);
    }
  }, [isAddDeliverySuccess]);
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="deliveryModalLabel">
          Add New Address
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setIsAddressModalVisible(false);
          }}
        />
      </div>
      <div className="modal-body">
        <div className="form-details">
          <div className="row text-start">
            <div className="col-md-12">
              {showErrorOnForm && (
                <Alert
                  message={message}
                  type="error"
                  showIcon={true}
                  style={{ marginBottom: "1rem" }}
                />
              )}
              <div className=" fw-bold">
                <label htmlFor="">Location</label>
                <div className="input-group w-100">
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyDqRwktb0WF6d7KbMg-208CyZi1h99gSMg"
                    className="form-control"
                    selectProps={{
                      onChange: (val) => {
                        geocodeByAddress(val.label)
                          .then((results) => getLatLng(results[0]))
                          .then(({ lat, lng }) =>
                            setValue({
                              Id: "",
                              Latitude: lat,
                              Longitude: lng,
                              DeliveryLocation: val.label,
                            })
                          );
                      },
                    }}
                  />
                </div>
                {!value.DeliveryLocation && isFormSubmitted && (
                  <span style={{ color: "Red" }}>
                    Please Choose Delivery Address !
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 text-end">
        <Button
          type="primary"
          className="primary-button"
          htmlType="submit"
          loading={addDeliveryLocationLoading}
          onClick={() => {
            setIsFormSubmitted(true);
            handleAddAddress();
          }}
          style={{
            border: "none",
            color: "white",
            marginRight: "1rem",

            marginBottom: "5px",
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default AddAddress;
