/** @format */

import { getLocalStorage, setLocalStorage } from "../../Helpers/FrontendHelper";

const initialState = {
  isLoading: false,
  userProfileData: {},
  isOperationSuccessful: false,
  userOrderHistory: {},
  orderDetail: {},
  activeTab: "first",
  updateUserDetailLoading: false,
  getOrderDetailLoading: false,
  show: false,
  type: "",
  message: "",
  description: "",
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "CLOSE_NOTIFICATION":
      return {
        ...state,
        show: false,
        type: "",
        message: "",
      };

    case "GET_USER_DETAIL_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_USER_DETAIL_SUCCESS":
      const localData = getLocalStorage(
        `${payload.storeId}-${payload.storeState}`
      );
      //
      setLocalStorage(`${payload.storeId}-${payload.storeState}`, {
        ...localData,
        userInformation: {
          ...localData.userInformation,
          fullName: payload.data.name,
          normalImage: payload.data.image,
          email: payload.data.email,
        },
      });
      return {
        ...state,
        isLoading: false,
        userProfileData: payload.data,
      };

    case "GET_USER_DETAIL_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "UDPATE_USER_DETAIL_REQUEST":
      return {
        ...state,
        updateUserDetailLoading: true,
        isOperationSuccessful: false,
      };
    case "UPDATE_USER_DETAIL_SUCCESS":
      const oldLocal = getLocalStorage(
        `${payload.lsUpdateData.storeId}-${payload.lsUpdateData.storeState}`
      );
      setLocalStorage(
        `${payload.lsUpdateData.storeId}-${payload.lsUpdateData.storeState}`,
        {
          ...oldLocal,
          userInformation: {
            ...oldLocal.userInformation,
            fullName: payload.lsUpdateData.fullName,
          },
        }
      );
      return {
        ...state,
        updateUserDetailLoading: false,
        isOperationSuccessful: true,
        show: true,
        message: "Profile Update Successfull",
        type: "success",
      };

    case "UPDATE_USER_DETAIL_FAILURE":
      return {
        ...state,
        updateUserDetailLoading: false,
        isOperationSuccessful: false,
        show: true,
        message: payload?.data?.message[0]?.message,
        type: "error",
      };
    case "GET_ORDER_HISTORY_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ORDER_HISTORY_SUCCESS":
      return {
        ...state,
        isLoading: false,
        userOrderHistory: payload,
      };

    case "GET_ORDER_HISTORY_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_ORDER_DETAIL_REQUEST":
      return {
        ...state,
        getOrderDetailLoading: true,
      };
    case "GET_GUEST_ORDER_DETAIL_REQUEST":
      return {
        ...state,
        getOrderDetailLoading: true,
      };
    case "GET_GUEST_ORDER_DETAIL_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ORDER_DETAIL_SUCCESS":
      return {
        ...state,
        getOrderDetailLoading: false,
        orderDetail: payload,
      };

    case "GET_ORDER_DETAIL_FAILURE":
      return {
        ...state,
        getOrderDetailLoading: false,
      };
    case "CHANGE_ACTIVE_TAB":
      return {
        ...state,
        activeTab: "second",
      };
    case "CHANGE_ACTIVE_TAB1":
      return {
        ...state,
        activeTab: "first",
      };
    default:
      return state;
  }
}
