/** @format */
import "react-credit-cards/es/styles-compiled.css";
import ReactDOM from "react-dom/client";
import HttpsRedirect from "react-https-redirect";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HttpsRedirect>
    <App />
  </HttpsRedirect>
);
