/** @format */
import { useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";

const Banner = () => {
  const bannerImages = useSelector(
    (state) =>
      state.storeReducer.storeData.bannerImagewithBannerTypeListViewModels
  );
  return (
    <div
      style={
        bannerImages?.find((item) => item.bannerType == 1)?.banners?.[0]?.image
          ? { position: "relative" }
          : { display: "none" }
      }
    >
      {/* <div className="overlay" /> */}
      <Carousel>
        {bannerImages?.find((item) => item.bannerType == 1)?.banners[0]
          ?.image ? (
          bannerImages
            ?.find((item) => item.bannerType == 1)
            ?.banners.map((item, index) => {
              return (
                <Carousel.Item key={index + item.id}>
                  <img className="d-block w-100" src={item.image} />
                  <Carousel.Caption>
                    <h1 style={{ color: "white" }}> {item.bannerTitle}</h1>
                    <p>{item.bannerSubTitle}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })
        ) : (
          <Carousel.Item key={Math.random()}>
            <img className="d-block w-100" src={""} />
            <Carousel.Caption>
              <h1 style={{ color: "white" }}> {"Dummy Text Here"}</h1>
              <p>{"Dummy Text Here"}</p>
            </Carousel.Caption>
          </Carousel.Item>
        )}
      </Carousel>
    </div>
  );
};

export default Banner;
