/** @format */

// import { Button, Modal, Spin } from "antd";
import { Form, Input } from "antd";

const CheckoutForm = ({ form, cvcValidator, cardNumberValidator }) => {
  return (
    <div className="col-md-12">
      {/* <Form form={form}> */}
      <div className="row">
        <div className="col-md-6 form-group">
          <Form.Item
            label={
              <label htmlFor="username" className=" fw-bold">
                Full name (on the card)
              </label>
            }
            rules={[
              {
                required: true,
                message: "Please enter name on card",
              },
            ]}
            name="name"
          >
            <Input
              className="form-control w-100"
              // value={name}
              // onChange={(e) => setName(e.target.value)}
              type="text"
              name="username"
              placeholder="Name on Card"
              required=""
            />
          </Form.Item>
        </div>
        <div className="col-md-6 form-group ">
          <Form.Item
            className="w-100"
            label={
              <div className="d-flex">
                <label htmlFor="cardNumber" className="fw-bold">
                  Card number
                </label>
                <span className=" h-100 rounded-0 border-0 ms-2">
                  <i className="fab fa-cc-visa mx-1" />
                  <i className="fab fa-cc-mastercard mx-1" />
                  <i className="fab fa-cc-amex mx-1" />
                </span>
              </div>
            }
            name="cardNumber"
            rules={[
              {
                validator: cardNumberValidator,
              },
            ]}
          >
            <Input
              // value={cardNumber}
              // onChange={(e) => {
              //   setCardNumber(e.target.value);
              // }}
              type="text"
              name="cardNumber"
              placeholder="Your card number"
              className="form-control"
              required=""
            />
          </Form.Item>

          <div className="input-group d-flex w-100" />
        </div>
      </div>
      {/* {cardNumberError && (
          <span style={{ color: "red" }}>{cardNumberError}</span>
        )} */}
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label className="fw-bold" style={{ marginBottom: "10px" }}>
              <span className="hidden-xs">Expiration</span>
            </label>
            <div className="d-flex input-group w-100">
              <Form.Item
                className="w-50"
                name="mm"
                rules={[
                  {
                    required: true,
                    message: "Missing expiration month",
                  },
                ]}
              >
                <Input
                  type="text"
                  // value={mm}
                  // onChange={(e) => setMm(e.target.value)}
                  placeholder="MM"
                  style={{ borderRight: 0 }}
                  name=""
                  className="form-control br-0"
                  // style={{ width: "40%" }}
                  required=""
                />
              </Form.Item>
              <Form.Item
                className="w-50"
                name="yy"
                rules={[
                  {
                    required: true,
                    message: "Missing expiration year",
                  },
                ]}
              >
                <Input
                  // value={yy}
                  // onChange={(e) => setYY(e.target.value)}
                  type="text"
                  placeholder="YY"
                  name=""
                  // style={{ width: "40%" }}
                  className="form-control br-0"
                  required=""
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <Form.Item
              name="cvc"
              rules={[
                {
                  validator: cvcValidator,
                },
              ]}
              label={<label className="fw-bold">CVC</label>}
            >
              <Input
                type="text"
                className="form-control w-50"
                required=""
                placeholder="CVC"
                // value={cvc}
                // onChange={(e) => setCvc(e.target.value)}
              />
            </Form.Item>
          </div>
          {/* {cvcNumberError && (
              <span style={{ color: "red" }}>{cvcNumberError}</span>
            )} */}
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
