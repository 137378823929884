/** @format */

export const getTimeArray = (identifier, selectedWeekDay, checkoutData) => {
  let arr = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      const minutes = j * 15;
      arr.push({
        label: `${i < 10 ? "0" + i : i}:${
          minutes < 10 ? "0" + minutes : minutes
        }`,
        value: `${i < 10 ? "0" + i : i}:${
          minutes < 10 ? "0" + minutes : minutes
        }:00`,
      });
    }
  }

  let startIndex;
  let endIndex;

  if (identifier === "1") {
    startIndex = arr.findIndex((item) => {
      return (
        item.label ===
        checkoutData.pickUpHours?.find(
          (item) => item.weekDayName.toLowerCase() === selectedWeekDay
        )?.openHour
      );
    });
    endIndex = arr.findIndex((item) => {
      return (
        item.label ===
        checkoutData.pickUpHours?.find(
          (item) => item.weekDayName.toLowerCase() === selectedWeekDay
        )?.closeHour
      );
    });
  } else if (identifier === "2") {
    startIndex = arr.findIndex((item) => {
      return (
        item.label ===
        checkoutData.deliveryHours?.find(
          (item) => item.weekDayName.toLowerCase() === selectedWeekDay
        )?.openHour
      );
    });
    endIndex = arr.findIndex((item) => {
      return (
        item.label ===
        checkoutData.deliveryHours?.find(
          (item) => item.weekDayName.toLowerCase() === selectedWeekDay
        )?.closeHour
      );
    });
  }

  arr = arr.filter((item, index) => index >= startIndex && index <= endIndex);

  return arr;
};

export const getPickupDeliveryMessage = (
  identifier,
  selectedWeekDay,
  checkoutData
) => {
  if (identifier == "1") {
    return checkoutData?.pickUpHours?.find(
      (item) => item.weekDayName.toLowerCase() === selectedWeekDay
    )?.message;
  } else if (identifier == "2") {
    return checkoutData?.deliveryHours?.find(
      (item) => item.weekDayName.toLowerCase() === selectedWeekDay
    )?.message;
  } else {
    return "";
  }
};
