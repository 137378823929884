/** @format */

import React, { useEffect, useState } from "react";
import AddAddress from "../../../Components/AddAddress/AddAddress";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MyOrder from "../../../Components/MyOrder/MyOrder";
import { getLocalStorage } from "../../../Helpers/FrontendHelper";
import axios from "axios";
import { getCookie, setLocalStorage } from "../../../Helpers/FrontendHelper";
import moment from "moment";
import { BiTime } from "react-icons/bi";
import { openNotificationWithIcon } from "../../../Components/Notification/Success.tsx";

import GuestOrderDetail from "../../../Components/GuestOrderDetails/GuestOrderDetail";
import {
  Button,
  Modal,
  Form,
  DatePicker,
  Radio,
  Input,
  Checkbox,
  Alert,
  Carousel,
  TimePicker,
  Select,
} from "antd";
import CheckoutForm from "./CheckoutForm";
import Navbar from "../../../Components/Navbar/Navbar";
import OrderPlacedModal from "../../../Components/OrderPlacedInfo/OrderPlacedModal";
import { isAuth } from "../../../Helpers/FrontendHelper";
import { Badge } from "antd";
import { Footer } from "../../../Components/Footer/Footer";
import Skeleton from "react-loading-skeleton";
import FormItemSkeleton from "../../../Components/Skeletons/FormItemSkeleton";
import CheckoutLoading from "../../../Components/Skeletons/CheckoutLoading";
import valid from "card-validator";
import { navigateHelper } from "../../../Helpers/NavigateHelper";
import { Breadcrumb } from "antd";
import { HomeOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { TextArea } = Input;
function Checkout() {
  const [form] = Form.useForm();
  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);
  const navigate = useNavigate();
  const homePageData = useSelector((state) => state.storeReducer.homePageData);
  const bannerImages = useSelector(
    (state) =>
      state.storeReducer.storeData.bannerImagewithBannerTypeListViewModels
  );
  const currencySymbol = useSelector(
    (state) => state.storeReducer.storeData.currencySymbol
  );
  const [selectedWeekDay, setSelectedWeekDay] = useState(null);
  const selectedDate = Form.useWatch("deliveryDate", form);

  const cardNumberValidator = (_, value) => {
    if (valid.number(value).isValid) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("Invalid Credit Card Number"));
    }
  };
  const cvcValidator = (_, value) => {
    if (valid.cvv(value).isValid) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("Invalid CVC Number"));
    }
  };

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = homePageData?.storeDetails?.image
      ? homePageData?.storeDetails?.image
      : storeState == "restaurant"
      ? "https://posapt.au/assets/dist/image/restaurant.png"
      : storeState == "normal"
      ? "https://posapt.au/assets/dist/image/grocery.png"
      : "";
    document.title = homePageData?.storeDetails?.storeName
      ? homePageData?.storeDetails?.storeName
      : "Online Order";
  }, [homePageData]);

  const paramValue = useParams();
  // const homePageData = useSelector((state) => state.storeReducer.homePageData);
  const dateFormat = useSelector(
    (state) => state.storeReducer.storeData.dateFormat
  );
  const storesCartData = useSelector(
    (state) => state.StateManager.storesCartData
  );
  const [value, setValue] = useState({});

  // const [isModalVisible, setISModalVisible] = useState(false);
  const [isThankYouModal, setIsThankYouModal] = useState(false);
  const [isOrderPlacedModalVisible, setIsOrderPlacedModalVisible] = useState(
    false
  );
  const [promoCode, setPromoCode] = useState(null);
  const [isPromoSubmitted, setIsPromoSubmited] = useState(false);
  const [isAddAddressModalVisible, setIsAddressModalVisible] = useState(false);

  const Token = getCookie("token");
  const dispatch = useDispatch();

  const paymentOption = Form.useWatch("paymentOption", form);

  const {
    deliveryChangeSuccess,
    deliveryPrice,
    isOrderAlreadyPlaced,
    checkoutAmounts,
  } = useSelector((state) => state.checkout);
  const storeState = useSelector(
    (state) => state.storeReducer.storeData.templateName
  );
  const [method, setMethod] = useState("");
  const [isOrderDetailModalVisible, setIsOrderDetailModalVisible] = useState(
    false
  );

  const userId = getLocalStorage(`${storeId}-${storeState}`)?.userInformation
    ?.userId;
  const {
    checkoutSectionList,
    deliveryLocation,
    isOrderSuccess,
    orderSucceedDetails,
    isLoading,
    checkoutLoading,
    checkoutData,
    getCheckoutSectionLoading,
    checkVoucherLoading,
    isCheckVoucherSuccess,
    voucherDiscount,
    isAddDeliverySuccess,
    orderId,
  } = useSelector((state) => state.checkout);
  // const [cardNumberError, setCardNumberError] = useState("");
  // const [cvcNumberError, setCvcNumberError] = useState("");

  const name = Form.useWatch("name", form);
  const cardNumber = Form.useWatch("cardNumber", form);
  const mm = Form.useWatch("mm", form);
  const yy = Form.useWatch("yy", form);
  const cvc = Form.useWatch("cvc", form);
  // const [name, setName] = useState("");
  // const [cardNumber, setCardNumber] = useState("");
  // const [mm, setMm] = useState("");
  // const [yy, setYY] = useState("");
  // const [cvc, setCvc] = useState("");
  useEffect(() => {
    dispatch({
      type: "CLEAR_ORDER_ALREADY_PLACED_STATE",
    });
    setIsOrderPlacedModalVisible(false);
  }, []);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  useEffect(() => {
    if (storeId && userId) {
      dispatch({
        type: "CHECKOUT_SECTION_LIST_REQUEST",
        payload: {
          storeId,
          userId,
        },
      });
    }
  }, [storeId, userId]);

  useEffect(() => {
    if (isAddDeliverySuccess) {
      dispatch({
        type: "CHECKOUT_SECTION_LIST_REQUEST1",
        payload: {
          storeId,
          userId,
          token: getCookie(`token-${storeId}`),
        },
      });
    }
  }, [isAddDeliverySuccess]);

  const [selectedDeliveryLocation, setSelectedDeliveryLocation] = useState();

  useEffect(() => {
    if (selectedDeliveryLocation && deliveryMethod == "2") {
      dispatch({
        type: "DELIVERY_LOCATION_ADD_REQUEST",
        getPrice: true,
        payload: {
          storeId,
          userId,
          ...selectedDeliveryLocation,
        },
      });
    }
  }, [selectedDeliveryLocation]);
  useEffect(() => {
    const location = deliveryLocation.find((el) => {
      return el.deliveryLocation == value.DeliveryLocation;
    });
    if (location) {
      setSelectedDeliveryLocation(location);
    } else {
      setSelectedDeliveryLocation();
    }
  }, [deliveryLocation]);
  const query = useQuery();
  const deliveryMethod = query.get("deliveryMethod");

  useEffect(() => {
    const deliveryMethod = query.get("deliveryMethod");

    if (deliveryMethod == 1) {
      setMethod("Pick Up ");
    } else if (deliveryMethod == 2) {
      setMethod("Delivery ");
    } else if (deliveryMethod == 3) {
      setMethod("Dine in ");
    }
  }, [deliveryMethod]);
  const getSelectedProductFromList = (productId) => {
    const values = homePageData?.productsWithCategories
      .map((item) => {
        return {
          ...item,
          products: item.products.filter((product) => product.id == productId),
        };
      })
      .filter((item) => item.products.length > 0);

    return values?.[0]?.products?.[0];
  };

  const calucateTaxHandler = (quantity, price, id) => {
    if (homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "3") {
      return (
        (parseFloat(price * quantity) *
          getSelectedProductFromList(id)?.taxExclusiveInclusiveValue) /
        100
      );
    } else if (
      homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "2"
    ) {
      return (
        (parseFloat(price * quantity) *
          getSelectedProductFromList(id)?.taxExclusiveInclusiveValue) /
        (1 + getSelectedProductFromList(id)?.taxExclusiveInclusiveValue)
      );
    } else {
      return "0";
    }
  };

  const placeOrderHandler = async (values) => {
    const localStorageData = getLocalStorage(`${storeId}-${storeState}`);

    if (deliveryMethod == "2") {
      if (!selectedDeliveryLocation) {
        return dispatch(
          openNotificationWithIcon(
            "error",
            "Please select delivery address first !"
          )
        );
      }
    }
    dispatch({
      type: "PLACE_ORDER_REQUEST",
      payload: {
        storeId: storeId,
        orderId: orderSucceedDetails.orderId ? orderSucceedDetails.orderId : "",
        OrderTypeId: homePageData.deliveryMethod?.find(
          (item) => item.deliveryMethodValue == deliveryMethod
        )?.id,
        PaymentMethodId: values.paymentOption,
        UserId: localStorageData.userInformation?.userId,
        TableId: query.get("TableId") ? query.get("TableId") : "",
        Description: values.description,
        DiscountAmount:
          homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "3"
            ? checkoutAmounts?.voucherDiscount.toString()
            : (
                parseFloat(checkoutAmounts?.voucherDiscount) -
                parseFloat(checkoutAmounts?.discountTax)
              )
                .toFixed(2)
                .toString(),
        DiscountAmountWithTax:
          homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "3"
            ? checkoutAmounts?.discountTax
              ? (
                  parseFloat(checkoutAmounts?.discountTax) +
                  parseFloat(checkoutAmounts?.voucherDiscount)
                )
                  .toFixed(2)
                  .toString()
              : ""
            : parseFloat(checkoutAmounts?.voucherDiscount)
                .toFixed(2)
                .toString(),
        TaxAmount: checkoutAmounts?.taxAmount?.split(" ")?.[1].toString(),
        CreditCardDetails:
          checkoutSectionList?.find(
            (option) => option.paymentMethodId == paymentOption
          )?.type == "7"
            ? {
                NameOnCard: name,
                CardNumber: cardNumber,
                CVCNumber: cvc,
                ExpiryMonth: mm,
                ExpiryYear: yy,
              }
            : undefined,
        TotalWithoutTaxAmount:
          homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "3"
            ? checkoutAmounts?.subTotalAmount?.split(" ")?.[1].toString()
            : (
                checkoutAmounts?.subTotalAmount?.split(" ")?.[1] -
                checkoutAmounts.taxAmountWithoutDeliveryAndDiscountTax
              )
                .toFixed(2)
                .toString(),
        TotalAmount: checkoutAmounts?.totalAmount?.split(" ")?.[1].toString(),
        DeliveryAmount:
          query.get("deliveryMethod") == "2"
            ? deliveryPrice
              ? homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "3"
                ? deliveryPrice
                  ? deliveryPrice
                  : ""
                : (
                    parseFloat(deliveryPrice) -
                    parseFloat(checkoutAmounts.deliveryTax)
                  )
                    .toFixed(2)
                    .toString()
              : ""
            : "",
        DeliveryAmountWithTax:
          query.get("deliveryMethod") == "2"
            ? deliveryPrice
              ? homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "3"
                ? deliveryPrice
                  ? (
                      parseFloat(deliveryPrice) +
                      parseFloat(checkoutAmounts.deliveryTax)
                    )
                      .toFixed(2)
                      .toString()
                  : ""
                : parseFloat(deliveryPrice)
                    .toFixed(2)
                    .toString()
              : ""
            : "",

        DeliveryAddress:
          query.get("deliveryMethod") == "2"
            ? selectedDeliveryLocation?.DeliveryLocation
              ? selectedDeliveryLocation?.DeliveryLocation
              : ""
            : "",
        VoucherRequestViewModel: {
          VoucherCode: checkoutAmounts?.voucherDiscount ? promoCode : null,
        },
        PickUpDeliveryDateTime:
          query.get("deliveryMethod") !== "3"
            ? dateFormat == "yyyy/MM/dd HH:mm:ss"
              ? moment(values.deliveryDate).format("YYYY/MM/DD") +
                " " +
                values.time
              : dateFormat == "MM/dd/yyyy HH:mm:ss"
              ? moment(values.deliveryDate).format("MM/DD/YYYY") +
                " " +
                values.time
              : dateFormat == "dd/MM/yyyy HH:mm:ss"
              ? moment(values.deliveryDate).format("DD/MM/YYYY") +
                " " +
                values.time
              : ""
            : "",
        OrderDetails: localStorageData.orderDetails?.map((item) => {
          return {
            ProductId: item?.id,
            ProductVariationId: item?.productVariations[0]?.id,
            Quantity: item?.productVariations[0]?.quantity.toString(),
            Description: item.description,
            Total: (
              item.productVariations[0]?.quantity *
              item.productVariations[0]?.price
            )
              .toFixed(2)
              .toString(),
            TotalOriginalSellingAmount: (
              item.productVariations[0]?.quantity *
              item.productVariations[0]?.actualPrice
            )
              .toFixed(2)
              .toString(), //added
            DiscountWithoutTax: item.productVariations[0]?.discount
              ? (
                  item.productVariations[0]?.quantity *
                  parseFloat(item.productVariations[0]?.discount)
                )
                  ?.toFixed(2)
                  .toString()
              : (0).toFixed(2).toString(), //added
            TotalTax: calucateTaxHandler(
              item.productVariations[0]?.quantity,
              item.productVariations[0]?.actualPrice,
              item?.id
            )
              .toFixed(2)
              .toString(), //added
            UnitCost: item.productVariations[0]?.unitPrice, //added
          };
        }),
      },
    });
  };

  useEffect(() => {
    if (isOrderSuccess) {
      dispatch({
        type: "REMOVE_CHECK_VOUCHER",
      });
      setIsThankYouModal(true);
      const localStorageData = getLocalStorage(`${storeId}-${storeState}`);
      dispatch({
        type: "CHANGE_LOCAL_STORAGE_DATA",
        payload: {
          newData: {
            ...localStorageData,
            orderDetails: [],
          },
        },
      });
      setLocalStorage(`${storeId}-${storeState}`, {
        ...localStorageData,
        orderDetails: [],
      });
      form.resetFields();
    }
  }, [isOrderSuccess]);

  const getTimeArray = () => {
    let arr = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        arr.push({
          label: `${i < 10 ? "0" + i : i}:${j === 0 ? `00` : 15 * j}`,
          value: `${i < 10 ? "0" + i : i}:${j === 0 ? `00` : 15 * j}:00`,
        });
      }
    }

    let startIndex;
    let endIndex;
    if (deliveryMethod == 1) {
      startIndex = arr.findIndex((item) => {
        return (
          item.label ==
          checkoutData?.pickUpHours?.find(
            (item) => item.weekDayName.toLowerCase() == selectedWeekDay
          )?.openHours
        );
      });
      endIndex = arr.findIndex((item) => {
        return (
          item.label ==
          checkoutData?.pickUpHours?.find(
            (item) => item.weekDayName.toLowerCase() == selectedWeekDay
          )?.closeHours
        );
      });
    } else if (deliveryMethod == 2) {
      startIndex = arr.findIndex((item) => {
        return (
          item.label ==
          checkoutData?.deliveryHours?.find(
            (item) => item.weekDayName.toLowerCase() == selectedWeekDay
          )?.openHours
        );
      });
      endIndex = arr.findIndex((item) => {
        return (
          item.label ==
          checkoutData?.deliveryHours?.find(
            (item) => item.weekDayName.toLowerCase() == selectedWeekDay
          )?.closeHours
        );
      });
    }
    arr = arr.filter((item, index) => index >= startIndex && index <= endIndex);

    return arr;
  };
  const updatedCartData = getLocalStorage(`${storeId}-${storeState}`);
  const backToHomeHandler = () => {
    navigate(`${navigateHelper(paramValue.type, paramValue.storeName, true)}`);
    dispatch({
      type: "CLEAR_ORDER_ALREADY_PLACED_STATE",
    });
  };
  useEffect(() => {
    setSelectedWeekDay(
      moment(selectedDate)
        .format("dddd")
        ?.toLowerCase("")
    );
  }, [selectedDate]);

  return (
    <>
      <Modal
        style={{ top: "2rem" }}
        width={"50vw"}
        className="guest-order-detail-modal"
        visible={isOrderDetailModalVisible}
        // onCancel={() => {
        //   setIsGuestOrderDetailModalVisible(false);
        // }}
        closable={false}
        footer={null}
      >
        <GuestOrderDetail orderId={orderId} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <Button
            style={{
              borderRadius: "5px",

              marginTop: "0.5rem",
            }}
            onClick={backToHomeHandler}
            danger
            type="primary"
          >
            Close
          </Button>
        </div>
      </Modal>
      <Modal visible={isThankYouModal} footer={null} closable={false}>
        {" "}
        <div className="modal-content">
          <div className="modal-body bg-transparent">
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              id="order-heading"
            >
              <div className="text-uppercase">
                <p>Order detail</p>
              </div>
              <div className="h4">{orderSucceedDetails.orderedDate}</div>
              <div className="pt-1">
                <p>
                  {orderSucceedDetails.orderNumber} is currently
                  <b className="text-dark"> processing</b>
                </p>
              </div>
            </div>
            <div className="order-confirm text-center">
              <img src="https://img.icons8.com/carbon-copy/100/000000/checked-checkbox.png" />
              <h2 className="text-theme"> Thank You For Your Order! </h2>
              <p>Your Order has been Confirmed</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <Button
                style={{
                  borderRadius: "5px",

                  marginTop: "0.5rem",
                }}
                onClick={backToHomeHandler}
                danger
              >
                Back To Home
              </Button>

              <Button
                style={{
                  borderRadius: "5px",

                  marginTop: "0.5rem",
                }}
                type="primary"
                danger
                onClick={() => {
                  dispatch({
                    type: "CLEAR_ORDER_ALREADY_PLACED_STATE",
                  });
                  setIsOrderDetailModalVisible(true);
                  setIsThankYouModal(false);
                  // dispatch({
                  //   type: "CHANGE_ACTIVE_TAB",
                  // });
                  // navigate(
                  //   `${navigateHelper(
                  //     paramValue.type,
                  //     paramValue.storeName
                  //   )}/profile`
                  // );
                }}
              >
                View Order
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Navbar
        homePageData={homePageData}
        storeState={storeState}
        updatedCartData={updatedCartData}
      />
      {/* ***** Header Area End ***** */}
      {/* vertical tab */}
      <div className="container">
        <div className="topslider home_slider pt-8 d-none d-md-block " />
        <Carousel>
          {bannerImages?.find((item) => item.bannerType == 3)?.banners[0]
            ?.image ? (
            bannerImages
              ?.find((item) => item.bannerType == 3)
              ?.banners.map((item) => {
                return (
                  <div key={item.id}>
                    <img
                      src={item.image}
                      className="d-block w-100 banner-image"
                      alt="..."
                    />
                  </div>
                );
              })
          ) : (
            <div>
              <img
                src={"Assets/assets/images/checkout.png"}
                className="d-block w-100"
                alt="..."
              />
            </div>
          )}
        </Carousel>
        <Breadcrumb className="mt-2 d-md-none d-block">
          <Breadcrumb.Item href="">
            <HomeOutlined />
            <Link
              to={
                query.get("TableId")
                  ? `${navigateHelper(
                      paramValue.type,
                      paramValue.storeName
                    )}?TableId=${query.get("TableId")}`
                  : `${navigateHelper(
                      paramValue.type,
                      paramValue.storeName,
                      true
                    )}`
              }
            >
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <ShoppingCartOutlined />
            <span className="mt-4">Checkout</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Form
        className="login"
        name="form"
        onFinish={placeOrderHandler}
        autoComplete="off"
        initialValues={{
          remember: true,
        }}
        form={form}
      >
        <section className="pt-3 pb-3">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-8">
                {getCheckoutSectionLoading ? (
                  <CheckoutLoading
                    deliveryMethod={deliveryMethod}
                    query={query}
                    guestCheckout={false}
                  />
                ) : (
                  <div className="checkout_left">
                    {deliveryMethod == 2 && (
                      <div className="card newaddress">
                        <div className="card-header fw-bold text-red">
                          DELIVERY ADDRESS
                        </div>
                        <div className="card-body d-flex-row">
                          <div className="addresss1">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="bg-light p-3 rounded-3">
                                  <blockquote className="blockquote mb-2 pb-2">
                                    <p className="mb-0 fw-bold fs-6">
                                      Delivery Address
                                      <span>
                                        {" "}
                                        |{" "}
                                        <a className="text-red1">
                                          <small
                                            className="text-danger"
                                            onClick={() => {
                                              setIsAddressModalVisible(true);
                                            }}
                                          >
                                            Add New Address
                                          </small>
                                        </a>
                                      </span>
                                    </p>
                                  </blockquote>
                                  {deliveryLocation == null ||
                                  deliveryLocation.length == 0 ? (
                                    <label
                                      className="form-check-label fw-bold"
                                      htmlFor="flexRadioDefault1"
                                    >
                                      No delivery address available
                                    </label>
                                  ) : (
                                    deliveryLocation?.map((location, i) => {
                                      return (
                                        <>
                                          <div class="row" key={location.id}>
                                            <div class="col-md-6">
                                              <div
                                                className="form-check"
                                                key={i}
                                              >
                                                <Checkbox
                                                  style={{
                                                    marginRight: "8px",
                                                  }}
                                                  checked={
                                                    location ==
                                                    selectedDeliveryLocation
                                                  }
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setSelectedDeliveryLocation(
                                                        location
                                                      );
                                                    } else {
                                                      setSelectedDeliveryLocation();
                                                    }
                                                  }}
                                                  name="flexRadioDefault-h"
                                                  id={"flexRadioDefaultd" + i}
                                                />
                                                <label
                                                  className="form-check-label fw-bold"
                                                  htmlFor={
                                                    "flexRadioDefaultd" + i
                                                  }
                                                >
                                                  {location.deliveryLocation}
                                                </label>
                                              </div>
                                            </div>
                                            <div class="col-md-6 text-end">
                                              <div class="deleteform">
                                                <a
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    dispatch({
                                                      type:
                                                        "DELIVERY_LOCATION_DELETE_REQUEST",
                                                      payload: {
                                                        id: location.id,
                                                      },
                                                    });
                                                  }}
                                                  href="#"
                                                  class="text-danger"
                                                >
                                                  <i class="fas fa-trash" />
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    })
                                  )}
                                  <hr />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {query.get("deliveryMethod") !== "3" && (
                      <div className="card checkout-card mt-3 border-0">
                        <div className="card-header fw-bold text-red">
                          {method}
                          Date and Time
                        </div>
                        <div className="card-body d-flex-row">
                          <div className="addresss1">
                            <div className="row">
                              <div className="col-md-12">
                                {query.get("deliveryMethod") == "1" &&
                                  checkoutData?.pickupMessage && (
                                    <div
                                      class="alert alert2 alert-danger mt-2 d-flex align-items-center justify-content-between"
                                      role="alert"
                                    >
                                      {checkoutData?.pickupMessage}
                                    </div>
                                  )}
                                {query.get("deliveryMethod") == "2" &&
                                  checkoutData?.deliveryMessage && (
                                    <div
                                      class="alert alert2 alert-danger mt-2 d-flex align-items-center justify-content-between"
                                      role="alert"
                                    >
                                      {checkoutData?.deliveryMessage}
                                    </div>
                                  )}

                                <div className="bg-light p-3 mt-3">
                                  <div className="form-details">
                                    <div className="row text-start">
                                      <div className="col-md-3">
                                        <Form.Item
                                          label={`${method} date`}
                                          name="deliveryDate"
                                          rules={[
                                            {
                                              required: true,
                                              message: `Please input your ${method?.toLowerCase()} date!`,
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            // inputReadOnly={false}
                                            disabledDate={(date) => {
                                              return (
                                                moment(date).format(
                                                  "DD/MM/YYYY"
                                                ) <
                                                moment().format("DD/MM/YYYY")
                                              );
                                            }}
                                            format={dateFormat
                                              .split(" ")[0]
                                              ?.toUpperCase()}
                                            className="checkout-date-picker"
                                            placeholder={`Enter ${method} Date`}
                                            style={{
                                              width: "100%",
                                              height: "40px",
                                            }}
                                          />
                                        </Form.Item>
                                      </div>
                                      <div className="col-md-3">
                                        <Form.Item
                                          label={`${method} Time`}
                                          name="time"
                                          rules={[
                                            {
                                              required: true,
                                              message: `Please input your ${method?.toLowerCase()} time!`,
                                            },
                                          ]}
                                        >
                                          <Select
                                            disabled={!selectedDate}
                                            options={getTimeArray(
                                              selectedWeekDay
                                            )}
                                            placeholder="Select Time"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div class="card checkout-card border-0 mt-3">
                      <div class="card-header promo-container text-red d-block d-md-flex align-items-center justify-content-between border-0">
                        <h6 class="fw-bold text-danger">
                          Do you have a Promo code to redeem?
                        </h6>
                        {isCheckVoucherSuccess ? (
                          <>
                            {" "}
                            <Alert
                              className="alert-checkout"
                              style={{ justifySelf: "flex-end" }}
                              message={
                                <>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          marginRight: "3px",
                                        }}
                                      >
                                        {promoCode}
                                      </span>
                                      <span style={{ color: "#444" }}>
                                        applied !
                                      </span>
                                    </div>
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        color: "#F03F4C",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        dispatch({
                                          type: "REMOVE_CHECK_VOUCHER",
                                        });
                                      }}
                                    >
                                      Remove{" "}
                                    </span>
                                  </div>
                                </>
                              }
                              description={
                                <span style={{ color: "#444" }}>
                                  {voucherDiscount} % Off
                                </span>
                              }
                              type="success"
                              showIcon
                            />
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              {" "}
                              <Input
                                className="promo-input"
                                value={promoCode}
                                onChange={(e) => setPromoCode(e.target.value)}
                                style={{ height: "40px" }}
                                placeholder="Promo Code / Coupon"
                              />{" "}
                              <Button
                                loading={checkVoucherLoading}
                                onClick={() => {
                                  setIsPromoSubmited(true);
                                  if (promoCode) {
                                    dispatch({
                                      type: "CHECK_VOUCHER_REQUEST",
                                      payload: {
                                        VoucherCode: promoCode,
                                      },
                                    });
                                  }
                                }}
                                style={{
                                  width: "120px",
                                  marginLeft: "5px",

                                  border: "none",
                                  color: "white",
                                  height: "40px",
                                }}
                                className="btn btn-dark btn-sm ms-1 primary-button"
                              >
                                Apply
                              </Button>
                            </div>
                            {isPromoSubmitted && !promoCode && (
                              <span
                                style={{ color: "Red", fontWeight: "normal" }}
                              >
                                Please enter Coupon Code !
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="card checkout-card border-0 mt-3">
                      <div className="card-header fw-bold text-red">
                        Payment Options
                      </div>
                      <div className="card-body d-flex-row">
                        <div className="addresss1">
                          <div className="row">
                            <div className="col-md-12">
                              <Form.Item
                                name="paymentOption"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input one of the given payment options !",
                                  },
                                ]}
                              >
                                <Radio.Group>
                                  {query.get("deliveryMethod") == "2"
                                    ? checkoutSectionList
                                        ?.filter(
                                          (item) => item.isThirdPartyPayment
                                        )
                                        .map((option, i) => {
                                          return (
                                            <Radio
                                              key={Math.random()}
                                              value={option.paymentMethodId}
                                            >
                                              {" "}
                                              {option.name}
                                              {option.name == "Payment" &&
                                                " on " + method}
                                            </Radio>
                                          );
                                        })
                                    : checkoutSectionList.map((option, i) => {
                                        return (
                                          <Radio value={option.paymentMethodId}>
                                            {" "}
                                            {option.name}
                                            {option.name == "Payment" &&
                                              " on " + method}
                                          </Radio>
                                        );
                                      })}
                                </Radio.Group>
                              </Form.Item>
                              {checkoutSectionList?.find(
                                (option) =>
                                  option.paymentMethodId == paymentOption
                              )?.type == "7" && (
                                <div className="mt-3">
                                  <div className="row bg-light p-4">
                                    <CheckoutForm
                                      form={form}
                                      cvcValidator={cvcValidator}
                                      cardNumberValidator={cardNumberValidator}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card checkout-card border-0 mt-3">
                      <div className="card-header fw-bold text-red">
                        Instruction Or Special Request
                      </div>
                      <div className="card-body d-flex-row">
                        <div className="addresss1">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <Form.Item name="description">
                                    <TextArea
                                      placeholder="Enter Instruction Or Special Request"
                                      class="form-control"
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mybtn d-flex justify-content-between align-items-start mt-3">
                          <Button
                            onClick={() => {
                              navigate(
                                `${navigateHelper(
                                  paramValue.type,
                                  paramValue.storeName
                                )}`
                              );
                            }}
                            type="primary"
                            htmlType="submit"
                            className="secondary-button"
                            style={{
                              border: "none",
                              color: "white",
                            }}
                          >
                            Back
                          </Button>
                          <Form.Item>
                            <Button
                              disabled={
                                storesCartData?.orderDetails?.length == 0
                                  ? true
                                  : false
                              }
                              type="primary"
                              htmlType="submit"
                              loading={checkoutLoading}
                              className="primary-button"
                              style={{
                                border: "none",
                                color: "white",
                              }}
                            >
                              Continue
                            </Button>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-12 col-lg-4">
                <MyOrder
                  deliveryMethod={deliveryMethod}
                  deliveryPrice={deliveryPrice}
                  selectedDeliveryLocation={selectedDeliveryLocation}
                  activeDeliveryMethod={query.get("deliveryMethod")}
                />
              </div>
            </div>
          </div>
        </section>
      </Form>
      <Footer />
      <Modal
        visible={isAddAddressModalVisible}
        onCancel={() => {
          setIsAddressModalVisible(false);
        }}
        footer={null}
        style={{ top: "2rem" }}
      >
        <AddAddress
          setIsAddressModalVisible={setIsAddressModalVisible}
          value={value}
          setValue={setValue}
          setSelectedDeliveryLocation={setSelectedDeliveryLocation}
        />
      </Modal>
    </>
  );
}

export default Checkout;
