/** @format */

import "./Promotion.css";
import promotionImage from "./promotion.png";

const PromotionalModal = ({ setPromotionModalOpen, homePageData }) => {
  return (
    <div className="promotion-modal-container">
      <div className="promotion-content">
        <span
          className="promotion-close"
          onClick={() => setPromotionModalOpen(false)}
          aria-label="Close"
        >
          &times;
        </span>
        <img src={promotionImage} alt="Promotion" className="promotion-image" />
        <h4 className="promotion-heading">Don't Miss Out!</h4>
        <div className="promotion-message-container">
          <p className="promotion-text">
            Buy{" "}
            <span className="promotion-highlight">
              ${homePageData?.promotionalOffer?.thresholdAmount}
            </span>{" "}
            and get
            <span className="promotion-highlight ms-2">
              {homePageData?.promotionalOffer?.discountPercentage}%
            </span>{" "}
            off!
          </p>
          {homePageData?.promotionalOffer?.promotionalMessage && (
            <p className="promotion-message">
              {homePageData?.promotionalOffer?.promotionalMessage}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromotionalModal;
