/** @format */

import React, { useState } from "react";
import { Modal, Tooltip } from "antd";
import { useEffect } from "react";
import ProductListing from "../../Pages/Stores/Restaurant/ProductListing";
import { useDispatch } from "react-redux";

const SearchProduct = ({
  productsWithCategorie,
  setActiveFood,
  addToCartHandler,
  setIsProductDetailModalOpen,
  homePageData,
  setReserverTableModalOpen,
}) => {
  const [productsWithCategories, setProductsWithCategories] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalProducts, setTotalProducts] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setProductsWithCategories(productsWithCategorie);
  }, [productsWithCategorie]);

  useEffect(() => {
    dispatch({
      type: "STORE_SEARCH_KEYWORD",
      payload: searchKeyword,
    });
    setProductsWithCategories((prev) =>
      productsWithCategorie
        .map((item) => {
          return {
            ...item,
            products: item.products.filter((product) =>
              product.productName
                .toLowerCase()
                .includes(searchKeyword.toLowerCase())
            ),
          };
        })
        .filter((item) => item.products.length > 0)
    );
  }, [searchKeyword]);

  const checkOutOfStock = (item) => {
    const defaultVariation = item.productVariations?.filter(
      (item) => item.isDefault
    );

    if (defaultVariation[0]?.stockCount == "0") {
      return {
        isOutOfStock: true,
        outOfStockMessage: defaultVariation[0]?.outOfStockMessage,
      };
    } else {
      return {
        isOutOfStock: false,
      };
    }
  };

  return (
    // <Modal visible={searchModalOpen} footer={null} onCancel={() => setSearchModalOpen(false)}>
    <>
      <div class="modal-header  ">
        <h5 class="modal-title fw-bold" id="searchnowmodalLabel">
          Explore Menu Items
        </h5>
        {/* <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> --> */}
      </div>
      <div class="modal-body">
        <div class=" form-group">
          <input
            type="text"
            placeholder="Search for food... "
            class="form-control form-control-lg rounded-0"
            data-ms-editor="true"
            value={searchKeyword}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
          />
        </div>
      </div>
      <ProductListing
        addToCartHandler={addToCartHandler}
        productsWithCategories={productsWithCategories}
        checkOutOfStock={checkOutOfStock}
        setActiveFood={setActiveFood}
        setIsProductDetailModalOpen={setIsProductDetailModalOpen}
        isSearchList={true}
        homePageData={homePageData}
        setReserverTableModalOpen={setReserverTableModalOpen}
        alertMessage={false}
      />
    </>
  );
};

export default SearchProduct;
