/** @format */

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderDetailSkeleton from "../Skeletons/OrderDetailSkeleton";

const GuestOrderDetail = ({ orderId }) => {
  const dispatch = useDispatch();
  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);
  const { orderDetail, getOrderDetailLoading } = useSelector(
    (state) => state.profile
  );
  const homePageData = useSelector((state) => state.storeReducer.homePageData);

  const currencySymbol = useSelector(
    (state) => state.storeReducer.storeData.currencySymbol
  );
  useEffect(() => {
    dispatch({
      type: "GET_GUEST_ORDER_DETAIL_REQUEST",
      payload: {
        data: {
          OrderId: orderId,
        },
        storeId,
      },
    });
  }, [orderId]);
  return (
    <>
      {getOrderDetailLoading ? (
        <OrderDetailSkeleton />
      ) : (
        <div className="">
          <div className="modal-header">
            <h5 className="modal-title" id="orderdetailsModalLabel">
              Order Details({orderDetail?.orderDetailsViewModel?.orderNumber})
            </h5>
          </div>
          <div className="modal-body">
            <div className="card bg-theme mb-3">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr className="table-light">
                        <td>Tax Amount</td>
                        <td>Table No.</td>
                        <td>Total Amount</td>
                        <td>Delivery Address</td>
                        <td>Delivery Amount</td>
                        <td>Discount Amount</td>
                      </tr>
                      <tr>
                        <td>
                          {currencySymbol}{" "}
                          {orderDetail?.orderDetailsViewModel?.taxAmount}
                        </td>

                        <td>
                          {orderDetail?.orderDetailsViewModel?.tableNumber}
                        </td>

                        <td>
                          {currencySymbol}{" "}
                          {orderDetail?.orderDetailsViewModel?.totalAmount}
                        </td>
                        <td>
                          {orderDetail?.orderDetailsViewModel?.deliveryAddress}
                        </td>
                        <td>
                          {currencySymbol}{" "}
                          {homePageData?.storeTaxSettings
                            ?.taxExclusiveInclusiveType == "3"
                            ? orderDetail?.orderDetailsViewModel?.deliveryAmount
                            : orderDetail?.orderDetailsViewModel
                                ?.deliveryAmountWithTax}
                        </td>
                        <td>
                          {currencySymbol} {currencySymbol}{" "}
                          {homePageData?.storeTaxSettings
                            ?.taxExclusiveInclusiveType == "3"
                            ? orderDetail?.orderDetailsViewModel?.discountAmount
                            : orderDetail?.orderDetailsViewModel
                                ?.discountAmountWithTax}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="detailtable">
              <h6 className="fw-bold text-theme">Customer User</h6>
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr className="table-light">
                      <td>Customer Name</td>
                      <td>Email</td>
                      <td>Phone</td>
                    </tr>
                    <tr>
                      <td>{orderDetail?.customerUserViewModel?.name}</td>
                      <td>{orderDetail?.customerUserViewModel?.email}</td>
                      <td>{orderDetail?.customerUserViewModel?.phoneNumber}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="detailtable">
              <h6 className="fw-bold text-theme">Order Payment Details</h6>
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr className="table-light">
                      <td>Paid Amount</td>

                      <td>Payment Status</td>
                      <td>Payment Method</td>
                      <td>Customer Name</td>
                    </tr>
                    <tr>
                      <td>
                        {currencySymbol}
                        {
                          orderDetail
                            ?.orderPaymentDetailsWithPaymentStatusViewModel
                            ?.paidAmount
                        }
                      </td>
                      <td>
                        {
                          orderDetail
                            ?.orderPaymentDetailsWithPaymentStatusViewModel
                            ?.paymentStatus
                        }
                      </td>
                      <td>
                        {
                          orderDetail
                            ?.orderPaymentDetailsWithPaymentStatusViewModel
                            ?.orderPaymentsDetailsViewModels[0]?.paymentMethod
                        }
                      </td>
                      <td>{orderDetail?.customerUserViewModel?.name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="detailtable">
              <h6 className="fw-bold text-theme">Product with Price Details</h6>
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr className="table-light">
                      <td>Name</td>
                      <td>Quantity</td>
                      <td>Total</td>
                      <td>Description</td>

                      <td>Image</td>
                    </tr>
                    {orderDetail?.productWithPriceDetailsViewModel?.map(
                      (item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.quantity}</td>
                          <td>
                            {currencySymbol} {item.total}
                          </td>
                          <td>{item.description}</td>
                          <td>
                            {" "}
                            <img
                              style={{ height: "80px", width: "80px" }}
                              src={item.image}
                              className="img-fluid width50"
                              alt=""
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GuestOrderDetail;
