import React from "react";
import { Typography } from "antd";
import { useSelector } from "react-redux";

const OrderItemModifiers = ({ product }) => {
  const currencySymbol = useSelector(
    (state) => state.storeReducer.storeData.currencySymbol
  );
  return (
    <>
      {product?.OrderItemsPriceModifierViewModels?.length > 0 && (
        <Typography.Text
          type="warning"
          className="me-2 fw-bold"
          style={{ fontSize: "13px", lineHeight: "1" }}
        >
          Modifiers :
        </Typography.Text>
      )}
      {product?.OrderItemsPriceModifierViewModels?.map((item, index) => (
        <Typography.Text
          type="warning"
          className="me-2"
          key={index}
          style={{ fontSize: "13px", lineHeight: "1" }}
        >
          {item.ModifierName} {`( ${currencySymbol} ${item.ModifierPrice} )`}
          {index < product.OrderItemsPriceModifierViewModels.length - 1 && ","}
        </Typography.Text>
      ))}
    </>
  );
};

export default OrderItemModifiers;
