import React from "react";
import Skeleton from "react-loading-skeleton";

const OrderDetailSkeleton = () => {
  return (
    <div className="">
      <div className="modal-header">
        <h5 className="modal-title" id="orderdetailsModalLabel">
          Order Details
        </h5>
      </div>
      <div className="modal-body">
        <div className="card bg-theme mb-3">
          <div className="card-body">
            <table className="table">
              <tbody>
                <tr className="table-light">
                  <td>Tax Amount</td>
                  <td>Order No</td>
                  <td>Table No.</td>
                  <td>Total Amount</td>
                  <td>Delivery Address</td>
                  <td>Order Status</td>
                </tr>
                <tr>
                  <td>
                    <Skeleton />
                  </td>

                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    {" "}
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    {" "}
                    <Skeleton />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="detailtable">
          <h6 className="fw-bold text-theme">Customer User</h6>
          <table className="table">
            <tbody>
              <tr className="table-light">
                <td>Customer Name</td>
                <td>Email</td>
                <td>Phone</td>
              </tr>
              <tr>
                <td>
                  <Skeleton />
                </td>

                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="detailtable">
          <h6 className="fw-bold text-theme">Order Payment Details</h6>
          <table className="table">
            <tbody>
              <tr className="table-light">
                <td>Paid Amount</td>

                <td>Payment Status</td>
                <td>Payment Method</td>
                <td>Customer Name</td>
              </tr>
              <tr>
                <td>
                  <Skeleton />
                </td>

                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="detailtable">
          <h6 className="fw-bold text-theme">Product with Price Details</h6>
          <table className="table">
            <tbody>
              <tr className="table-light">
                <td>Name</td>
                <td>Quantity</td>
                <td>Total</td>
                <td>Description</td>

                <td>Image</td>
              </tr>
              <tr>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>{" "}
                <td>
                  <Skeleton />
                </td>
              </tr>
              <tr>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>{" "}
                <td>
                  <Skeleton />
                </td>
              </tr>
              <tr>
                <td>
                  <Skeleton />
                </td>

                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailSkeleton;
