import { combineReducers } from "redux";
import storeReducer from "./storeReducer";
import authentication from "./authentication";
import checkout from "./checkout";
import StateManager from "./StateManager";
import profile from "./profile";

export const rootReducer = combineReducers({
  storeReducer,
  authentication,
  checkout,
  StateManager,
  profile,
});
