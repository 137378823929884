/** @format */

import Payment from "payment";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 3;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}
export const validateNumber = (rule, value, callback) => {
  const numberRegex = /^[0-9]+$/;

  if (!numberRegex.test(value)) {
    callback("Must be a valid Number !");
  } else {
    callback(); // No error, so call the callback with no arguments
  }
};
export const validateAustralianNumber = (_, value) => {
  if (!value) {
    return Promise.resolve();
  }

  // Regex for Australian phone number (with optional leading 0)
  const australianPhoneNumberRegex = /^0?4\d{8}$|^0?[1-9]\d{8}$/;

  if (australianPhoneNumberRegex.test(value)) {
    return Promise.resolve();
  } else {
    return Promise.reject(new Error("Invalid phone number"));
  }
};
