/** @format */

import { Form } from "antd";
import Skeleton from "react-loading-skeleton";
import FormItemSkeleton from "./FormItemSkeleton";

const ProfileSkeleton = () => {
  return (
    <Form
      className="login profile"
      name="form"
      autoComplete="off"
      initialValues={{
        remember: true,
      }}
    >
      <div
        className="tab-pane"
        id="account-vertical"
        role="tabpanel"
        aria-labelledby="account-vertical-tab"
      >
        <div className="card acc_setting border-0">
          <div className="card-body border-0">
            <div className=" bg-white">
              <h4 className="pb-4 border-bottom">Account settings</h4>
              <div className="d-flex align-items-start py-3 border-bottom">
                {" "}
                <Skeleton height={80} width={80} />
                <div className="ps-sm-4 ps-2" id="img-section">
                  {" "}
                  <b>
                    <Skeleton width={120} />
                  </b>
                  <Skeleton width={170} height={37} />
                </div>
              </div>
              <div className="py-2">
                <div className="row py-2">
                  <div className="col-md-6 pt-md-0 pt-3">
                    {" "}
                    <FormItemSkeleton height={40} />
                  </div>
                  <div className="col-md-6">
                    {" "}
                    <FormItemSkeleton height={40} />
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col-md-6 pt-md-0 pt-3">
                    {" "}
                    <FormItemSkeleton height={40} />
                  </div>
                  <div className="col-md-6 pt-md-0 pt-3">
                    {" "}
                    <FormItemSkeleton height={40} />
                  </div>
                </div>
                <div className="row py-2" />
                <div className="py-3 pb-4 border-bottom">
                  {" "}
                  <Skeleton height={40} width={150} />
                  {/* <button className="btn border button">
            Cancel
          </button>{" "} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ProfileSkeleton;
