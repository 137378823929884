/** @format */

import LoadingToRedirect from "./LoadingToRedirect";

function PrivateRoute({ children, storeId, ...rest }) {
  // ------Disabling PRivate Routing for now ------

  // return isAuth(getLocalStorage("activeStoreId")) ? (
  //   children
  // ) : (
  //   <LoadingToRedirect />
  // );
  return true ? children : <LoadingToRedirect />;
}

export default PrivateRoute;
