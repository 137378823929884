/** @format */

import { setLocalStorage } from "../../Helpers/FrontendHelper";

const initialState = {
  isLoading: true,
  storeData: {},
  homePageData: null,
  unUpdatedHomePageData: null,
  reservationSectionList: {},
  reservationLoading: false,
  isOperationSuccessfull: false,
  isSessionExpired: false,
  getReservationLoading: false,
  isUnAuthenticated: false,
  countryList: {},
  allStoresList: [],
  show: false,
  type: "",
  message: "",
  description: "",
  allTemplateData: null,
  productByCategories: null,
  getProductByCategoryLoading: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "CLOSE_NOTIFICATION":
      return {
        ...state,
        show: false,
        type: "",
        message: "",
      };
    case "GET_TEMPLATE_WITH_DETAILS_REQUEST":
      return {
        ...state,
        isLoading: true,
        getProductByCategoryLoading: true,
      };
    case "GET_TEMPLATE_WITH_DETAILS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        allTemplateData: payload,
        getProductByCategoryLoading: false,
      };

    case "GET_TEMPLATE_WITH_DETAILS_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_PRODUCT_BY_CATEGORIES_REQUEST":
      return {
        ...state,
        getProductByCategoryLoading: true,
      };
    case "GET_PRODUCT_BY_CATEGORIES_SUCCESS":
      return {
        ...state,
        getProductByCategoryLoading: false,
        productByCategories: payload?.categoryData
          ? payload?.categoryData
          : payload,
        allTemplateData: state.allTemplateData
          ? state.allTemplateData
          : payload?.templateData,
      };
    case "GET_PRODUCT_BY_CATEGORIES_FAILURE":
      return {
        ...state,
        getProductByCategoryLoading: false,
      };
    case "GET_TEMPLATE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_TEMPLATE_SUCCESS":
      setLocalStorage("activeStoreId", payload.storeId);
      return {
        ...state,
        isLoading: false,
        storeData: payload,
      };

    case "GET_TEMPLATE_FAILURE":
      return {
        ...state,
        isLoading: false,
        show: true,
        message: payload?.data?.message[0]?.message,
        type: "error",
      };
    case "GET_ALL_ONLINE_ORDER_SECTION_LIST_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ALL_ONLINE_ORDER_SECTION_LIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        unUpdatedHomePageData: payload,
        homePageData: payload,
        productByCategories: payload?.productsWithCategories,
      };
    case "GET_ALL_UPDATED_ONLINE_ORDER_SECTION_LIST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        homePageData: payload,
      };

    case "GET_ALL_ONLINE_ORDER_SECTION_LIST_FAILURE":
      return {
        ...state,
        isLoading: false,
        show: true,
        message: payload?.data?.message[0]?.message,
        type: "error",
      };
    case "GET_RESERVATION_SECTION_LIST_REQUEST":
      return {
        ...state,
        getReservationLoading: true,
      };
    case "GET_RESERVATION_SECTION_LIST_SUCCESS":
      return {
        ...state,
        getReservationLoading: false,
        reservationSectionList: payload,
      };

    case "GET_RESERVATION_SECTION_LIST_FAILURE":
      return {
        ...state,
        getReservationLoading: false,
        show: true,
        message: payload?.data?.message[0]?.message,
        type: "error",
      };
    case "RESERVE_TABLE_REQUEST":
      return {
        ...state,
        reservationLoading: true,
        isOperationSuccessfull: false,
      };
    case "RESERVE_TABLE_SUCCESS":
      return {
        ...state,
        reservationLoading: false,
        isOperationSuccessfull: true,
        show: true,
        message: "Table Reservation Successfull",
        type: "success",
      };

    case "RESERVE_TABLE_FAILURE":
      return {
        ...state,
        reservationLoading: false,
        isOperationSuccessfull: false,
        show: true,
        message: payload?.data?.message[0]?.message,
        type: "error",
      };
    case "GET_ALL_STORES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ALL_STORES_SUCCESS":
      return {
        ...state,
        isLoading: false,
        allStoresList: payload,
      };

    case "GET_ALL_STORES_FAILURE":
      return {
        ...state,
        isLoading: false,
        show: true,
        message: payload?.data?.message[0]?.message,
        type: "error",
      };
    case "SESSION_EXPIRED":
      return {
        ...state,
        isSessionExpired: true,
      };
    case "UNAUTHENTICATED":
      return {
        ...state,
        isUnAuthenticated: true,
      };
    case "CLEAR_UNAUTHENTICATED_STATE":
      return {
        ...state,
        isUnAuthenticated: false,
        isSessionExpired: false,
      };
    default:
      return state;
  }
}
