/** @format */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { navigateHelper } from "../../Helpers/NavigateHelper";

import { Button, Form, Input } from "antd";

const ChangePassword = ({ setIsChangePasswordOpen, storeId }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const dispatch = useDispatch();
  const paramValue = useParams();
  const query = useQuery();
  const { data, error, isLoading, isPasswordChangedSucces } = useSelector(
    (state) => state.authentication
  );
  const code = query.get("code");
  const email = query.get("email");

  const onChangePasswordFormSubmit = (values) => {
    dispatch({
      type: "RESET_PASSWORD_REQUEST",
      payload: {
        Email: email,
        Code: code,
        Password: values.newPassword,
        ConfirmPassword: values.confirmPassword,
        storeId: storeId,
      },
    });
  };
  useEffect(() => {
    if (isPasswordChangedSucces) {
      form.resetFields();
      setIsChangePasswordOpen(false);
    }
  }, [isPasswordChangedSucces]);
  return (
    <div className="forgot-password forgotPassword">
      <Form
        name="form"
        onFinish={onChangePasswordFormSubmit}
        autoComplete="off"
        form={form}
        className="forgot-password-form"
        style={{
          boxShadow:
            "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px",
          padding: "20px",
        }}
      >
        <h4>Create New Password</h4>
        <p style={{ color: "#A3A7B0" }}>
          Your New Password Must be different from previous used passwords !
        </p>
        <Form.Item
          hasFeedback
          label="New Password"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Please input your new password!",
            },
          ]}
        >
          <Input.Password
            placeholder="Enter New Password"
            class="form-control"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please input your confirm password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Enter Confirm Password"
            class="form-control"
          />
        </Form.Item>
        <Form.Item>
          <Button
            className="primary-button"
            loading={isLoading}
            type="primary"
            htmlType="submit"
            style={{
              height: "35px",
              background: "#1B9AA5",
              border: "none",
              marginRight: "8px",
            }}
          >
            Submit
          </Button>
          <Button
            className="secondary-button"
            style={{
              height: "35px",

              border: "none",
            }}
            onClick={() => {
              setIsChangePasswordOpen(false);
              navigate(
                `${navigateHelper(paramValue.type, paramValue.storeName)}`
              );
            }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePassword;
