/** @format */

import { getLocalStorage, setLocalStorage } from "../../Helpers/FrontendHelper";

const initialState = {
  isSearchModalOpen: false,
  storesCartData: {},
  halfHalfProducts:
    getLocalStorage("halfHalfProducts") ||
    getLocalStorage("halfHalfProducts")?.length > 0
      ? getLocalStorage("halfHalfProducts")
      : [],
  totalAmount: "",
  searchKeyword: "",
  show: false,
  type: "",
  message: "",
  description: "",
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  let halfHalfData = [...state.halfHalfProducts];
  switch (type) {
    case "SHOW_NOTIFICATION":
      return {
        ...state,
        show: true,
        type: action.payload.type,
        message: action.payload.message,
      };
    case "CLOSE_NOTIFICATION":
      return {
        ...state,
        show: false,
        type: "",
        message: "",
      };

    case "STORE_SEARCH_KEYWORD":
      return {
        ...state,
        searchKeyword: payload,
      };
    case "TOGGLE_SEARCH_MODAL":
      return {
        ...state,
        isSearchModalOpen: state.isSearchModalOpen ? false : true,
      };
    case "CLOSE_SEARCH_MODAL":
      return {
        ...state,
        isSearchModalOpen: false,
      };
    case "OPEN_SEARCH_MODAL":
      return {
        ...state,
        isSearchModalOpen: true,
      };
    case "GET_LOCAL_STORAGE_DATA":
      const { storeId, storeState } = payload;
      return {
        ...state,
        storesCartData: getLocalStorage(`${storeId}-${storeState}`),
      };
    case "CHANGE_LOCAL_STORAGE_DATA":
      console.log("--this is running !");
      return {
        ...state,
        storesCartData: payload.newData,
      };
    case "CLEAR_LOCAL_STORAGE_DATA":
      console.log("--this is running 2");
      return {
        ...state,
        storesCartData: {
          productVersion: state.storesCartData.productVersion,
          storeId: state.storesCartData.storeId,
          tableId: state.storesCartData.tableId,
          userInformation: state.storesCartData.userInformation,
          orderDetails: [],
        },
      };

    case "SET_HALF_HALF_ITEM_CART":
      halfHalfData.push(payload);
      setLocalStorage("halfHalfProducts", halfHalfData);
      return {
        ...state,
        halfHalfProducts: halfHalfData,
      };

    case "REMOVE_HALF_HALF_ITEMS_BY_GROUP_KEY":
      const halfGroupKey = payload;
      setLocalStorage(
        "halfHalfProducts",
        halfHalfData?.filter((item) => item.HalfGroupKey != halfGroupKey)
      );

      return {
        ...state,
        halfHalfProducts: halfHalfData?.filter(
          (item) => item.HalfGroupKey != halfGroupKey
        ),
      };
    case "STORE_TOTAL_AMOUNT":
      return {
        ...state,
        totalAmount: payload.totalAmount,
      };
    case "CLEAR_HALF_HALF_PRODUCTS":
      setLocalStorage("halfHalfProducts", []);
      return {
        ...state,
        halfHalfProducts: [],
      };
    default:
      return state;
  }
}
