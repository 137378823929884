import { Typography } from "antd";
import React from "react";

const OrderItemRemovedIngredients = ({ product }) => {
  return (
    <>
      {product?.RemovedOrderItemsIngredientsViewModels?.length > 0 && (
        <Typography.Text
          type="danger"
          className="me-2 fw-bold"
          style={{ fontSize: "13px" }}
        >
          Removed Ingredients:
        </Typography.Text>
      )}
      {product?.RemovedOrderItemsIngredientsViewModels?.map((item, index) => (
        <Typography.Text
          style={{ fontSize: "13px" }}
          type="danger"
          className="me-2"
          key={index}
        >
          {item.name}

          {index < product?.RemovedOrderItemsIngredientsViewModels.length - 1 &&
            ","}
        </Typography.Text>
      ))}
    </>
  );
};

export default OrderItemRemovedIngredients;
