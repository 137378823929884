/** @format */

import { Skeleton as AntSkeleton } from "antd";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

export const ProductCard = () => {
  return (
    <div className="col-md-3 col-6">
      <div
        className="card mb-3"
        data-bs-toggle="modal"
        data-bs-target="#foodModal"
      >
        <div className="row ">
          <Skeleton height={150} />
          <div className="card-body">
            <p
              className="card-text"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title=""
              data-bs-original-title="Flat rice noodle in tamarind flavoured sauce with bean sprouts, bell pepper, carrot, onion, egg topped with lemon and crushed peanuts."
              aria-label="innerpara"
              style={{
                marginBottom: "5px",
              }}
            >
              <Skeleton />
            </p>
            <p
              className="card-text"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title=""
              data-bs-original-title="Flat rice noodle in tamarind flavoured sauce with bean sprouts, bell pepper, carrot, onion, egg topped with lemon and crushed peanuts."
              aria-label="innerpara"
            >
              <Skeleton
                style={{
                  marginTop: "-1rem",
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const BakerySkeleton = () => {
  const bannerImages = useSelector(
    (state) =>
      state.storeReducer.storeData.bannerImagewithBannerTypeListViewModels
  );
  const array = new Array(4).fill(0).map(() => Math.random());
  return (
    <div className="container-fluid">
      {" "}
      <div className="row">
        <div
          className="col-md-8 col-lg-9 d-flex flex-column "
          style={{ minHeight: "100vh" }}
        >
          <div className="topslider home_slider" style={{ height: "40px" }} />
          <header className="sticky-top  pt-2 pb-2 bg-light">
            <nav className="navbar navbar-expand-lg navbar-light bg-light p-0">
              <div className="container-fluid">
                <a className="navbar-brand">
                  <AntSkeleton.Button
                    size={"large"}
                    style={{ width: "100px" }}
                  />
                </a>
                <div className="d-flex align-items-center d-md-none">
                  {/* Small button groups (default and split) */}
                </div>
                <button
                  className="navbar-toggler d-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse  d-flex justify-content-end"
                  id="navbarSupportedContent"
                  style={{ marginLeft: "2rem" }}
                >
                  <ul
                    className="navbar-nav me-auto mb-2 mb-lg-0 pt-2 pb-2 d-none d-md-flex"
                    style={{ marginLeft: "2.5rem" }}
                  >
                    <li style={{ marginRight: "15px", marginLeft: "5rem" }}>
                      <div className="topaddress">
                        <div className="d-flex align-items-center">
                          <AntSkeleton.Avatar
                            size={"large"}
                            style={{
                              height: "60px",
                              width: "60px",
                              marginRight: "9px",
                            }}
                          />

                          <div className="address-text">
                            <Skeleton style={{ width: "80px" }} />
                            <small className="d-block text-muted">
                              <Skeleton style={{ width: "150px" }} />
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li style={{ marginRight: "15px" }}>
                      <div className="topaddress">
                        <div className="d-flex align-items-center">
                          <AntSkeleton.Avatar
                            size={"large"}
                            style={{
                              height: "60px",
                              width: "60px",
                              marginRight: "9px",
                            }}
                          />

                          <div className="address-text">
                            <Skeleton style={{ width: "80px" }} />
                            <small className="d-block text-muted">
                              <Skeleton style={{ width: "150px" }} />
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="topaddress">
                        <div className="d-flex align-items-center">
                          <AntSkeleton.Avatar
                            size={"large"}
                            style={{
                              height: "60px",
                              width: "60px",
                              marginRight: "9px",
                            }}
                          />

                          <div className="address-text">
                            <Skeleton style={{ width: "80px" }} />
                            <small className="d-block text-muted">
                              <Skeleton style={{ width: "150px" }} />
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </header>
          {bannerImages?.find((item) => item.bannerType == 1)?.banners?.[0]
            ?.image && (
            <div className="topslider home_slider">
              <Skeleton height={310} />
            </div>
          )}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* ***** Header Area End ***** */}
                <section className="pt-3">
                  <div className="container p-0" />
                </section>
                {/* vertical tab */}
                <section className="pt-2">
                  <div className="row">
                    <div className="col-md-12">
                      {/* Tab panes */}
                      <div className="tab-content restro-content">
                        <div
                          role="tabpanel"
                          className="tab-pane restro-pane fade show active"
                          id="tab1"
                        >
                          <div className=" fooditems">
                            <div className="section-header-left mb-3 mt-0">
                              <h4 className="text-dark header-title fw-bold" />
                            </div>
                            <div className="row">
                              {array?.map((item) => <ProductCard />)}
                            </div>
                            <div className="row">
                              {array?.map((item) => <ProductCard />)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-3 ">
          {/* <AddToCart /> */}
          <Skeleton height={"100vh"} width={"100%"} />
        </div>
      </div>
    </div>
  );
};

export default BakerySkeleton;
