/** @format */
import "antd/dist/antd.css";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import MetaTags from "./Components/MetaTags/MetaTags";
import Rootpage from "./Pages/Rootpage/index.jsx";
import SelectedStore from "./Pages/Stores/index.jsx";
import Store from "./Redux/store";

function App() {
  return (
    <Provider store={Store()}>
      <MetaTags />
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Rootpage />} />
          <Route path="/:type/:storeName/*" element={<SelectedStore />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
