/** @format */

import { ProductCard } from "./BakerySkeleton";

const ProductLoadingSkeleton = () => {
  const array = new Array(4).fill(0).map(() => Math.random());
  return (
    <div className="row">
      <div className="col-md-12">
        {/* Tab panes */}
        <div className="tab-content restro-content">
          <div
            role="tabpanel"
            className="tab-pane restro-pane fade show active"
            id="tab1"
          >
            <div className=" fooditems">
              <div className="section-header-left mb-3 mt-0">
                <h4 className="text-dark header-title fw-bold" />
              </div>
              <div className="row">
                {array.map((item) => (
                  <ProductCard />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductLoadingSkeleton;
