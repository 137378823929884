/** @format */

const footer = () => {
  return (
    <footer className=" p-2 mt-2">
      <div className="">
        {/* Bottom Credits */}
        <div className="col-md-12 text-center">
          <p className="fw-bold">
            COPYRIGHT © 2024 , Designed by{" "}
            <a
              href="https://posapt.au"
              target={"_blank"}
              className="text-warning"
            >
              POSApt
            </a>
          </p>
        </div>
      </div>
      {/* /container */}
      {/* Go To Top Link */}
    </footer>
  );
};

export default footer;
