/** @format */

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import { Footer } from "../../../Components/Footer/Footer";
import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen";
import Navbar from "../../../Components/Navbar/Navbar";
import { openNotificationWithIcon } from "../../../Components/Notification/Success.tsx";
import ReserveTable from "../../../Components/ReserveTable/ReserveTable";
import SearchProduct from "../../../Components/SearchProducts/SearchProduct";
import Subscribe from "../../../Components/Subscribe";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../Helpers/FrontendHelper";
import ProductDetails from "./ProductDetails";
import ProductListing from "./ProductListing";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function RestaurantTemplate({ homePageData, storeState, isLoadingHome }) {
  const dispatch = useDispatch();
  const storeReducer = useSelector((state) => state.storeReducer);
  const productVersion = useSelector(
    (state) => state.storeReducer.storeData.productVersion
  );
  const unUpdatedHomePageData = useSelector(
    (state) => state.storeReducer.unUpdatedHomePageData
  );
  const isSearchModalOpen = useSelector(
    (state) => state.StateManager.isSearchModalOpen
  );
  const themeColors = useSelector(
    (state) => state.storeReducer.homePageData.themeColors
  );

  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);
  const [deliveryMethod, setDeliveryMethod] = useState([]);
  const [metaTags, setMetaTags] = useState({});
  const [popups, setPopups] = useState({});
  const [productsWithCategories, setProductsWithCategories] = useState([]);
  const [storeDetails, setStoreDetails] = useState({});
  const [storeTaxSettings, setStoreTaxSettings] = useState({});
  const [categories, setCategories] = useState([]);
  const [activeFood, setActiveFood] = useState("");
  const [reserverTableModalOpen, setReserverTableModalOpen] = useState(false);
  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false);
  const [cartData, setCartData] = useState({});
  const [value, setValue] = React.useState(0);
  const tabRef = useRef();
  const [updatedCartData, setUpdatedCartData] = useState(
    getLocalStorage(`${storeId}-${storeState}`)
  );
  const [activeItemSelected, setActiveItemSelected] = useState("");
  const [isProductDetailModalOpen, setIsProductDetailModalOpen] = useState(
    false
  );
  const [isPopupClosed, setIsPopClosed] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedTab = tabRef.current.querySelector(
      `[data-index="${newValue}"]`
    );
    selectedTab.scrollIntoView({ behavior: "smooth", block: "nearest" });
  };
  useEffect(() => {
    setUpdatedCartData(getLocalStorage(`${storeId}-${storeState}`));
  }, [storeId, storeState]);

  let query = useQuery();
  const authenticationState = useSelector((state) => state.authentication);
  useEffect(() => {
    if (!getLocalStorage(`${storeId}-${storeState}`)?.orderDetails) {
      setLocalStorage(`${storeId}-${storeState}`, {
        storeId: storeId,
        userInformation: {},
        tableId: "",
        orderDetails: [],
        productVersion: productVersion,
      });
    } else {
      if (
        getLocalStorage(`${storeId}-${storeState}`)?.productVersion !=
        productVersion
      ) {
        localStorage.clear();

        function deleteAllCookies() {
          var cookies = document.cookie.split(";");

          for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          }
        }
        deleteAllCookies();
        // dispatch({
        //   type: "LOGOUT_REQUEST",
        //   payload: {
        //     storeId,
        //     storeState,
        //   },
        // });

        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
    }
  }, []);
  useEffect(() => {
    const addToCartData = getLocalStorage(`${storeId}-${storeState}`);
    if (authenticationState.userData.userId) {
      setLocalStorage(`${storeId}-${storeState}`, {
        ...addToCartData,
        userInformation: authenticationState.userData,
      });
    }

    if (query.get("TableId")) {
      setLocalStorage(`${storeId}-${storeState}`, {
        ...addToCartData,
        tableId: query.get("TableId"),
      });
    } else {
      setLocalStorage(`${storeId}-${storeState}`, {
        ...addToCartData,
        tableId: "",
      });
    }
  }, [authenticationState.userData, query.get("TableId")]);

  useEffect(() => {
    const addToCartData = getLocalStorage(`${storeId}-${storeState}`);
    setCartData(addToCartData);
    setUpdatedCartData(addToCartData);
  }, [storeId, storeState]);

  useEffect(() => {
    setDeliveryMethod(unUpdatedHomePageData?.deliveryMethod);
    setMetaTags(unUpdatedHomePageData?.metaTags);
    setPopups(unUpdatedHomePageData?.popUps);
    setProductsWithCategories(unUpdatedHomePageData?.productsWithCategories);
    setStoreDetails(unUpdatedHomePageData?.storeDetails);
    setStoreTaxSettings(unUpdatedHomePageData?.storeTaxSettings);
    setCategories(unUpdatedHomePageData?.productsWithCategories);

    if (unUpdatedHomePageData.popUps) {
      setSubscribeModalOpen(true);
    }
    if (!activeItemSelected) {
      setActiveItemSelected(
        unUpdatedHomePageData?.productsWithCategories?.[0]?.categoryId
      );
    }
  }, [unUpdatedHomePageData]);

  const addToCartHandler = (item) => {
    const addToCartData = getLocalStorage(`${storeId}-${storeState}`);

    const defaultVariation = item.productVariations?.filter(
      (item) => item.isDefault
    );

    if (
      addToCartData?.orderDetails
        ?.filter((item1) => item1.id == item.id)
        ?.filter(
          (item1) => item1.productVariations[0].id == defaultVariation[0].id
        ).length > 0
    ) {
      // Checking if data is already in local Storage

      setLocalStorage(`${storeId}-${storeState}`, {
        ...addToCartData,
        orderDetails: addToCartData.orderDetails?.map((itemUpdating) => {
          if (
            itemUpdating.productVariations[0]?.id ==
            addToCartData.orderDetails
              .filter((item1) => item1.id == item.id)
              ?.filter(
                (item10) =>
                  item10.productVariations[0].id == defaultVariation[0].id
              )[0].productVariations[0].id
          ) {
            return {
              ...itemUpdating,
              productVariations: itemUpdating.productVariations.map((item) => {
                return { ...item, quantity: item.quantity + 1 };
              }),
            };
          } else {
            return {
              ...itemUpdating,
            };
          }
        }),
      });

      dispatch(
        openNotificationWithIcon(
          "success",
          "Product Added to Cart Successfully"
        )
      );

      dispatch({
        type: "CHANGE_LOCAL_STORAGE_DATA",
        payload: {
          newData: {
            ...addToCartData,
            orderDetails: addToCartData.orderDetails?.map((itemUpdating) => {
              if (
                itemUpdating.productVariations[0]?.id ==
                addToCartData.orderDetails
                  .filter((item1) => item1.id == item.id)
                  ?.filter(
                    (item10) =>
                      item10.productVariations[0].id == defaultVariation[0].id
                  )[0].productVariations[0].id
              ) {
                return {
                  ...itemUpdating,
                  productVariations: itemUpdating.productVariations.map(
                    (item) => {
                      return { ...item, quantity: item.quantity + 1 };
                    }
                  ),
                };
              } else {
                return {
                  ...itemUpdating,
                };
              }
            }),
          },
        },
      });

      return;
    }
    setLocalStorage(`${storeId}-${storeState}`, {
      ...addToCartData,
      orderDetails: [
        ...addToCartData.orderDetails,
        {
          ...item,
          productVariations: item.productVariations
            .map((item) => {
              return { ...item, quantity: 1 };
            })
            ?.filter((item) => item.isDefault),
        },
      ],
    });

    dispatch({
      type: "CHANGE_LOCAL_STORAGE_DATA",
      payload: {
        newData: {
          ...addToCartData,
          orderDetails: [
            ...addToCartData.orderDetails,
            {
              ...item,
              productVariations: item.productVariations
                .map((item) => {
                  return { ...item, quantity: 1 };
                })
                ?.filter((item) => item.isDefault),
            },
          ],
        },
      },
    });

    dispatch(
      openNotificationWithIcon("success", "Product Added to Cart Successfully ")
    );
  };

  const checkOutOfStock = (item) => {
    const defaultVariation = item.productVariations?.filter(
      (item) => item.isDefault
    );

    if (defaultVariation[0]?.stockCount == "0") {
      return {
        isOutOfStock: true,
        outOfStockMessage: defaultVariation[0]?.outOfStockMessage,
      };
    } else {
      return {
        isOutOfStock: false,
      };
    }
  };

  useEffect(() => {
    if (storeReducer.isOperationSuccessfull) {
      setReserverTableModalOpen(false);
    }
  }, [storeReducer.isOperationSuccessfull]);

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    if (isMobile) {
      if (value > activeItemSelected) {
        const nextTab = tabRef.current.querySelector(
          `[data-index="${value - 1}"]`
        );
        if (nextTab) {
          setValue(value - 1);
          nextTab.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      } else {
        const nextTab = tabRef.current.querySelector(
          `[data-index="${value + 1}"]`
        );
        if (nextTab) {
          setValue(value + 1);
          nextTab.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }
  }, [activeItemSelected]);

  return (
    <>
      {" "}
      {isLoadingHome || storeReducer.isLoading ? (
        <>
          <LoadingScreen />
        </>
      ) : (
        <>
          {" "}
          <Navbar
            updatedCartData={updatedCartData}
            homePageData={homePageData}
            storeState={storeState}
            setUpdatedCartData={setUpdatedCartData}
            showSearchIcon={true}
          >
            <Box
              sx={{
                minWidth: "100vw",
                bgcolor: "background.paper",
                color: "#E63948",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                style={{
                  overflowX: "auto",
                  overflowY: "hidden",
                  whiteSpace: "nowrap",
                }}
                ref={tabRef}
                data-testid="tabs"
              >
                {categories?.map((item, index) => {
                  return (
                    <Link
                      key={Math.random()}
                      className={
                        activeItemSelected == item.categoryId
                          ? "category-active tab-link"
                          : "tab-link"
                      }
                      to={item.categoryId}
                      spy={true}
                      duration={50}
                      isDynamic={true}
                      onSetActive={() => {
                        setActiveItemSelected(item.categoryId);
                      }}
                      smooth={true}
                    >
                      <Tab data-index={index} label={item.categoryName} />
                    </Link>
                  );
                })}
              </Tabs>
            </Box>
            {/* <Button onClick={handleNext}>Next</Button> */}
          </Navbar>
          <ProductListing
            productsWithCategories={productsWithCategories}
            checkOutOfStock={checkOutOfStock}
            setActiveFood={setActiveFood}
            addToCartHandler={addToCartHandler}
            isSearchList={false}
            setIsProductDetailModalOpen={setIsProductDetailModalOpen}
            homePageData={homePageData}
            setReserverTableModalOpen={setReserverTableModalOpen}
            alertMessage={true}
          />
          {productsWithCategories?.length > 0 && <Footer />}
        </>
      )}
      <Modal
        className="subscrible-modal"
        visible={subscribeModalOpen && !isPopupClosed && true}
        footer={null}
        onCancel={() => {
          setSubscribeModalOpen(false);
          setIsPopClosed(true);
        }}
      >
        <Subscribe
          setSubscribeModalOpen={setSubscribeModalOpen}
          homePageData={homePageData}
          isLoadingHome={isLoadingHome}
        />
      </Modal>
      <Modal
        className="product-detail-modal"
        visible={isProductDetailModalOpen}
        onCancel={() => {
          setIsProductDetailModalOpen(false);
        }}
        footer={null}
      >
        <ProductDetails
          activeFood={activeFood}
          updatedCartData={updatedCartData}
          setUpdatedCartData={setUpdatedCartData}
          setIsProductDetailModalOpen={setIsProductDetailModalOpen}
          setActiveFood={setActiveFood}
          checkOutOfStock={checkOutOfStock}
        />
      </Modal>
      <Modal
        width={"80vw"}
        className="search-product-modal"
        style={{ top: "2rem" }}
        visible={isSearchModalOpen}
        footer={null}
        onCancel={() => {
          dispatch({ type: "CLOSE_SEARCH_MODAL" });
        }}
      >
        <SearchProduct
          addToCartHandler={addToCartHandler}
          productsWithCategorie={productsWithCategories}
          setActiveFood={setActiveFood}
          setIsProductDetailModalOpen={setIsProductDetailModalOpen}
          homePageData={homePageData}
          setReserverTableModalOpen={setReserverTableModalOpen}
        />
      </Modal>
      <Modal
        width={"80vw"}
        style={{ top: "2rem" }}
        visible={reserverTableModalOpen}
        footer={null}
        className="reserve-table-modal"
        closeIcon={
          <i
            className="fas fa-times btn-close1"
            data-bs-dismiss="modal"
            aria-label="close"
          />
        }
        onCancel={() => setReserverTableModalOpen(false)}
      >
        <ReserveTable />
      </Modal>
    </>
  );
}

export default RestaurantTemplate;
