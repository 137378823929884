/** @format */

import {
  getLocalStorage,
  removeCookie,
  setCookie,
} from "../../Helpers/FrontendHelper";

import { setLocalStorage } from "../../Helpers/FrontendHelper";

const initialState = {
  isLoading: false,
  userData: {},
  registerResponse: {},
  isLoggedIn: false,
  isSendLinkSuccess: false,
  googleLoginLoading: false,
  sendOTPLoading: false,
  isSendOTPSuccess: false,
  show: false,
  showErrorOnForm: false,
  type: "",
  message: "",
  description: "",
};

export default function(state = initialState, action) {
  const { type, payload, storeId, storeState, isGoogleLogin } = action;
  const oldLocal = getLocalStorage(`${storeId}-${storeState}`);

  switch (type) {
    case "SHOW_NOTIFICATION":
      return {
        ...state,
        show: true,
        type: action.payload.type,
        message: action.payload.message,
      };
    case "CLOSE_NOTIFICATION":
      return {
        ...state,
        show: false,
        type: "",
        message: "",
      };

    case "LOGIN_WITH_GOOGLE_REQUEST":
      return {
        ...state,
        googleLoginLoading: true,
      };
    case "AUTHENTICATE_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
        showErrorOnForm: false,
      };
    case "AUTHENTICATE_USER_SUCCESS":
      setCookie(`token-${storeId}`, payload.token);
      setLocalStorage(`${storeId}-${storeState}`, {
        ...oldLocal,

        userInformation: {
          fullName: payload.name,
          email: payload.email,
          isAdmin: payload.isAdmin,
          userId: payload.userId,
          normalImage: payload.image,
        },
      });
      return {
        ...state,
        isLoading: false,
        userData: payload,

        isLoggedIn: true,
        userData: {
          fullName: payload.name,
          email: payload.email,
          isAdmin: payload.isAdmin,
          userId: payload.userId,
          normalImage: payload.image,
        },
        showErrorOnForm: false,
      };
    case "LOGOUT_REQUEST":
      const oldLocal2 = getLocalStorage(
        `${payload.storeId}-${payload.storeState}`
      );
      removeCookie(`token-${payload.storeId}`);
      setLocalStorage(`${payload.storeId}-${payload.storeState}`, {
        ...oldLocal2,
        userInformation: {},
      });

      return {
        ...state,
        isLoggedIn: false,
        userData: {},
      };

    case "AUTHENTICATE_USER_FAILURE":
      return {
        ...state,
        isLoading: false,
        showErrorOnForm: true,
        message: payload?.data?.message[0]?.message,
        type: "error",
      };
    case "REGISTER_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "REGISTER_USER_SUCCESS":
      if (isGoogleLogin) {
      } else {
      }

      setLocalStorage(`${storeId}-${storeState}`, {
        ...oldLocal,
        userInformation: {
          fullName: payload.name,
          email: payload.email,
          isAdmin: payload.isAdmin,
          userId: payload.userId,
          normalImage: payload.image,
        },
      });
      setCookie(`token-${storeId}`, payload.token);

      // setTimeout(() => {
      //   window.location.reload();
      // }, [2000]);
      return {
        ...state,
        googleLoginLoading: false,
        isLoading: false,
        isLoggedIn: true,
        registerResponse: payload,
        userData: {
          fullName: payload.name,
          email: payload.email,
          isAdmin: payload.isAdmin,
          userId: payload.userId,
          normalImage: payload.image,
        },
      };

    case "REGISTER_USER_FAILURE":
      return {
        ...state,
        googleLoginLoading: false,
        isLoading: false,
        show: true,
        message: payload?.data?.message[0]?.message,
        type: "error",
      };
    case "FORGOT_PASSWORD_REQUEST":
      return {
        ...state,
        isLoading: true,
        showErrorOnForm: false,
      };
    case "FORGOT_PASSWORD_SUCCESS":
      return {
        ...state,
        isLoading: false,
        showErrorOnForm: false,
        isSendLinkSuccess: true,
      };

    case "FORGOT_PASSWORD_FAILURE":
      return {
        ...state,
        isLoading: false,
        showErrorOnForm: true,
        message: payload?.data?.message[0]?.message,
        type: "error",
      };
    case "CLEAR_SEND_LINK_SUCCESS":
      return {
        ...state,
        isSendLinkSuccess: false,
      };
    case "CHANGE_PASSWORD_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "CHANGE_PASSWORD_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isPasswordChangedSucces: true,
        show: true,
        message: "Your password has been changed ",
        type: "success",
      };

    case "CHANGE_PASSWORD_FAILURE":
      return {
        ...state,
        isLoading: false,
        show: true,
        message: payload?.data?.message[0]?.message,
        type: "error",
      };
    case "RESET_PASSWORD_REQUEST":
      return {
        ...state,
        isLoading: true,
        isPasswordChangedSucces: false,
      };
    case "RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isPasswordChangedSucces: true,
        show: true,
        message: "Your password has been changed ",
        type: "success",
      };

    case "RESET_PASSWORD_FAILURE":
      return {
        ...state,
        isLoading: false,
        show: true,
        message: payload,
        type: "error",
      };
    case "SEND_OTP_REQUEST":
      return {
        ...state,
        sendOTPLoading: true,
        isSendOTPSuccess: false,
        error: null,
      };
    case "SEND_OTP_REQUEST_RESEND":
      return {
        ...state,
        sendOTPLoading: true,
        isResendOTPSuccess: false,
        error: null,
      };
    case "SEND_OTP_SUCCESS_RESEND":
      return {
        ...state,
        sendOTPLoading: false,
        isResendOTPSuccess: true,
        error: null,
      };
    case "SEND_OTP_SUCCESS":
      return {
        ...state,
        sendOTPLoading: false,
        isSendOTPSuccess: true,
        isResendOTPSuccess: false,
        success: payload.message,
      };
    case "SEND_OTP_FAILURE":
      return {
        ...state,
        isSendOTPSuccess: false,
        sendOTPLoading: false,
        error: payload,
      };

    case "SEND_OTP_PHONE_NUMBER_VALIDATION_REQUEST":
      return {
        ...state,
        sendOtpPhoneNumberLoading: true,
        isSendOtpPhoneNumberSuccessful: false,
        sendOtpFailureMessage: null,
      };
    case "SEND_OTP_PHONE_NUMBER_VALIDATION_SUCCESS":
      return {
        ...state,
        sendOtpPhoneNumberLoading: false,
        isSendOtpPhoneNumberSuccessful: true,
        sendOtpFailureMessage: null,
      };
    case "SEND_OTP_PHONE_NUMBER_VALIDATION_REQUEST_RESEND":
      return {
        ...state,
        sendOtpPhoneNumberLoading: true,
        isResendOTPPhoneNumberValidationSuccess: false,
        sendOtpFailureMessage: null,
      };
    case "SEND_OTP_PHONE_NUMBER_VALIDATION_SUCCESS_RESEND":
      return {
        ...state,
        sendOtpPhoneNumberLoading: false,
        isResendOTPPhoneNumberValidationSuccess: true,
        sendOtpFailureMessage: null,
      };
    case "SEND_OTP_PHONE_NUMBER_VALIDATION_FAILURE":
      return {
        ...state,
        sendOtpPhoneNumberLoading: false,
        isSendOtpPhoneNumberSuccessful: false,
        sendOtpFailureMessage: payload?.data?.message[0]?.message,
        isResendOTPPhoneNumberValidationSuccess: false,
      };
    case "VALIDATE_PHONE_NUMBER_REQUEST":
      localStorage.setItem("isPhoneNumberValidated", false);

      return {
        ...state,
        validatePhoneNumberLoading: true,
        isOTPValidationSuccessful: false,
        validatePhoneNumberFailureMessage: null,
      };
    case "VALIDATE_PHONE_NUMBER_SUCCESS":
      localStorage.setItem("isPhoneNumberValidated", true);
      return {
        ...state,
        validatePhoneNumberLoading: false,
        isOTPValidationSuccessful: true,
        validatePhoneNumberFailureMessage: null,
      };
    case "VALIDATE_PHONE_NUMBER_FAILURE":
      localStorage.setItem("isPhoneNumberValidated", false);
      return {
        ...state,
        validatePhoneNumberLoading: false,
        isOTPValidationSuccessful: false,
        validatePhoneNumberFailureMessage: payload?.data?.message[0]?.message,
      };
    case "CLEAR_FORM":
      return {
        ...state,
        isSendOTPSuccess: false,
        isCLearForm: true,
      };
    case "CLEAR_ERROR":
      return {
        ...state,
        error: null,
      };
    case "CLEAR_SEND_OTP_PHONE_NUMBER_SUCCESSFUL":
      return {
        ...state,
        isSendOtpPhoneNumberSuccessful: false,
        isOTPValidationSuccessful: false,
        validatePhoneNumberFailureMessage: null,
        sendOtpFailureMessage: null,
      };
    default:
      return state;
  }
}
