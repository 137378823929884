/** @format */

import { Form, Input, Select, Spin } from "antd";
import { gapi } from "gapi-script";
import { useEffect, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { GoogleLogin } from "react-google-login";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button/Button.jsx";

const { Option } = Select;

const clientId =
  "957933860561-46sr470503hi8qtg4s1emihv8pfu1iam.apps.googleusercontent.com";

const Register = ({
  storeId,
  storeState,
  showModal,
  setShowModal,
  setShowLoginModal,
}) => {
  const [otpForm] = Form.useForm();
  const [RegisterForm] = Form.useForm();
  const countryList = useSelector(
    (state) => state.storeReducer.homePageData?.listCountry
  );
  const [otp, setOTP] = useState("");
  const {
    googleLoginLoading,
    sendOTPLoading,
    success,
    isSendOTPSuccess,
    error,
  } = useSelector((state) => state.authentication);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_CLIENT_ID,
        scope: "email",
      });
    }

    gapi.load("client:auth2", start);
  }, []);
  // useEffect(() => {

  // },[])

  const authenticationState = useSelector((state) => state.authentication);
  useEffect(() => {
    if (authenticationState.isLoggedIn) {
      RegisterForm.resetFields();
      setShowModal(false);
    }
  }, [authenticationState.isLoggedIn]);
  const dispatch = useDispatch();

  const onRegisterFormSubmit = (values) => {
    dispatch({
      type: "REGISTER_USER_REQUEST",
      payload: {
        OTP: values.verificationCode,
        FullName: values.fullName,
        Password: values.password,
        Email: otpForm.getFieldValue("Email"),
        ConfirmPassword: values.confirmPassword,
        PhoneNumber: values.phone,
        CountryPhoneNumberPrefixId: values.prefix,
        CountryId: values.country,
        IsGoogleLogin: false,
        storeId,
        storeState,
      },
    });
  };
  const onOTPFormSubmit = (values) => {
    dispatch({
      type: "SEND_OTP_REQUEST",
      payload: {
        email: values.Email,
        storeId,
        storeState,
      },
    });
  };
  const onSuccess = (res) => {
    dispatch({
      type: "LOGIN_WITH_GOOGLE_REQUEST",
      payload: {
        ullName: res.profileObj.name,
        Password: "",
        ConfirmPassword: "",
        email: res.profileObj.email,
        GoogleImage: res.profileObj.imageUrl ? res.profileObj.imageUrl : "",

        PhoneNumber: "",
        storeId,
        storeState,
        IsGoogleLogin: true,
      },
    });
  };

  const onFailure = (res) => {};
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 120,
        }}
      >
        {countryList?.length > 0 &&
          countryList?.map((item) => (
            <Option value={item.id} key={item.id}>
              {" "}
              <span style={{ fontSize: "14px" }}>
                <img
                  src={item.image}
                  style={{ height: "20px", width: "30px", marginRight: "4px" }}
                />
                {item.additionalValue}
              </span>
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
  return (
    <Modal
      backdrop="static"
      show={showModal}
      onHide={() => {
        setShowModal(false);
        dispatch({
          type: "CLEAR_ERROR",
        });
      }}
      size="small"
      style={{ top: "0rem" }}
      className="register-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Register Now
        </Modal.Title>
      </Modal.Header>

      <div class="modal-body login log1">
        <div class="card2 card border-0 px-3 py-3">
          {isSendOTPSuccess && (
            <Form
              className="login"
              name="form"
              onFinish={onRegisterFormSubmit}
              autoComplete="off"
              initialValues={{
                remember: true,
              }}
              fields={[
                {
                  name: ["prefix"],
                  value: RegisterForm.getFieldValue("prefix")
                    ? RegisterForm.getFieldValue("prefix")
                    : countryList.length > 0 &&
                      countryList?.find((item) => item.isSelected)?.id,
                },
                {
                  name: ["country"],
                  value: RegisterForm.getFieldValue("country")
                    ? RegisterForm.getFieldValue("country")
                    : countryList.length > 0 &&
                      countryList?.find((item) => item.isSelected)?.id,
                },
              ]}
              form={RegisterForm}
            >
              <div class="row ">
                <div class="  col-md-12 col-lg-12 otp-container">
                  {/* <p className="verification-text ant-label">
                    Enter Verification Code:
                  </p> */}
                  <Alert variant="info">
                    We Have Sent Verifcation Code To Your Email. Please Check
                    And Enter Verification Code Here !
                  </Alert>

                  <Form.Item
                    label="Verification Code"
                    name="verificationCode"
                    rules={[
                      {
                        required: true,
                        message: "Please input Verification Code !",
                      },
                    ]}
                  >
                    <OtpInput
                      className="verification-container"
                      inputStyle={"ant-input"}
                      value={otp}
                      onChange={(e) => setOTP(e)}
                      numInputs={6}
                    />
                  </Form.Item>
                </div>

                <div class="  col-md-12 col-lg-6">
                  <Form.Item
                    label="Full Name"
                    name="fullName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your full name!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Full Name" class="form-control" />
                  </Form.Item>
                </div>
                <div class=" col-md-12 col-lg-6">
                  <Form.Item
                    label="Country"
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "Please input Country !",
                      },
                    ]}
                  >
                    <Select style={{ height: "48px", paddingBottom: "1rem" }}>
                      {countryList.length > 0 &&
                        countryList?.map((item) => (
                          <Option value={item.id} key={item.id}>
                            {" "}
                            <span style={{ fontSize: "14px" }}>
                              <img
                                src={item.image}
                                style={{
                                  height: "20px",
                                  width: "30px",
                                  marginRight: "4px",
                                }}
                              />
                              {item.value}
                            </span>
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
                <div class="  col-md-12 col-lg-12">
                  {" "}
                  <Form.Item
                    label="Phone"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                    ]}
                  >
                    <Input
                      addonBefore={prefixSelector}
                      placeholder="Enter Phone"
                      class="form-control"
                    />
                  </Form.Item>
                </div>

                <div class="  col-md-12 col-lg-6">
                  {" "}
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      className="login-password"
                      type={"password"}
                      placeholder="Enter password"
                      class="form-control"
                    />
                  </Form.Item>
                </div>
                <div class=" col-md-12 col-lg-6">
                  {" "}
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please input confirm password!",
                      },
                    ]}
                  >
                    <Input.Password
                      className="login-password"
                      type={"password"}
                      placeholder="Enter Confirm Password"
                      class="form-control"
                    />
                  </Form.Item>
                </div>

                <div class=" mb-3  mt-3">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={authenticationState.isLoading}
                      className="primary-button"
                    >
                      {" "}
                      {authenticationState.isLoading ? "" : "Register"}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          )}
          {!isSendOTPSuccess && (
            <Form
              className="login"
              name="form"
              onFinish={onOTPFormSubmit}
              autoComplete="off"
              initialValues={{
                remember: true,
              }}
              form={otpForm}
            >
              <div class="row">
                <div class="  col-md-12 col-lg-12">
                  {error && <Alert variant="danger">{error}</Alert>}

                  <Form.Item
                    label="Email Address"
                    name="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                      {
                        type: "email",
                        message: "Please enter valid email!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter email" class="form-control" />
                  </Form.Item>
                </div>

                <div class=" mb-3  mt-3">
                  <Form.Item>
                    <Button
                      className="primary-button"
                      style={{ width: "100%" }}
                      type="primary"
                      htmlType="submit"
                      loading={sendOTPLoading}
                    >
                      {" "}
                      {authenticationState.isLoading ? "" : "Send OTP"}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          )}

          <div class="row px-3 mb-2">
            <div class="line" /> <small class="or text-center">Or</small>
            <div class="line" />
          </div>
          <GoogleLogin
            render={(renderProps) => (
              <a
                href="#"
                onClick={renderProps.onClick}
                class="social-button"
                id="google-connect"
              >
                {" "}
                <span>
                  {!googleLoginLoading ? (
                    "Login With Google"
                  ) : (
                    <Spin
                      style={{
                        margin: "auto",
                        marginLeft: "6rem",
                      }}
                    />
                  )}
                </span>
              </a>
            )}
            icon={false}
            clientId={clientId}
            buttonText="Login with Google"
            onSuccess={onSuccess}
            onFailure={onFailure}
          />

          <div class="row ">
            {" "}
            <p class="fw-bold">
              Already have an account?{" "}
              <a
                class="text-danger "
                onClick={() => {
                  setShowModal(false);
                  setShowLoginModal(true);
                }}
                data-bs-toggle="modal"
                data-bs-target="#loginModal"
              >
                Login
              </a>
            </p>{" "}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Register;
