/** @format */

import { Form, Input } from "antd";
import { useState } from "react";
// import {
//   formatCVC,
//   formatCreditCardNumber,
//   formatExpirationDate,
// } from "../../Helpers/CreditCardValidation";

import { CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";

const CheckoutForm = ({ form, CardNumberElement }) => {
  // const name = Form.useWatch("name", form);
  // const cardNumber = Form.useWatch("cardNumber", form);
  // const mm = Form.useWatch("mm", form);
  // const yy = Form.useWatch("yy", form);
  // const cvc = Form.useWatch("cvc", form);
  // const [issuer, setIssuer] = useState("");
  // const [focused, setFocused] = useState("");

  // const handleCallback = ({ issuer }, isValid) => {
  //   if (isValid) {
  //     setIssuer(issuer);
  //   }
  // };

  return (
    <div className="row">
      <div className="col-md-6">
        {" "}
        <Form.Item
          name={"stripeEmail"}
          label="Email Address"
          rules={[
            {
              required: true,
              message: "Please enter email address",
            },
          ]}
        >
          <Input placeholder="Email Address" />
        </Form.Item>
      </div>
      <div className="col-md-6">
        {" "}
        <Form.Item
          name={"cardNumber"}
          label="Card Number"
          rules={[
            {
              required: true,
              message: "Please enter card number",
            },
          ]}
        >
          <CardNumberElement
            className="stripe-input"
            // options={CARD_ELEMENT_OPTIONS}
          />
        </Form.Item>
      </div>
      <div className="col-md-6">
        {" "}
        <Form.Item
          name={"expiryDate"}
          label="Expiry Date"
          rules={[
            {
              required: true,
              message: "Please enter expiry date",
            },
          ]}
        >
          <CardExpiryElement
            className="stripe-input"
            // options={CARD_ELEMENT_OPTIONS}
          />
        </Form.Item>
      </div>
      <div className="col-md-6">
        {" "}
        <Form.Item
          name={"CVC"}
          label="CVC"
          rules={[
            {
              required: true,
              message: "Please enter CVC",
            },
          ]}
        >
          <CardCvcElement
            className="stripe-input"
            // options={CARD_ELEMENT_OPTIONS}
          />
        </Form.Item>
      </div>
    </div>
  );
};
export default CheckoutForm;
