/** @format */
import { useSelector } from "react-redux";

export const Footer = () => {
  const { homePageData } = useSelector((state) => state.storeReducer);
  const { copyRightFooterDescription } = homePageData || {};
  return (
    <footer className="footer-res p-2 mt-auto d-none d-md-block">
      <div className="">
        <div className="col-md-12 text-center">
          {/* {metaTags?.footerText ? (
            <p className="fw-bold ">{metaTags?.footerText} </p>
          ) : (
            <p className="text-dark">
              COPYRIGHT © 2024 , Designed by{" "}
              <a
                href="https://posapt.au"
                target={"_blank"}
                className="text-dark"
              >
                POSApt
              </a>
            </p>
          )} */}
          <div
            dangerouslySetInnerHTML={{ __html: copyRightFooterDescription }}
          />
        </div>
      </div>
    </footer>
  );
};
