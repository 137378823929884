import React from "react";
import { Button, Tag, Typography } from "antd";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LazyImage from "../../Pages/Stores/Restaurant/LazyImage";
import ProductComponentWithCommas from "../MyOrder/ProductComponentWithCommas";
import { openNotificationWithIcon } from "../Notification/Success";

const CreateHalfHalfItem = ({
  isCreateHalfHalfItemOpen,
  setIsCreateHalfHalfItemOpen,
  groupedProducts,
  setGroupedProducts,
  setIsHalfHalfProduct,
}) => {
  const dispatch = useDispatch();
  const currencySymbol = useSelector(
    (state) => state.storeReducer.storeData.currencySymbol
  );

  const firstHalfProduct = groupedProducts[0];
  const secondHalfProduct = groupedProducts[1];

  const firstHalfPrice = Number(
    firstHalfProduct?.productVariations[0]?.price ?? 0
  );
  const secondHalfPrice = secondHalfProduct
    ? Number(secondHalfProduct?.productVariations[0]?.price)
    : 0;
  const highestPrice = Math.max(firstHalfPrice, secondHalfPrice);

  const totalModifierPrice = groupedProducts.reduce((acc, product) => {
    const productTotalModifierPrice = product?.productVariations[0]?.OrderItemsPriceModifierViewModels.reduce(
      (modifierAcc, modifier) => {
        return (
          modifierAcc +
          parseFloat(modifier.TotalModifierPrice ?? modifier.totalModifierPrice)
        );
      },
      0
    );
    return acc + productTotalModifierPrice;
  }, 0);

  // const totalModifierPrice = 0;

  const totalAmount =
    Number(highestPrice ? highestPrice : 0) +
    Number(totalModifierPrice ? totalModifierPrice : 0);

  const removeProduct = (productToRemove) => {
    const updatedGroupedProducts = groupedProducts.filter(
      (product) => product !== productToRemove
    );

    setGroupedProducts(updatedGroupedProducts);
  };

  const halfHalfItemAddToCartHandler = () => {
    if (groupedProducts.length == 2) {
      groupedProducts.forEach((halfItem) => {
        dispatch({
          type: "SET_HALF_HALF_ITEM_CART",
          payload: {
            ...halfItem,
            Quantity: 0.5,
            HalfGroupAmount: highestPrice,
          },
        });
      });
      setIsHalfHalfProduct(false);
      setGroupedProducts([]);
      setIsCreateHalfHalfItemOpen(false);
      // setToBeUpdateProdcutFromHalfHalf(null);
      setTimeout(() => {
        dispatch(
          openNotificationWithIcon(
            "success",
            "Product Added to Cart Successfully "
          )
        );
      }, [500]);
    }
  };
  return (
    <Modal
      size="lg"
      centered
      show={isCreateHalfHalfItemOpen}
      onHide={() => {
        setIsHalfHalfProduct(false);
        setGroupedProducts([]);
        // setToBeUpdateProdcutFromHalfHalf(null);
        setIsCreateHalfHalfItemOpen(false);
      }}
    >
      <Modal.Header closeButton className="modal-header">
        <div className="row flex-direction-column">
          <Typography.Text strong id="exampleModalLabel">
            Create Half Half Item
          </Typography.Text>
          <Typography.Text type="secondary">
            Create Unique Half Half Items by combining two products into a
            single item.
          </Typography.Text>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div
          className="d-flex justify-content-center align-items-center d-flex-column-md"
          style={{ flexDirection: "column", gap: "1rem" }}
        >
          <Button
            type="dashed"
            className="w-100 mb-3"
            style={{
              minHeight: 180,
              height: "auto",
              minWidth: 150,
              maxWidth: 300, // Adjust maximum width as needed
              textAlign: "center", // Center text
            }}
            onClick={() => {
              if (!firstHalfProduct) {
                setIsCreateHalfHalfItemOpen(false);
              }
            }}
          >
            {firstHalfProduct ? (
              <div className="d-flex flex-column align-items-center">
                <img
                  src={firstHalfProduct?.productImage}
                  style={{ width: 80, height: 80 }}
                />
                <Typography.Text className="mt-2" strong>
                  {firstHalfProduct?.productName}
                </Typography.Text>
                <ProductComponentWithCommas product={firstHalfProduct} />
                <Typography.Text>
                  {highestPrice ==
                  Number(firstHalfProduct?.productVariations[0]?.price) ? (
                    <>
                      {currencySymbol}{" "}
                      {firstHalfProduct?.productVariations[0]?.price}
                    </>
                  ) : (
                    // <Tag color="green">Free</Tag>
                    <></>
                  )}
                </Typography.Text>
                <div gap={4} className="mt-2">
                  <Button
                    type="default"
                    danger
                    onClick={() => {
                      removeProduct(firstHalfProduct);
                    }}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            ) : (
              <Typography.Text strong className="theme-color-text">
                + Add first half
              </Typography.Text>
            )}
          </Button>
          <Button
            type="dashed"
            className="w-100"
            style={{
              minHeight: 180,
              height: "auto",
              minWidth: 150,
              maxWidth: 300, // Adjust maximum width as needed
              textAlign: "center", // Center text
            }}
            onClick={() => {
              if (!secondHalfProduct) {
                setIsCreateHalfHalfItemOpen(false);
              }
            }}
          >
            {secondHalfProduct ? (
              <div className="d-flex flex-column align-items-center">
                <img
                  src={secondHalfProduct?.productImage}
                  style={{ width: 80, height: 80 }}
                />
                <Typography.Text className="mt-2" strong>
                  {secondHalfProduct?.productName}
                </Typography.Text>
                <ProductComponentWithCommas product={secondHalfProduct} />
                <Typography.Text>
                  {highestPrice ==
                  Number(secondHalfProduct?.productVariations[0]?.price) ? (
                    <>
                      {currencySymbol}{" "}
                      {secondHalfProduct?.productVariations[0]?.price}
                    </>
                  ) : (
                    // <Tag color="green">Free</Tag>
                    <></>
                  )}
                </Typography.Text>
                <div gap={4} className="mt-2">
                  <Button
                    type="default"
                    danger
                    onClick={() => {
                      removeProduct(secondHalfProduct);
                    }}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            ) : (
              <Typography.Text strong className="theme-color-text">
                + Add second half
              </Typography.Text>
            )}
          </Button>
        </div>

        <div className="mt-3 d-flex justify-content-center">
          <Typography.Text strong>
            Total Amount : {currencySymbol} {totalAmount}
          </Typography.Text>
        </div>

        <div gap={6} className="mt-4 d-flex justify-content-end gap-2">
          <Button
            type="primary"
            danger
            onClick={() => {
              setGroupedProducts([]);
            }}
            disabled={groupedProducts.length == 0}
          >
            Clear
          </Button>
          <Button
            type="primary"
            danger
            disabled={!(groupedProducts.length == 2)}
            onClick={() => {
              halfHalfItemAddToCartHandler();
            }}
          >
            {" "}
            Add to cart
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateHalfHalfItem;
