/** @format */

const initialState = {
  isLoading: false,
  deliveryLocation: [],
  deliveryLocationGuest: [],
  checkoutSectionList: [],
  isInitiateOrderSuccess: false,
  orderSucceedDetails: {},
  checkoutLoading: false,
  isInitiateOrderSuccess1: false,
  paymentMethodId: "",
  isOrderAlreadyPlaced: false,
  stripeCheckoutLoading: false,
  getCheckoutSectionLoading: false,
  orderId: "",
  checkoutData: {},
  checkVoucherLoading: false,
  isCheckVoucherSuccess: false,
  voucherDiscount: "",
  checkoutAmounts: {},
  addDeliveryLocationLoading: false,
  isAddDeliverySuccess: false,
  show: false,
  showErrorOnForm: false,
  type: "",
  message: "",
  description: "",
};

export default function(state = initialState, action) {
  const { type, payload, isGuest, getPrice } = action;
  switch (type) {
    case "CLOSE_NOTIFICATION":
      return {
        ...state,
        show: false,
        type: "",
        message: "",
      };

    case "DELIVERY_LOCATION_ADD_REQUEST_GUEST":
      return {
        ...state,
        addDeliveryLocationLoading: true,
        isAddDeliverySuccess: false,
        showErrorOnForm: false,
      };
    case "DELIVERY_LOCATION_ADD_REQUEST":
      return {
        ...state,
        addDeliveryLocationLoading: true,
        isAddDeliverySuccess: false,
        showErrorOnForm: false,
      };
    case "DELIVERY_LOCATION_ADD_SUCCESS_GUEST":
      if (!getPrice) {
        // openNotificationWithIcon("success", "Location Added Successfully !");
      }
      if (isGuest) {
        return {
          ...state,
          deliveryLocationGuest: [...state.deliveryLocation, payload].reverse(),
          isAddDeliverySuccess: true,
          addDeliveryLocationLoading: false,
          show: true,
          message: "Location Added Successfully",
          type: "success",
          showErrorOnForm: false,
          deliveryPrice: payload.price,
        };
      }
      if (getPrice) {
        return {
          ...state,
          deliveryPrice: payload.price,
          // isAddDeliverySuccess: true,
          addDeliveryLocationLoading: false,
          showErrorOnForm: false,
        };
      }
      return {
        ...state,
        deliveryPrice: payload.price,
        isAddDeliverySuccess: true,
        addDeliveryLocationLoading: false,
      };
    case "DELIVERY_LOCATION_ADD_SUCCESS":
      if (!getPrice) {
        // openNotificationWithIcon("success", "Location Added Successfully !");
      }
      if (isGuest) {
        return {
          ...state,
          deliveryLocation: [...state.deliveryLocation, payload].reverse(),
          isAddDeliverySuccess: true,
          addDeliveryLocationLoading: false,
          show: true,
          message: "Location Added Successfully",
          type: "success",
        };
      }
      if (getPrice) {
        return {
          ...state,
          deliveryPrice: payload.price,
          // isAddDeliverySuccess: true,
          addDeliveryLocationLoading: false,
        };
      }
      return {
        ...state,
        deliveryPrice: payload.price,
        isAddDeliverySuccess: true,
        addDeliveryLocationLoading: false,
      };
    case "DELIVERY_LOCATION_ADD_FAILURE":
      return {
        ...state,
        deliveryError: payload,
        isLoading: false,
        deliveryChangeSuccess: false,
        isAddDeliverySuccess: false,
        addDeliveryLocationLoading: false,
        showErrorOnForm: true,
        message: payload?.data?.message[0]?.message,
        type: "error",
      };
    case "DELIVERY_LOCATION_DELETE_REQUEST":
      return {
        ...state,
        getCheckoutSectionLoading: true,
        deliveryPrice: 0,
        deliveryLocation: [
          ...state.deliveryLocation.filter(
            (location) => location.id !== payload.id
          ),
        ],
        // deliveryChangeSuccess: false,
      };
    case "DELIVERY_LOCATION_DELETE_SUCCESS":
      let newArray;
      if (isGuest) {
        newArray = state.deliveryLocation.filter(
          (location) => location.DeliveryLocation !== payload.DeliveryLocation
        );
      } else {
        newArray = state.deliveryLocation.filter(
          (location) => location.id !== payload.id
        );
      }
      return {
        ...state,
        deliveryChangeSuccess: true,
        deliveryLocation: [...newArray],
        getCheckoutSectionLoading: false,
        show: true,
        message: "Location Deleted Successfully",
        type: "success",
      };
    case "DELIVERY_LOCATION_DELETE_FAILURE":
      return {
        ...state,
        deliveryError: payload,
        getCheckoutSectionLoading: false,
        show: true,
        message: payload?.data?.message[0]?.message,
        type: "error",
      };
    case "CHECKOUT_SECTION_LIST_REQUEST":
      return {
        ...state,
        getCheckoutSectionLoading: true,
      };
    case "CHECKOUT_SECTION_LIST_SUCCESS":
      return {
        ...state,
        checkoutData: payload,
        checkoutSectionList: payload.paymentMethods,
        getCheckoutSectionLoading: false,
        deliveryLocation:
          payload.deliveryAddressByUser != null
            ? payload.deliveryAddressByUser
            : [],
      };
    case "CHECKOUT_SECTION_LIST_FAILURE":
      return {
        ...state,
        checkoutListError: payload,
        getCheckoutSectionLoading: false,
      };
    case "GUEST_CHECKOUT_SECTION_LIST_REQUEST":
      return {
        ...state,
        getCheckoutSectionLoading: true,
      };
    case "GUEST_CHECKOUT_SECTION_LIST_SUCCESS":
      return {
        ...state,
        checkoutData: payload,
        checkoutSectionList: payload.paymentMethods,
        getCheckoutSectionLoading: false,
      };
    case "GUEST_CHECKOUT_SECTION_LIST_FAILURE":
      return {
        ...state,
        checkoutListError: payload,
        getCheckoutSectionLoading: false,
      };
    case "PLACE_ORDER_REQUEST":
      return {
        ...state,
        checkoutLoading: true,
        isInitiateOrderSuccess: false,
        isInitiateOrderSuccess1: false,
        orderSucceedDetails: {
          ...state.orderSucceedDetails,
          isOrderCompleted: false,
        },
        isOrderAlreadyPlaced: false,
      };
    case "PLACE_ORDER_REQUEST_GUEST":
      return {
        ...state,
        checkoutLoading: true,
        isInitiateOrderSuccess: false,
        isInitiateOrderSuccess1: false,
        orderSucceedDetails: {
          ...state.orderSucceedDetails,
          isOrderCompleted: false,
        },
        isOrderAlreadyPlaced: false,
      };
    case "PLACE_ORDER_SUCCESS":
      return {
        ...state,
        checkoutLoading: false,
        isOrderSuccess: true,
        orderSucceedDetails: payload.data,
        paymentMethodId: payload.paymentMethodId,
        orderId: payload.data.orderId,
      };
    case "PLACE_ORDER_FAILURE":
      if (
        payload.data.message[0].message ==
        "Order Already Placed.Please check your Order history"
      ) {
        return {
          ...state,
          checkoutLoading: false,
          isInitiateOrderSuccess: false,
          isOrderAlreadyPlaced: true,
        };
      } else {
        return {
          ...state,
          checkoutLoading: false,
          isInitiateOrderSuccess: false,
          show: true,
          message: payload?.data?.message[0]?.message,
          type: "error",
        };
      }
    case "CLEAR_ORDER_ALREADY_PLACED_STATE":
      return {
        ...state,
        isOrderAlreadyPlaced: false,
        orderSucceedDetails: {
          ...state.orderSucceedDetails,
          orderId: "",
          isOrderCompleted: false,
        },
        isOrderCompleted: false,
        isInitiateOrderSuccess1: false,
        isInitiateOrderSuccess: false,
      };
    case "MAKE_PAYMENT_REQUEST":
    case "MAKE_PAYMENT_REQUEST_GUEST":
      return {
        ...state,
        stripeCheckoutLoading: true,
        isInitiateOrderSuccess1: false,
      };
    case "MAKE_PAYMENT_SUCCESS":
      return {
        ...state,
        stripeCheckoutLoading: false,
        isInitiateOrderSuccess1: true,
      };
    case "MAKE_PAYMENT_FAILURE":
      return {
        ...state,
        stripeCheckoutLoading: false,
        isInitiateOrderSuccess1: false,
        show: true,
        message: payload?.data?.message[0]?.message,
        type: "error",
      };
    case "CHECK_VOUCHER_REQUEST":
      return {
        ...state,
        checkVoucherLoading: true,
        isCheckVoucherSuccess: false,
      };
    case "CHECK_VOUCHER_SUCCESS":
      return {
        ...state,
        checkVoucherLoading: false,
        isCheckVoucherSuccess: true,
        voucherDiscount: payload.discount,
      };
    case "CHECK_VOUCHER_FAILURE":
      return {
        ...state,
        checkVoucherLoading: false,
        isCheckVoucherSuccess: false,
        show: true,
        message: payload?.data?.message[0]?.message,
        type: "error",
      };
    case "REMOVE_CHECK_VOUCHER":
      return {
        ...state,
        isCheckVoucherSuccess: false,
        isOrderSuccess: false,
        voucherDiscount: "",
      };
    case "STORE_CHECKOUT_AMOUNTS":
      return {
        ...state,
        checkoutAmounts: payload,
      };
    default:
      return state;
  }
}
