/** @format */

import React from "react";
import Skeleton from "react-loading-skeleton";
import { Skeleton as AntSkeleton } from "antd";

const RestaurantSkeleton = () => {
  return (
    <>
      <header className="sticky-top  pt-2 pb-2 bg-light">
        <nav className="navbar navbar-expand-lg navbar-light bg-light p-0">
          <div className="container-fluid">
            <a className="navbar-brand">
              <AntSkeleton.Button size={"large"} style={{ width: "100px" }} />
            </a>
            <div className="d-flex align-items-center d-md-none">
              {/* Small button groups (default and split) */}
            </div>
            <button
              className="navbar-toggler d-none"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse  d-flex justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 pt-2 pb-2 d-none d-md-flex">
                <li style={{ marginRight: "15px", marginLeft: "5rem" }}>
                  <div className="topaddress">
                    <div className="d-flex align-items-center">
                      <AntSkeleton.Avatar
                        size={"large"}
                        style={{
                          height: "50px",
                          width: "50px",
                          marginRight: "9px",
                        }}
                      />

                      <div className="address-text">
                        <Skeleton style={{ width: "80px" }} />
                        <small className="d-block text-muted">
                          <Skeleton style={{ width: "150px" }} />
                        </small>
                      </div>
                    </div>
                  </div>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <div className="topaddress">
                    <div className="d-flex align-items-center">
                      <AntSkeleton.Avatar
                        size={"large"}
                        style={{
                          height: "50px",
                          width: "50px",
                          marginRight: "9px",
                        }}
                      />

                      <div className="address-text">
                        <Skeleton style={{ width: "80px" }} />
                        <small className="d-block text-muted">
                          <Skeleton style={{ width: "150px" }} />
                        </small>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="topaddress">
                    <div className="d-flex align-items-center">
                      <AntSkeleton.Avatar
                        size={"large"}
                        style={{
                          height: "50px",
                          width: "50px",
                          marginRight: "9px",
                        }}
                      />

                      <div className="address-text">
                        <Skeleton style={{ width: "80px" }} />
                        <small className="d-block text-muted">
                          <Skeleton style={{ width: "150px" }} />
                        </small>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              {/* <div className="newsearch">
                <a
                  href=""
                  data-bs-toggle="modal"
                  data-bs-target="#searchnowModalCenter"
                >
                  <i className="fas fa-search" />
                </a>
              </div>
              <div className="cart-btn ms-2 d-flex align-items-center">
                <a
                  href="#"
                  className="text-light-green fw-700"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvascart"
                  aria-controls="offcanvascart"
                >
                  {" "}
                  <i className="fas fa-shopping-bag" />
                  <span className="user-alert-cart">3</span>
                </a>
              </div>
              <div className="d-none d-md-flex align-items-center ">
                <div className="loginreg ms-4">
                  <a
                    href=""
                    className="fw-bold text-red"
                    data-bs-toggle="modal"
                    data-bs-target="#loginModal"
                  >
                    <i className="fas fa-user" /> Login/Register
                  </a>
                </div>
                <div className="logout">
                  <div className="btn-group ms-3 ">
                    <img
                      className="img-xs rounded-circle"
                      src="assets/images/face8.jpg"
                      alt="Profile image"
                    />
                    <button
                      className="btn btn-secondary btn-sm dropdown-toggle bg-transparent border-0 text-red fw-bold"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      SAJAN
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="profile.html">
                        <i className="fas fa-user me-2" />
                        Profile
                      </a>
                      <a className="dropdown-item" href="login.html">
                        <i className="fas fa-power-off me-2" />
                        Logout
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </nav>
        <div className="container-fluid  ">
          <div className="scroll_tab">
            {/* Nav tabs */}
            <ul className="nav nav-tabs restro-tab desktop" role="tablist">
              <li className="nav-item restro-item">
                <a className="nav-link  restronav" href="#tab1">
                  <Skeleton width={100} />
                </a>
              </li>
              <li className="nav-item restro-item">
                <a className="nav-link restronav" href="#tab2">
                  <Skeleton width={100} />
                </a>
              </li>
              <li className="nav-item restro-item">
                <a className="nav-link restronav" href="#tab4">
                  <Skeleton width={100} />
                </a>
              </li>
              <li className="nav-item restro-item">
                <a className="nav-link restronav" href="#tab5">
                  <Skeleton width={100} />
                </a>
              </li>
              <li className="nav-item restro-item">
                <a className="nav-link restronav" href="#tab6">
                  <Skeleton width={100} />
                </a>
              </li>
              <li className="nav-item restro-item">
                <a className="nav-link restronav" href="#tab7">
                  <Skeleton width={100} />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link restronav" href="#tab8">
                  <Skeleton width={100} />
                </a>
              </li>
              <li className="nav-item restro-item">
                <a className="nav-link restronav" href="#tab10">
                  <Skeleton width={100} />
                </a>
              </li>
              <li className="nav-item restro-item">
                <a className="nav-link restronav" href="#tab11">
                  <Skeleton width={100} />
                </a>
              </li>
              <li className="nav-item restro-item">
                <a className="nav-link restronav" href="#tab12">
                  <Skeleton width={100} />
                </a>
              </li>
            </ul>
            <ul className="nav nav-tabs restro-tab mobile" role="tablist">
              <li className="nav-item restro-item">
                <a className="nav-link  restronav" href="#tab1">
                  <Skeleton width={300} inline={true} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            {/* vertical tab */}
            <section className="pt-2">
              <div className="">
                <div className="row">
                  <div className="col-md-12">
                    <div className="scroll_tab">
                      {/* Tab panes */}
                      <div className="tab-content restro-content">
                        <div
                          role="tabpanel"
                          className="tab-pane restro-pane fade show active"
                          id="tab1"
                        >
                          <div
                            style={{
                              background: "#F8F9FA",
                              marginTop: "7rem",
                              border: "none",
                              height: "40px",
                            }}
                            className="alert alert-danger mt-4 mb-0 d-flex align-items-center justify-content-between"
                            role="alert"
                          />
                          <div className=" fooditems">
                            <div className="section-header-left mb-3">
                              <div className="row">
                                <div className="col-md-6">
                                  <h4 className="text-dark header-title fw-bold" />
                                </div>
                                {/* <a href="" class="btn-first green-btn text-custom-white fw-500 enqbtn">Reserve Table</a> */}
                                {/* <div class="col-md-1">
                                                      <div class="newsearch">
                                                          <a href="" data-bs-toggle="modal" data-bs-target="#searchnowModalCenter"><i class="fas fa-search"></i></a>
                                                      </div>
                                                  </div> */}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 col-6 col-lg-2 col-xxl-2 ">
                                <div className="product-box mb-xl-20">
                                  <a
                                    href=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#foodModal"
                                  >
                                    <div className="product-img">
                                      <Skeleton height={150} />
                                    </div>
                                  </a>
                                  <div className="product-caption">
                                    <div className="title-box">
                                      <h6 className="product-title">
                                        <a
                                          href=""
                                          className="text-light-black "
                                          data-bs-toggle="modal"
                                          data-bs-target="#foodModal"
                                        >
                                          {" "}
                                        </a>
                                      </h6>
                                    </div>
                                    <p
                                      className="text-light-white"
                                      style={{ marginTop: "-1rem" }}
                                    >
                                      <Skeleton
                                        style={{ marginTop: "-1rem" }}
                                      />
                                    </p>
                                    <p className="text-light-white">
                                      <Skeleton />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 col-lg-2 col-xxl-2 ">
                                <div className="product-box mb-xl-20">
                                  <a
                                    href=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#foodModal"
                                  >
                                    <div className="product-img">
                                      <Skeleton height={150} />
                                    </div>
                                  </a>
                                  <div className="product-caption">
                                    <div className="title-box">
                                      <h6 className="product-title">
                                        <a
                                          href=""
                                          className="text-light-black "
                                          data-bs-toggle="modal"
                                          data-bs-target="#foodModal"
                                        >
                                          {" "}
                                        </a>
                                      </h6>
                                    </div>
                                    <p
                                      className="text-light-white"
                                      style={{ marginTop: "-1rem" }}
                                    >
                                      <Skeleton
                                        style={{ marginTop: "-1rem" }}
                                      />
                                    </p>
                                    <p className="text-light-white">
                                      <Skeleton />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 col-lg-2 col-xxl-2 ">
                                <div className="product-box mb-xl-20">
                                  <a
                                    href=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#foodModal"
                                  >
                                    <div className="product-img">
                                      <Skeleton height={150} />
                                    </div>
                                  </a>
                                  <div className="product-caption">
                                    <div className="title-box">
                                      <h6 className="product-title">
                                        <a
                                          href=""
                                          className="text-light-black "
                                          data-bs-toggle="modal"
                                          data-bs-target="#foodModal"
                                        />
                                      </h6>
                                    </div>
                                    <p
                                      className="text-light-white"
                                      style={{ marginTop: "-1rem" }}
                                    >
                                      <Skeleton
                                        style={{ marginTop: "-1rem" }}
                                      />
                                    </p>
                                    <p className="text-light-white">
                                      <Skeleton />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 col-lg-2 col-xxl-2 ">
                                <div className="product-box mb-xl-20">
                                  <a
                                    href=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#foodModal"
                                  >
                                    <div className="product-img">
                                      <Skeleton height={150} />
                                    </div>
                                  </a>
                                  <div className="product-caption">
                                    <div className="title-box">
                                      <h6 className="product-title">
                                        <a
                                          href=""
                                          className="text-light-black "
                                          data-bs-toggle="modal"
                                          data-bs-target="#foodModal"
                                        >
                                          {" "}
                                        </a>
                                      </h6>
                                    </div>
                                    <p
                                      className="text-light-white"
                                      style={{ marginTop: "-1rem" }}
                                    >
                                      <Skeleton
                                        style={{ marginTop: "-1rem" }}
                                      />
                                    </p>
                                    <p className="text-light-white">
                                      <Skeleton />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 col-lg-2 col-xxl-2 ">
                                <div className="product-box mb-xl-20">
                                  <a
                                    href=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#foodModal"
                                  >
                                    <div className="product-img">
                                      <Skeleton height={150} />
                                    </div>
                                  </a>
                                  <div className="product-caption">
                                    <div className="title-box">
                                      <h6 className="product-title">
                                        <a
                                          href=""
                                          className="text-light-black "
                                          data-bs-toggle="modal"
                                          data-bs-target="#foodModal"
                                        >
                                          {" "}
                                        </a>
                                      </h6>
                                    </div>
                                    <p
                                      className="text-light-white"
                                      style={{ marginTop: "-1rem" }}
                                    >
                                      <Skeleton
                                        style={{ marginTop: "-1rem" }}
                                      />
                                    </p>
                                    <p className="text-light-white">
                                      <Skeleton />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 col-lg-2 col-xxl-2 ">
                                <div className="product-box mb-xl-20">
                                  <a
                                    href=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#foodModal"
                                  >
                                    <div className="product-img">
                                      <Skeleton height={150} />
                                    </div>
                                  </a>
                                  <div className="product-caption">
                                    <div className="title-box">
                                      <h6 className="product-title">
                                        <a
                                          href=""
                                          className="text-light-black "
                                          data-bs-toggle="modal"
                                          data-bs-target="#foodModal"
                                        >
                                          {" "}
                                        </a>
                                      </h6>
                                    </div>
                                    <p
                                      className="text-light-white"
                                      style={{ marginTop: "-1rem" }}
                                    >
                                      <Skeleton
                                        style={{ marginTop: "-1rem" }}
                                      />
                                    </p>
                                    <p className="text-light-white">
                                      <Skeleton />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 col-lg-2 col-xxl-2 ">
                                <div className="product-box mb-xl-20">
                                  <a
                                    href=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#foodModal"
                                  >
                                    <div className="product-img">
                                      <Skeleton height={150} />
                                    </div>
                                  </a>
                                  <div className="product-caption">
                                    <div className="title-box">
                                      <h6 className="product-title">
                                        <a
                                          href=""
                                          className="text-light-black "
                                          data-bs-toggle="modal"
                                          data-bs-target="#foodModal"
                                        >
                                          {" "}
                                        </a>
                                      </h6>
                                    </div>
                                    <p
                                      className="text-light-white"
                                      style={{ marginTop: "-1rem" }}
                                    >
                                      <Skeleton
                                        style={{ marginTop: "-1rem" }}
                                      />
                                    </p>
                                    <p className="text-light-white">
                                      <Skeleton />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 col-lg-2 col-xxl-2 ">
                                <div className="product-box mb-xl-20">
                                  <a
                                    href=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#foodModal"
                                  >
                                    <div className="product-img">
                                      <Skeleton height={150} />
                                    </div>
                                  </a>
                                  <div className="product-caption">
                                    <div className="title-box">
                                      <h6 className="product-title">
                                        <a
                                          href=""
                                          className="text-light-black "
                                          data-bs-toggle="modal"
                                          data-bs-target="#foodModal"
                                        >
                                          {" "}
                                        </a>
                                      </h6>
                                    </div>
                                    <p
                                      className="text-light-white"
                                      style={{ marginTop: "-1rem" }}
                                    >
                                      <Skeleton
                                        style={{ marginTop: "-1rem" }}
                                      />
                                    </p>
                                    <p className="text-light-white">
                                      <Skeleton />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 col-lg-2 col-xxl-2 ">
                                <div className="product-box mb-xl-20">
                                  <a
                                    href=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#foodModal"
                                  >
                                    <div className="product-img">
                                      <Skeleton height={150} />
                                    </div>
                                  </a>
                                  <div className="product-caption">
                                    <div className="title-box">
                                      <h6 className="product-title">
                                        <a
                                          href=""
                                          className="text-light-black "
                                          data-bs-toggle="modal"
                                          data-bs-target="#foodModal"
                                        >
                                          {" "}
                                        </a>
                                      </h6>
                                    </div>
                                    <p
                                      className="text-light-white"
                                      style={{ marginTop: "-1rem" }}
                                    >
                                      <Skeleton
                                        style={{ marginTop: "-1rem" }}
                                      />
                                    </p>
                                    <p className="text-light-white">
                                      <Skeleton />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 col-lg-2 col-xxl-2 ">
                                <div className="product-box mb-xl-20">
                                  <a
                                    href=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#foodModal"
                                  >
                                    <div className="product-img">
                                      <Skeleton height={150} />
                                    </div>
                                  </a>
                                  <div className="product-caption">
                                    <div className="title-box">
                                      <h6 className="product-title">
                                        <a
                                          href=""
                                          className="text-light-black "
                                          data-bs-toggle="modal"
                                          data-bs-target="#foodModal"
                                        >
                                          {" "}
                                        </a>
                                      </h6>
                                    </div>
                                    <p
                                      className="text-light-white"
                                      style={{ marginTop: "-1rem" }}
                                    >
                                      <Skeleton
                                        style={{ marginTop: "-1rem" }}
                                      />
                                    </p>
                                    <p className="text-light-white">
                                      <Skeleton />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 col-lg-2 col-xxl-2 ">
                                <div className="product-box mb-xl-20">
                                  <a
                                    href=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#foodModal"
                                  >
                                    <div className="product-img">
                                      <Skeleton height={150} />
                                    </div>
                                  </a>
                                  <div className="product-caption">
                                    <div className="title-box">
                                      <h6 className="product-title">
                                        <a
                                          href=""
                                          className="text-light-black "
                                          data-bs-toggle="modal"
                                          data-bs-target="#foodModal"
                                        >
                                          {" "}
                                        </a>
                                      </h6>
                                    </div>
                                    <p
                                      className="text-light-white"
                                      style={{ marginTop: "-1rem" }}
                                    >
                                      <Skeleton
                                        style={{ marginTop: "-1rem" }}
                                      />
                                    </p>
                                    <p className="text-light-white">
                                      <Skeleton />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 col-lg-2 col-xxl-2 ">
                                <div className="product-box mb-xl-20">
                                  <a
                                    href=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#foodModal"
                                  >
                                    <div className="product-img">
                                      <Skeleton height={150} />
                                    </div>
                                  </a>
                                  <div className="product-caption">
                                    <div className="title-box">
                                      <h6 className="product-title">
                                        <a
                                          href=""
                                          className="text-light-black "
                                          data-bs-toggle="modal"
                                          data-bs-target="#foodModal"
                                        >
                                          {" "}
                                        </a>
                                      </h6>
                                    </div>
                                    <p
                                      className="text-light-white"
                                      style={{ marginTop: "-1rem" }}
                                    >
                                      <Skeleton
                                        style={{ marginTop: "-1rem" }}
                                      />
                                    </p>
                                    <p className="text-light-white">
                                      <Skeleton />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default RestaurantSkeleton;
