/** @format */

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import isMobile from "../../Helpers/PrivateRoute/isMobile";
import useOnlineHelper from "../../Helpers/useOnlineHelper";
import AddToCart from "../BakeryComponents/AddToCart";

const Layout = ({ myOrderComponent, children }) => {
  const storesCartData = useSelector(
    (state) => state.StateManager.storesCartData
  );
  const halfHalfProducts = useSelector(
    (state) => state.StateManager.halfHalfProducts
  );
  const { pathname } = useLocation();
  const {
    tableIdFromQuery,
    isDineTrackOrderDisplay,
    trackOrderClickHandler,
  } = useOnlineHelper();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    document.body.style.overflow = "auto"; // Ensure overflow is enabled
    return () => {
      document.body.style.overflow = ""; // Reset on cleanup
    };
  }, []);

  return (
    <>
      {/* {tableIdFromQuery && <DineInLocationAccess />} */}
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-md-8 col-lg-8 col-xl-9 d-flex flex-column mb-md-0 mb-3"
            style={{
              overflowY: "auto",
              minHeight: isMobile ? "auto" : "100vh",
            }}
          >
            {children}
          </div>
          <div className="col-md-4 col-lg-4  col-xl-3 p-0 m-0 bg-white">
            <div className="sticky-top m-0 p-0">
              <AddToCart myOrderComponent={myOrderComponent} />
            </div>
          </div>
        </div>

        <div
          className="fixed-bottom d-md-none d-flex justify-content-between m-2"
          style={{
            zIndex: "1",
          }}
        >
          {isDineTrackOrderDisplay() && (
            <a
              className="btn-first track-order-button green-btn text-custom-white fw-500 flex-grow-1 me-2 fs-6 fw-bold"
              style={{
                zIndex: "1",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
              onClick={() => trackOrderClickHandler()}
            >
              <i className="fas fa-map-marker-alt me-2" />
              My Table Order
            </a>
          )}
          <a
            data-bs-toggle="offcanvas"
            data-bs-target="#shoppingCart"
            aria-controls="shoppingCart"
            className={`btn-first view-order-button green-btn text-custom-white fw-500 fs-6 fw-bold ${
              tableIdFromQuery ? "flex-grow-1 ms-2" : "w-100"
            }`}
            style={{
              zIndex: "1",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <i className="fas fa-shopping-cart me-2" />
            View Order{" "}
            <span className="order-items-number">
              {Number(
                (storesCartData?.orderDetails?.length ?? 0) +
                  (halfHalfProducts?.length ?? 0) / 2
              ) || 0}
            </span>
          </a>
        </div>
      </div>
    </>
  );
};

export default Layout;
