/** @format */

import React from "react";
import { Skeleton } from "antd";

const Subscribe = ({ homePageData, isLoadingHome, setSubscribeModalOpen }) => {
  return (
    <>
      {" "}
      <div>
        <div class="modal-body">
          {isLoadingHome ? (
            <Skeleton />
          ) : (
            <div class="row text-center">
              <div class="col-md-12 ">
                <h3 class="text-center">{homePageData?.popUps?.title}</h3>
                <br />
              </div>

              <br />
              <p style={{ color: "#3D3D4E" }} class="text-center fs-6 mb-3 ">
                {homePageData?.popUps?.description}
              </p>
              <span class="input-group-btn" data-bs-dismiss="modal">
                <a
                  href={
                    homePageData?.popUps?.buttonLink
                      ? homePageData?.popUps?.buttonLink
                      : ""
                  }
                  target={"_blank"}
                  onClick={() => {
                    setSubscribeModalOpen(false);
                  }}
                  class="btn btn-dark  primary-button"
                >
                  {homePageData?.popUps?.buttonLabel}
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Subscribe;
