/** @format */

import { message, Tabs } from "antd";
import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { openNotificationWithIcon } from "../../Components/Notification/Success.tsx";
import { getLocalStorage, setLocalStorage } from "../../Helpers/FrontendHelper";
import handleImageError from "../../Helpers/handleImageError.js";
import ProductPriceModifiers from "./ProductPriceModifiers.jsx";
import ProductRemoveIngredients from "./ProductRemoveIngredients.jsx";
import ProductSpiceChoices from "./ProductSpiceChoices.jsx";

const ProductDetails = ({
  activeFood,
  setActiveFood,
  setIsProductDetailModalOpen,
  isHalfHalfProduct,
  setIsHalfHalfProduct,
  groupedProducts,
  setGroupedProducts,
  setIsCreateHalfHalfItemOpen,
}) => {
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const [activeFoodVariations, setActiveFoodVariations] = useState();
  const [addOns, setAddOns] = useState();
  const [activeaddOnsVariants, setActiveAdOnsVariants] = useState();
  const [checkedAdsOn, setCheckedAdsOn] = useState([]);
  const [totalSum, setTotalSum] = useState("");
  const [isAddToCartSuccess, setIsAddToCartSuccess] = useState(false);
  const [checkedModifier, setCheckedModifier] = useState([]);
  const [removedProductIngredients, setRemovedProductIngredients] = useState(
    []
  );
  const [checkedSpiceChoice, setCheckedSpiceChoice] = useState("{}");
  const homePageData = useSelector((state) => state.storeReducer.homePageData);

  const currencyCode = useSelector(
    (state) => state.storeReducer.storeData.currencyCode
  );
  const currencySymbol = useSelector(
    (state) => state.storeReducer.storeData.currencySymbol
  );

  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);
  const storeState = useSelector(
    (state) => state.storeReducer.storeData.templateName
  );

  const calucateTaxHandler = (quantity, price, item) => {
    if (homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "3") {
      return (
        (parseFloat(price * quantity) * item?.taxExclusiveInclusiveValue) / 100
      );
    } else if (
      homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "2"
    ) {
      return (
        (parseFloat(price * quantity) * item?.taxExclusiveInclusiveValue) /
        (1 + item?.taxExclusiveInclusiveValue)
      );
    } else {
      return "0";
    }
  };

  useEffect(() => {
    setQuantity(1);
  }, [activeFood]);

  useEffect(() => {
    setIsAddToCartSuccess(false);
  }, []);

  useEffect(() => {
    setActiveFoodVariations(
      activeFood.productVariations?.map((item) => {
        return {
          ...item,
          isChecked: item.isDefault ? true : false,
        };
      })
    );
    setAddOns(
      activeFood.addOns?.map((item1) => {
        return {
          ...item1,
          quantity: 1,
          checkedVariation: null,
        };
      })
    );
  }, [activeFood]);

  const addOnAddHandler = () => {
    // Logic For Ad Ons

    const selectedAdons = addOns
      .filter((itemss) => itemss.checkedVariation)
      .map((item1) => {
        return {
          ...item1,
          productVariations: item1.productVariations
            .filter((item2) => item2.id == item1.checkedVariation)
            .map((productVar) => {
              return { ...productVar, quantity: item1.quantity };
            }),
        };
      });

    selectedAdons.map((addToCartAdOns) => {
      const addToCartData = getLocalStorage(`${storeId}-${storeState}`);

      if (
        // To Check if Product Variations is already in cart
        addToCartData?.orderDetails
          .filter((item1) => item1.id == addToCartAdOns.id)
          ?.filter(
            (item1) =>
              item1.productVariations[0].id ==
              addToCartAdOns.productVariations[0].id
          ).length > 0
      ) {
        // Updating the quantity with the new quantity in cart

        setLocalStorage(`${storeId}-${storeState}`, {
          ...addToCartData,
          orderDetails: addToCartData.orderDetails?.map((itemUpdating) => {
            if (
              itemUpdating.productVariations[0]?.id ==
              addToCartData.orderDetails
                .filter((item1) => item1.id == addToCartAdOns.id)
                ?.filter(
                  (item1) =>
                    item1.productVariations[0].id ==
                    addToCartAdOns.productVariations[0].id
                )[0]?.productVariations[0].id
            ) {
              return {
                ...itemUpdating,
                productVariations: itemUpdating.productVariations.map(
                  (item) => {
                    return {
                      ...item,
                      quantity:
                        item.quantity +
                        addToCartAdOns.productVariations[0].quantity,
                    };
                  }
                ),
              };
            } else {
              return {
                ...itemUpdating,
              };
            }
          }),
        });
        dispatch({
          type: "CHANGE_LOCAL_STORAGE_DATA",
          payload: {
            newData: {
              ...addToCartData,
              orderDetails: addToCartData.orderDetails?.map((itemUpdating) => {
                if (
                  itemUpdating.productVariations[0]?.id ==
                  addToCartData.orderDetails
                    .filter((item1) => item1.id == addToCartAdOns.id)
                    ?.filter(
                      (item1) =>
                        item1.productVariations[0].id ==
                        addToCartAdOns.productVariations[0].id
                    )[0]?.productVariations[0].id
                ) {
                  return {
                    ...itemUpdating,
                    productVariations: itemUpdating.productVariations.map(
                      (item) => {
                        return {
                          ...item,
                          quantity:
                            item.quantity +
                            addToCartAdOns.productVariations[0].quantity,
                        };
                      }
                    ),
                  };
                } else {
                  return {
                    ...itemUpdating,
                  };
                }
              }),
            },
          },
        });
      } else {
        setLocalStorage(`${storeId}-${storeState}`, {
          ...addToCartData,
          orderDetails: [
            ...addToCartData.orderDetails,
            {
              ...addToCartAdOns,
              productVariations: addToCartAdOns.productVariations.map(
                (item) => {
                  return {
                    ...item,
                    quantity: addToCartAdOns.productVariations[0].quantity,
                  };
                }
              ),
            },
          ],
        });
        dispatch({
          type: "CHANGE_LOCAL_STORAGE_DATA",
          payload: {
            newData: {
              ...addToCartData,
              orderDetails: [
                ...addToCartData.orderDetails,
                {
                  ...addToCartAdOns,
                  productVariations: addToCartAdOns.productVariations.map(
                    (item) => {
                      return {
                        ...item,
                        quantity: addToCartAdOns.productVariations[0].quantity,
                      };
                    }
                  ),
                },
              ],
            },
          },
        });

        // Setting to update the cart data
      }
    });
  };

  const addToCartHandler = () => {
    if (activeFood.addOns?.length > 0) {
      const selectedAdons = addOns
        .filter((itemss) => itemss.checkedVariation)
        .map((item1) => {
          return {
            ...item1,
            productVariations: item1.productVariations
              .filter((item2) => item2.id == item1.checkedVariation)
              .map((productVar) => {
                return {
                  ...productVar,
                  quantity: item1.quantity,
                  checkedModifier: checkedModifier,
                };
              }),
          };
        });
      if (selectedAdons?.length > 0) {
        addOnAddHandler();
      }
    }

    const addToCartData = getLocalStorage(`${storeId}-${storeState}`);
    const selectedVariation = activeFoodVariations.filter(
      (item) => item.isChecked
    )[0];

    if (selectedVariation?.stockCount == "0") {
      return openNotificationWithIcon(
        "info",
        "This variation is out of stock ! Please try others."
      );
    } else {
      let halfGroupKey = null;

      if (isHalfHalfProduct && groupedProducts.length === 0) {
        halfGroupKey = uuidv4();
      } else if (isHalfHalfProduct && groupedProducts.length === 1) {
        halfGroupKey = groupedProducts[0].HalfGroupKey;
      }
      // Adding new product if product variation is not already in Local Storage
      const addToCartPayload = {
        ...activeFood,
        HalfGroupKey: halfGroupKey,
        productSpiceChoices: JSON.parse(checkedSpiceChoice),
        productVariations: activeFoodVariations
          ?.map((variation) => {
            return {
              ...variation,
              quantity: quantity,
              OrderItemsPriceModifierViewModels: checkedModifier?.map(
                (item) => {
                  const modifier = JSON.parse(item);

                  return {
                    ModifierName: modifier.name,
                    ModifierPrice: modifier.price,
                    TotalModifierPrice: (
                      quantity * parseFloat(modifier.price)
                    ).toString(),
                    Quantity: quantity?.toString(),
                    PriceVariationModifierId: modifier.id,
                    TotalTax: calucateTaxHandler(
                      quantity,
                      modifier.price,
                      activeFood
                    )
                      ?.toFixed(2)
                      .toString(),
                  };
                }
              ),
              // checkedModifier: checkedModifier,
              RemovedOrderItemsIngredientsViewModels: removedProductIngredients?.map(
                (item) => {
                  const removedIngredient = JSON.parse(item);
                  return {
                    id: "",
                    name: removedIngredient.name,
                  };
                }
              ),
            };
          })
          ?.filter((item) => item.isChecked),
      };

      const updatedOrderDetailsCart = [
        ...addToCartData.orderDetails,
        addToCartPayload,
      ];
      const updatedCart = {
        ...addToCartData,
        orderDetails: updatedOrderDetailsCart,
      };

      if (isHalfHalfProduct) {
        setGroupedProducts((prev) => {
          return [...prev, addToCartPayload];
        });
        setIsCreateHalfHalfItemOpen(true);
        setIsProductDetailModalOpen(false);
      } else {
        setLocalStorage(`${storeId}-${storeState}`, updatedCart);
        dispatch({
          type: "CHANGE_LOCAL_STORAGE_DATA",
          payload: {
            newData: updatedCart,
          },
        });
        // setTimeout(() => {
        //   dispatch(
        //     openNotificationWithIcon(
        //       "success",
        //       "Product Added to Cart Successfully "
        //     )
        //   );
        // }, [500]);
        message.success("Product Added to Cart !", 2);
      }
    }

    setAddOns(
      activeFood.addOns?.map((item1) => {
        return {
          ...item1,
          quantity: 1,
          checkedVariation: null,
        };
      })
    );
    setActiveFood({});
    setIsProductDetailModalOpen(false);
  };
  useEffect(() => {
    let sum = 0;
    const selectedAdons = addOns
      ?.filter((itemss) => itemss.checkedVariation)
      ?.map((item1) => {
        return {
          ...item1,
          productVariations: item1.productVariations
            .filter((item2) => item2.id == item1.checkedVariation)
            .map((productVar) => {
              return { ...productVar, quantity: item1.quantity };
            }),
        };
      });
    let adOnsPrice = 0;
    selectedAdons?.forEach((item) => {
      adOnsPrice =
        adOnsPrice +
        item.productVariations[0].quantity * item.productVariations[0].price;
    });

    if (selectedAdons?.length > 0) {
      setTotalSum(
        sum +
          parseFloat(
            activeFoodVariations?.filter((item) => item.isChecked)[0]?.price *
              quantity
          ) +
          adOnsPrice
      );
    } else {
      setTotalSum(
        sum +
          parseFloat(
            activeFoodVariations?.filter((item) => item.isChecked)[0]?.price *
              quantity
          )
      );
    }
  }, [addOns, activeFoodVariations, quantity]);

  // const popoverContent = (modiferList, id) => {
  //   return (

  //   );
  // };

  useEffect(() => {
    if (checkedModifier?.length > 0) {
      setTotalSum(
        parseFloat(
          activeFoodVariations?.filter((item) => item.isChecked)[0]?.price *
            quantity
        ) +
          checkedModifier?.reduce((acc, item) => {
            return acc + parseFloat(JSON.parse(item).price * quantity);
          }, 0)
      );
    } else {
      setTotalSum(
        parseFloat(
          activeFoodVariations?.filter((item) => item.isChecked)[0]?.price *
            quantity
        )
      );
    }
  }, [checkedModifier, quantity]);

  return (
    <div class="">
      <div class="modal-body foodbody">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="indegridents-tab"
              data-bs-toggle="tab"
              data-bs-target="#indegridents"
              type="button"
              role="tab"
              aria-controls="indegridents"
              aria-selected="true"
            >
              Description
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="allergens-tab"
              data-bs-toggle="tab"
              data-bs-target="#allergens"
              type="button"
              role="tab"
              aria-controls="allergens"
              aria-selected="false"
            >
              Allergens
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="macros-tab"
              data-bs-toggle="tab"
              data-bs-target="#macros"
              type="button"
              role="tab"
              aria-controls="macros"
              aria-selected="false"
            >
              Macros
            </button>
          </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="indegridents"
            role="tabpanel"
            aria-labelledby="indegridents-tab"
          >
            <p class="fw-bold lh">
              {" "}
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(activeFood.productDescription),
                }}
              />
            </p>
            <div class="row mt-3 mb-3 g-0">
              <div class="col-md-4">
                <div class="menuimg rounded-0 h-100">
                  <img
                    onError={handleImageError}
                    src={activeFood.productImage}
                    class="img-fluid h-100"
                    alt=""
                    data-pagespeed-url-hash="1762518849"
                    // onLoad="pagespeed.CriticalImages.checkImageForCriticality(this);"
                  />
                </div>
              </div>
              <div class="col-md-8">
                <div class="proteinchoice bg-light p-3 h-100">
                  <div class="mychoice d-flex  justify-content-between">
                    <div class="section-header-left mb-3 mt-0">
                      <h4 class="text-dark header-title fw-bold">Variations</h4>
                      <small>Select one</small>
                      <span
                        class="badge bg-success"
                        style={{ marginLeft: "10px" }}
                      >
                        Required
                      </span>
                    </div>
                    <div class="qty-box">
                      <div class="quantity buttons_added">
                        <input
                          type="button"
                          value="-"
                          class="minus"
                          onClick={() =>
                            quantity <= 1 ? (
                              <></>
                            ) : (
                              setQuantity((prev) => prev - 1)
                            )
                          }
                        />
                        <input
                          type="number"
                          step="1"
                          min="1"
                          max="10"
                          value={quantity}
                          onChange={() => {}}
                          class="qty text"
                          size="4"
                          readOnly=""
                        />
                        <input
                          type="button"
                          value="+"
                          class="plus"
                          onClick={() => {
                            setQuantity((prev) => prev + 1);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <Tabs
                    type="card"
                    onChange={(key) => {
                      setCheckedModifier([]);
                      setActiveFoodVariations((prev) =>
                        prev.map((el) =>
                          el.id == key
                            ? {
                                ...el,
                                isChecked: true,
                              }
                            : {
                                ...el,
                                isChecked: false,
                              }
                        )
                      );
                      setQuantity(1);
                    }}
                    items={activeFoodVariations
                      ?.filter((item) => item.stockCount != "0")
                      .map((item) => {
                        return {
                          key: item.id,
                          label: item.name ? item.name : activeFood.productName,
                          children: (
                            <div>
                              {item.productPriceModifiers?.length > 0 && (
                                <ProductPriceModifiers
                                  modifierList={item.productPriceModifiers}
                                  variationId={item.id}
                                  activeFoodVariations={activeFoodVariations}
                                  checkedModifier={checkedModifier}
                                  setCheckedModifier={setCheckedModifier}
                                />
                              )}

                              {item?.productIngredients?.length > 0 && (
                                <ProductRemoveIngredients
                                  selectedProductVariation={item}
                                  removedProductIngredients={
                                    removedProductIngredients
                                  }
                                  setRemovedProductIngredients={
                                    setRemovedProductIngredients
                                  }
                                />
                              )}
                              {activeFood?.productSpiceChoices?.length > 0 && (
                                <ProductSpiceChoices
                                  spiceChoices={activeFood?.productSpiceChoices}
                                  checkedSpiceChoice={checkedSpiceChoice}
                                  setCheckedSpiceChoice={setCheckedSpiceChoice}
                                />
                              )}
                            </div>
                          ),
                        };
                      })}
                  />
                </div>
              </div>
            </div>
            <div class="recommended">
              <div class=" d-flex align-items-center justify-content-between">
                <div class="section-header-left mb-3 mt-0">
                  <h4 class="text-dark header-title fw-bold">Addons</h4>
                </div>
              </div>
              <div class="row">
                {addOns?.length > 0 ? (
                  addOns.map((item1) => {
                    if (item1?.productVariations?.length > 0) {
                      return (
                        <div key={item1.id} class="col-md-6">
                          <div class="card mb-3">
                            <div class="row g-0">
                              <div class="col-md-4">
                                <img
                                  onError={handleImageError}
                                  src={item1.productImage}
                                  class="img-fluid rounded-start h-100"
                                  alt="..."
                                />
                              </div>
                              <div class="col-md-8">
                                <div class="card-body">
                                  <h6 class="card-title fw-bold d-flex align-items-center justify-content-between">
                                    <a
                                      href=""
                                      class="text-dark"
                                      data-bs-toggle="modal"
                                      data-bs-target="#foodModal"
                                    >
                                      {item1.productName}
                                    </a>
                                    <div class="sign">
                                      <span
                                        id="minus"
                                        onClick={() => {
                                          setAddOns((prev) => {
                                            return prev.map((item) => {
                                              if (item.id == item1.id) {
                                                return {
                                                  ...item,
                                                  quantity:
                                                    item1.quantity <= 1
                                                      ? item1.quantity
                                                      : item1.quantity - 1,
                                                };
                                              } else {
                                                return {
                                                  ...item,
                                                };
                                              }
                                            });
                                          });
                                        }}
                                      >
                                        <i class="fa fa-minus" />
                                      </span>
                                      <small id="quantity">
                                        {item1.quantity}
                                      </small>{" "}
                                      <span
                                        id="plus"
                                        onClick={() => {
                                          setAddOns((prev) => {
                                            return prev.map((item) => {
                                              if (item.id == item1.id) {
                                                return {
                                                  ...item,
                                                  quantity: item1.quantity + 1,
                                                };
                                              } else {
                                                return {
                                                  ...item,
                                                };
                                              }
                                            });
                                          });
                                        }}
                                      >
                                        <i class="fa fa-plus" />
                                      </span>
                                    </div>
                                  </h6>
                                  <div class="myprice mt-3 pt-3 border-top">
                                    <div class="row">
                                      {item1.productVariations?.map((item) => {
                                        return (
                                          <div
                                            class="col-md-3 p1"
                                            key={item.id}
                                          >
                                            <p class="fw-bold text-muted">
                                              {currencySymbol} {item?.price}
                                            </p>
                                            <p class="d-flex align-items-center">
                                              {" "}
                                              <input
                                                type="checkbox"
                                                checked={
                                                  item.id ==
                                                  item1.checkedVariation
                                                }
                                                onChange={(e) => {
                                                  setAddOns(
                                                    addOns?.map((item4) => {
                                                      if (
                                                        item4.id == item1.id
                                                      ) {
                                                        return {
                                                          ...item4,
                                                          checkedVariation: e
                                                            .target.checked
                                                            ? item.id
                                                            : "",
                                                        };
                                                      }
                                                      return {
                                                        ...item4,
                                                      };
                                                    })
                                                  );
                                                }}
                                              />
                                              <small>{item.name}</small>
                                            </p>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <>
                    <p>No Addons Available !</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="allergens"
            role="tabpanel"
            aria-labelledby="allergens-tab"
          >
            {activeFood.allergens ? (
              <p class="fw-bold lh">{activeFood.allergens}</p>
            ) : (
              <p>No Allergens Found !</p>
            )}
          </div>
          <div
            class="tab-pane fade"
            id="macros"
            role="tabpanel"
            aria-labelledby="macros-tab"
          >
            <table class="table mydetails">
              <tbody>
                {activeFood.productMacros?.length > 0 ? (
                  activeFood.productMacros.map((item) => (
                    <tr>
                      <td>{item.name}</td>
                      <td>{item.value}</td>
                    </tr>
                  ))
                ) : (
                  <p>No Prodcut Macros Found !</p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer cartfoot d-flex justify-content-between">
        <p class="fs-6 fw-bold ">
          {currencyCode} {currencySymbol} {totalSum ? totalSum?.toFixed(2) : 0}
        </p>
        <div>
          <button
            onClick={addToCartHandler}
            disabled={totalSum == 0 ? true : false}
            style={{}}
            type="button"
            class="btn-first secondary-button border-0 text-custom-white fw-500 shadow-none"
          >
            <i class="fas fa-shopping-cart me-2" />{" "}
            {isHalfHalfProduct
              ? groupedProducts.length == 0
                ? "Add as 1st Half"
                : "Add as Second Half"
              : "Add to Cart"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
