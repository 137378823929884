/** @format */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./RestaurantStyle.scoped.css";
import faviconImage from "./icon.png";
import Carousel from "react-bootstrap/Carousel";
import CarouselImage1 from "./1.png";
import CarouselImage2 from "./2.png";

const RestaurantTemplate = () => {
  const dispatch = useDispatch();
  const { allStoresList, isLoading } = useSelector(
    (state) => state.storeReducer
  );
  useEffect(() => {
    dispatch({
      type: "GET_ALL_STORES_REQUEST",
    });
  }, []);
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = faviconImage;
  }, []);

  return (
    <>
      <header className="sticky-top">
        <nav className="navbar navbar-expand-md navbar-default  bg-light">
          <div className="container">
            <a className="navbar-brand">
              <img src="/Assets/assets/images/poslogo.png" alt="" />
            </a>
            <div className="navbar-toolbar d-flex align-items-center order-lg-3">
              {/* <a href="https://deliverypickup.online/login" target="_blank" class="btn btn-raised shadow my-button w-xs  me-2 homecart homelogin "><i class="fas fa-user me-2"></i>Login</a> */}
              {/* <a
                href="https://posapt.au/register/startnow?type=onlineorder"
                target="_blank"
                className="btn btn-raised shadow my-button w-xs  me-2 homecart homelogin"
              >
                <i className="fas fa-user me-2" />
                Sign Up Free{" "}
              </a> */}
              {/* <a href="" class="btn btn-raised bg-danger shadow my-button w-xs  me-2 homecart homelogin" data-bs-toggle="modal" data-bs-target="#freetrialModal">Free Trial</a> */}
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <span className="fas fa-bars" />
              </button>
            </div>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                {/* <li class="nav-item">
                      <a class="nav-link active" aria-current="page" href="">Products</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="">Business</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="">About</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="">Partners</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="">Pricing</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="">Help</a>
                  </li> */}
                {/* <li class="nav-item">
                      <a class="nav-link" href="features.html">Features</a>
                  </li> */}
                {/* <li class="nav-item">
                      <a class="nav-link" href="contact.html">Contact</a>
                  </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main>
        <Carousel
          className="restaurant-template"
          dots={false}
          variant="dark"
          style={{ position: "relative", zIndex: "2", minHeight: "40vh" }}
        >
          <Carousel.Item>
            <img
              style={{ height: "400px", objectFit: "cover" }}
              className="d-block w-100"
              src={CarouselImage1}
              alt="Second slide"
            />

            <Carousel.Caption style={{ color: "white", zIndex: 2323 }}>
              <h3 style={{ color: "white", zIndex: "23" }}>
                Sell More Sell Online !
              </h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{ height: "400px", objectFit: "cover" }}
              className="d-block w-100"
              src={CarouselImage1}
              alt="Second slide"
            />

            <Carousel.Caption style={{ color: "white", zIndex: 2323 }}>
              <h3 style={{ color: "white", zIndex: "23" }}>
                Sell More Sell Online !
              </h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={CarouselImage2}
              alt="Third slide"
            />

            <Carousel.Caption style={{ color: "white", zIndex: 2323 }}>
              <h3 style={{ color: "white", zIndex: "23" }}>
                Sell More Sell Online !
              </h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        {/* {allStoresList.length > 0 && (
          <section className="bg-light pt-50 ">
            <div className="container">
              <div className="row text-center">
                <div className="heading_s1">
                  <h2 className=" fw-bold text-capitalize">
                    Join our family of satisfied clientele today!
                  </h2>
                </div>
              </div>
              <div className="recentstores">
                <div className="row align-items-center justify-content-center">
                  {allStoresList.length > 0 &&
                    allStoresList?.map((item) => (
                      <div className="col-lg-3 col-6 col-sm-4">
                        <a href={item.url} target="_blank">
                          <div className="border-gray p-15 mb-4 bg-white text-center">
                            <img
                              src={item.image}
                              alt=""
                              data-pagespeed-url-hash={3465343162}
                              onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                            />
                            <div className="alt-font text-extra-dark-gray fw-bold d-block">
                              {item.name}
                              <span className="d-block text-muted">
                                {item.country}
                              </span>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </section>
        )} */}

        {/* new restro */}
        <section className="bg-light pt-50 pb-50">
          <div className="container">
            <div className="row text-center">
              <div className="heading_s1">
                <h2 className=" fw-bold text-capitalize text-red">
                  Australia's Best Online Food Ordering System{" "}
                </h2>
              </div>
            </div>
            <div className="recentstores">
              <div className="row">
                <div className="col-md-4 pb-3">
                  <div className="card mb-3 h-100">
                    <img
                      src="/Assets/assets/images/o1.png"
                      className="card-img-top mt-3"
                      alt="..."
                    />
                    <div className="card-body">
                      <h5 className="card-title fw-bold">Online Ordering</h5>
                      <p className="card-text">
                        Update your inventory and take orders from your website
                        or tablet POS. Automation is made easier with our online
                        ordering system. Notify your customers when their order
                        is ready for pickup or en route for delivery.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pb-3">
                  <div className="card mb-3 h-100">
                    <img
                      src="/Assets/assets/images/o2.png"
                      className="card-img-top mt-3"
                      alt="..."
                    />
                    <div className="card-body">
                      <h5 className="card-title fw-bold">Table Reservation</h5>
                      <p className="card-text">
                        With our online ordering system, you get an all-in-one
                        solution to maximise seating whether you run a small or
                        large operation. Calls during peak hours will no longer
                        be taken! Enjoy the automation of your table reservation
                        system.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pb-3">
                  <div className="card mb-3 h-100">
                    <img
                      src="/Assets/assets/images/o3.png"
                      className="card-img-top mt-3"
                      alt="..."
                    />
                    <div className="card-body">
                      <h5 className="card-title fw-bold">Table QR Ordering</h5>
                      <p className="card-text">
                        Pen and paper are a thing of the past! Our online
                        ordering system allows you to generate QR codes that
                        will enable customers to order directly from your online
                        menu. No more pandemic woes! Create a safe dining
                        experience for your guests and servers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pb-3">
                  <div className="card mb-3 h-100">
                    <img
                      src="/Assets/assets/images/o4.png"
                      className="card-img-top mt-3"
                      alt="..."
                    />
                    <div className="card-body">
                      <h5 className="card-title fw-bold">Gift Card System</h5>
                      <p className="card-text">
                        With the gift card feature, you can link customer
                        accounts and gain useful insights into their order
                        history and spending. Gift cards are a great way to win
                        over loyal customers. Additionally, when customers use
                        gift cards, businesses tend to see an increase in sales.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pb-3">
                  <div className="card mb-3 h-100">
                    <img
                      src="/Assets/assets/images/o5.png"
                      className="card-img-top mt-3"
                      alt="..."
                    />
                    <div className="card-body">
                      <h5 className="card-title fw-bold">Loyalty Program</h5>
                      <p className="card-text">
                        Businesses can gain valuable insight into their
                        customers' needs and habits through the loyalty program
                        feature. Understanding your customers' behaviour is
                        critical for increasing sales. Customers benefit from
                        loyalty programs as well. It's a win-win situation for
                        both you and your customer!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pb-3">
                  <div className="card mb-3 h-100">
                    <img
                      src="/Assets/assets/images/o6.png"
                      className="card-img-top mt-3"
                      alt="..."
                    />
                    <div className="card-body">
                      <h5 className="card-title fw-bold">Dedicated Support</h5>
                      <p className="card-text">
                        Run your business stress-free while we support you. A
                        team of dedicated professionals will handle all your
                        concerns, no matter how big or small. Together, we can
                        work through the setup of your business’s online
                        ordering system for long-term success, or make changes
                        to better suit your needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" pt-50 pb-50">
          <div className="container">
            <div className="row text-center">
              <div className="heading_s1">
                <span className="sub_heading font_style1">
                  Perfect for any business
                </span>
                <h2 className=" fw-bold text-capitalize">
                  Businesses We Support
                </h2>
              </div>
            </div>
            <div className="mytemplates">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-2 col-6 col-sm-4">
                  <a href="">
                    <div className="border-gray p-15  bg-light text-center">
                      <img src="/Assets/assets/images/restaurant.png" alt="" />
                      <div className="alt-font text-extra-dark-gray fw-bold d-block">
                        Restaurant Store
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-6 col-sm-4">
                  <a href="">
                    <div className="border-gray p-15  bg-light text-center">
                      <img src="/Assets/assets/images/bakery.png" alt="" />
                      <div className="alt-font text-extra-dark-gray fw-bold d-block">
                        Bakery Store
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-6 col-sm-4">
                  <a href="">
                    <div className="border-gray p-15  bg-light text-center">
                      <img src="/Assets/assets/images/flower.jpg" alt="" />
                      <div className="alt-font text-extra-dark-gray fw-bold d-block">
                        Flower Store
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-6 col-sm-4">
                  <a href="">
                    <div className="border-gray p-15  bg-light text-center">
                      <img src="/Assets/assets/images/grocery.png" alt="" />
                      <div className="alt-font text-extra-dark-gray fw-bold d-block">
                        Grocery Store
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-6 col-sm-4">
                  <a href="">
                    <div className="border-gray p-15  bg-light text-center">
                      <img src="/Assets/assets/images/beverages.png" alt="" />
                      <div className="alt-font text-extra-dark-gray fw-bold d-block">
                        Beverages Store
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* CTA button */}
        <section
          className="cta-section"
          style={{ backgroundImage: "url(/Assets/assets/images/bg1.jpg)" }}
        >
          <div className="auto-container">
            <h5>Not convinced yet?</h5>
            <h2>Give our Online Ordering System a try! </h2>
            {/* <div className="link-box"> */}
            {/* <a
                href="https://posapt.au/register/startnow?type=onlineorder"
                target="_blank"
                className="theme-btn btn-style-one"
              >
                <span>Start your 30-day free trial today!</span>
              </a> */}
            {/* </div> */}
          </div>
        </section>
        {/* Key Features */}
        <section className="pb-50 pt-50">
          <div className="keyfeatures text-center">
            <div className="container">
              <div className="row">
                <div className="heading_s1 w-80">
                  <span className="sub_heading font_style1">
                    Why Are We Better Than The Rest?
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="card border-0  rounded-xs mb-3">
                    <div className="card-body service_item">
                      <div className="ser_inner ">
                        <div className="ser_img">
                          <img
                            src="/Assets/assets/images/quick updates.png"
                            className="img-fluid h-100"
                            alt=""
                            data-pagespeed-url-hash={1416024010}
                            onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                          />
                        </div>
                        <h4 className="mt-4 mb-3">Quick Updates </h4>
                      </div>
                      <p>
                        You can effortlessly update your menu, pricing,
                        promotions, and operational hours from the backend.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card border-0  rounded-xs mb-3">
                    <div className="card-body service_item">
                      <div className="ser_inner ">
                        <div className="ser_img">
                          <img
                            src="/Assets/assets/images/tracking.png"
                            className="img-fluid h-100"
                            alt=""
                            data-pagespeed-url-hash={1416024010}
                            onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                          />
                        </div>
                        <h4 className="mt-4 mb-3">
                          Keep Track of your Customer’s Needs
                        </h4>
                      </div>
                      <p>
                        Keep track of customer behaviour to develop marketing
                        campaigns that are tailored to their needs and
                        preferences
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card border-0  rounded-xs mb-3">
                    <div className="card-body service_item">
                      <div className="ser_inner ">
                        <div className="ser_img">
                          <img
                            src="/Assets/assets/images/online order.png"
                            className="img-fluid h-100"
                            alt=""
                            data-pagespeed-url-hash={1416024010}
                            onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                          />
                        </div>
                        <h4 className="mt-4 mb-3">
                          Affordable Online Ordering System
                        </h4>
                      </div>
                      <p>
                        A subscription-based online ordering system with no
                        commission, no hidden fees and no extra charges!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card border-0  rounded-xs mb-3">
                    <div className="card-body service_item">
                      <div className="ser_inner ">
                        <div className="ser_img">
                          <img
                            src="/Assets/assets/images/hr.png"
                            className="img-fluid h-100"
                            alt=""
                            data-pagespeed-url-hash={1416024010}
                            onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                          />
                        </div>
                        <h4 className="mt-4 mb-3">
                          Inbuilt HR Management System
                        </h4>
                      </div>
                      <p>
                        Sophisticated HR system for small businesses, including
                        a built-in payroll system (at an additional cost)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card border-0  rounded-xs mb-3">
                    <div className="card-body service_item">
                      <div className="ser_inner ">
                        <div className="ser_img">
                          <img
                            src="/Assets/assets/images/custom website.png"
                            className="img-fluid h-100"
                            alt=""
                            data-pagespeed-url-hash={1416024010}
                            onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                          />
                        </div>
                        <h4 className="mt-4 mb-3">FREE Custom Website</h4>
                      </div>
                      <p>
                        A WordPress site that provides your customers a platform
                        to purchase your products and services for absolutely
                        ZERO cost!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card border-0  rounded-xs mb-3">
                    <div className="card-body service_item">
                      <div className="ser_inner ">
                        <div className="ser_img">
                          <img
                            src="/Assets/assets/images/integration.png"
                            className="img-fluid h-100"
                            alt=""
                            data-pagespeed-url-hash={1416024010}
                            onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
                          />
                        </div>
                        <h4 className="mt-4 mb-3">Direct Integration to POS</h4>
                      </div>
                      <p>
                        Receive all orders into a centralised POS system and
                        keep tracking and management simple.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer id="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                {/* <div class="col-lg-3 col-md-6 footer-links">
                      <h4>Useful Links</h4>
                      <ul>
                          <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
                          <li><i class="bx bx-chevron-right"></i> <a href="#">About us</a></li>

                          <li><i class="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
                          <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
                      </ul>
                  </div> */}
                {/* <div class="col-lg-3 col-md-6 footer-links">
                      <h4>Our Services</h4>
                      <ul>
                          <li><i class="bx bx-chevron-right"></i> <a href="#">Products</a></li>
                          <li><i class="bx bx-chevron-right"></i> <a href="#">Business</a></li>
                          <li><i class="bx bx-chevron-right"></i> <a href="#">Partners</a></li>
                          <li><i class="bx bx-chevron-right"></i> <a href="#">Pricing</a></li>
                          <li><i class="bx bx-chevron-right"></i> <a href="#">Help</a></li>
                      </ul>
                  </div> */}
                <div className="col-lg-4 col-md-6 footer-contact text-start">
                  <h4>Contact Us</h4>
                  <p className="text-start">
                    Building 4, Suite 30/195 Wellington Rd, Clayton, VIC 3168{" "}
                    <br /> <strong>Email: </strong>
                    <a className="text-warning" href="mailto:info@posapt.au">
                      info@posapt.au
                    </a>{" "}
                    <br /> <strong>Phone: </strong>{" "}
                    <a className="text-warning" href="tel:0433 594 555">
                      0433 594 555
                    </a>{" "}
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 footer-info">
                  <h3 style={{ color: "white" }}>Our Commitment To You</h3>
                  <p>
                    We believe a robust and reliable POS shouldn’t cost you half
                    your monthly revenue. We’re here to offer a tailormade POS
                    system that’s affordable and encourages your success.
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 footer-links text-center">
                  <h4>Follow Us</h4>
                  <div className="social-links mt-3">
                    {" "}
                    <a
                      href="https://twitter.com/posapt_au"
                      target={"_blank"}
                      className="twitter"
                    >
                      <i className="fab fa-twitter" />
                    </a>{" "}
                    <a
                      href="https://www.facebook.com/posapt/"
                      target={"_blank"}
                      className="facebook"
                    >
                      <i className="fab fa-facebook" />
                    </a>{" "}
                    <a
                      href="https://www.instagram.com/posapt.au/"
                      target={"_blank"}
                      className="instagram"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/posapt-au"
                      target={"_blank"}
                      className="linkedin"
                    >
                      <i className="fab fa-linkedin" />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCEbnQMG4QHXB5rk2TzROApA"
                      target={"_blank"}
                      className="linkedin"
                    >
                      <i className="fab fa-youtube" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="copyright">
              {" "}
              © Copyright{" "}
              <strong>
                <span>
                  <a
                    href="http://develop.deliverypickup.online/store/saffron-cottage"
                    className="text-warning"
                  >
                    POSAPT
                  </a>
                </span>
              </strong>
              . All Rights Reserved{" "}
            </div>
            {/* <div class="credits"> Designed by <a href="#">VolgAI</a> </div> */}
          </div>
        </footer>
      </main>
      {/* request free trial */}
    </>
  );
};

export default RestaurantTemplate;
