/** @format */

import { Typography } from "antd";

import { useDispatch, useSelector } from "react-redux";

import ProductComponentWithCommas from "./ProductComponentWithCommas";

const CartHalfHalfItem = ({}) => {
  const dispatch = useDispatch();

  const { halfHalfProducts } = useSelector((state) => state.StateManager);
  const currencySymbol = useSelector(
    (state) => state.storeReducer.storeData.currencySymbol
  );

  const groupProductsByHalfGroupKey = (products) => {
    const groupedProducts = {};

    products
      ?.filter((item) => item.HalfGroupKey || item.halfGroupKey)
      ?.forEach((product) => {
        const halfGroupKey = product.HalfGroupKey || product.halfGroupKey;

        if (!groupedProducts[halfGroupKey]) {
          groupedProducts[halfGroupKey] = [];
        }

        groupedProducts[halfGroupKey].push(product);
      });

    return Object.values(groupedProducts);
  };

  const groupedProducts = groupProductsByHalfGroupKey(halfHalfProducts);

  return (
    <div vertical className="m-2">
      {groupedProducts?.map((groupedProduct) => {
        return (
          <div
            vertical
            className="mb-3 me-2 p-2 rounded"
            style={{ background: "#e9f3f3" }}
          >
            <div className="d-flex justify-content-between">
              <div>
                {" "}
                <Typography.Text strong>Half/Half Item - </Typography.Text>
                <Typography.Text strong>
                  {currencySymbol} {groupedProduct[0]?.HalfGroupAmount}{" "}
                </Typography.Text>
              </div>
              <div className="mt-2">
                {/* <Input placeholder="Description" /> */}
                <div
                  class="delete-btn"
                  onClick={() => {
                    dispatch({
                      type: "REMOVE_HALF_HALF_ITEMS_BY_GROUP_KEY",
                      payload: groupedProduct[0]?.HalfGroupKey,
                    });
                  }}
                >
                  <a class="text-dark-white">
                    {" "}
                    <i class="far fa-trash-alt" />
                  </a>
                </div>
              </div>
            </div>
            {groupedProduct?.map((product) => {
              return (
                <div className="p-0">
                  <div className="cartdetails ">
                    <div className="">
                      <div className="">
                        <div className="card-details">
                          <div className="row align-items-center">
                            <div className="col-md-3">
                              <img
                                style={{
                                  width: "50%",
                                  borderRadius: "100%",
                                }}
                                src={product.productImage}
                                alt=""
                              />
                            </div>
                            <div className="col-md-7">
                              <h6
                                className="mb-0 light-color-text"
                                style={{
                                  fontSize: "13px",
                                  cursor: "pointer",
                                }}
                              >
                                {product.productName}
                                {product.productVariations?.[0]?.name &&
                                  "(" +
                                    product.productVariations?.[0]?.name +
                                    ")"}
                              </h6>
                            </div>
                          </div>

                          <ProductComponentWithCommas product={product} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default CartHalfHalfItem;
