/** @format */

import { ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { API } from "../Helpers/BaseUrlProvider";

export const getTempalateEpic = (action$) =>
  action$.pipe(
    ofType("GET_TEMPLATE_REQUEST"),
    mergeMap((action) =>
      from(API.post("/api/onlineorder/getTemplate", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "GET_TEMPLATE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_TEMPLATE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getTemplatesWithDetailsEpic = (action$) =>
  action$.pipe(
    ofType("GET_TEMPLATE_WITH_DETAILS_REQUEST"),
    mergeMap((action) =>
      from(
        API.post("/api/onlineorder/getTemplateWithDetails", action.payload)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_TEMPLATE_WITH_DETAILS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_TEMPLATE_WITH_DETAILS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getProductByCategoryEpic = (action$) =>
  action$.pipe(
    ofType("GET_PRODUCT_BY_CATEGORIES_REQUEST"),
    mergeMap((action) =>
      from(
        API.post("api/onlineorder/getallProductsByCategory", action.payload)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_PRODUCT_BY_CATEGORIES_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_PRODUCT_BY_CATEGORIES_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const getTemplatesWithDetails1Epic = (action$) =>
  action$.pipe(
    ofType("GET_TEMPLATE_WITH_DETAILS_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(`/api/onlineorder/getTemplateWithDetails`, action.payload)
      ).pipe(
        mergeMap((response) => {
          let templateResponse = response.data;

          return from(
            API.post(`/api/onlineorder/getallProductsByCategory`, {
              CategoryId: response.data?.prodcutCategories?.[0]?.id,
            })
          ).pipe(
            map((response) => ({
              type: "GET_PRODUCT_BY_CATEGORIES_SUCCESS",
              payload: {
                categoryData: response.data,
                templateData: templateResponse,
              },
            })),
            catchError((error) =>
              of({
                type: "GET_PRODUCT_BY_CATEGORIES_FAILURE",
                payload: error.response.data.message[0].message,
              })
            )
          );
        }),
        catchError((error) =>
          of({
            type: "GET_TEMPLATE_WITH_DETAILS_FAILURE",
            payload: error.response.data.message[0].message,
          })
        )
      )
    )
  );

export const authenticateUserEpic = (action$) =>
  action$.pipe(
    ofType("AUTHENTICATE_USER_REQUEST"),
    mergeMap((action) =>
      from(API.post("/api/account/login", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "AUTHENTICATE_USER_SUCCESS",
            payload: response.data,
            storeId: action.payload.storeId,
            storeState: action.payload.storeState,
          })
        ),
        catchError((error) =>
          of({
            type: "AUTHENTICATE_USER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const addDeliveryAddress = (action$) =>
  action$.pipe(
    ofType("DELIVERY_LOCATION_ADD_REQUEST"),
    mergeMap((action) => {
      let headers = {
        storeId: action.payload.storeId,
      };
      if (action.payload.userId) {
        headers = {
          storeId: action.payload.storeId,
          userId: action.payload.userId,
        };
      }
      return from(
        API.post(
          "/api/onlineorder/addDeliveryAddressAndGetCostByDeliveryDistance",
          action.payload,
          {
            headers,
          }
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "DELIVERY_LOCATION_ADD_SUCCESS",
            payload: {
              ...response.data,
              ...action.payload,
            },

            getPrice: action.getPrice,
            isGuest: action.isGuest,
          })
        ),
        catchError((error) =>
          of({
            type: "DELIVERY_LOCATION_ADD_FAILURE",
            payload: error.response,
          })
        )
      );
    })
  );

export const addDeliveryAddressGuest = (action$) =>
  action$.pipe(
    ofType("DELIVERY_LOCATION_ADD_REQUEST_GUEST"),
    mergeMap((action) => {
      let headers = {
        storeId: action.payload.storeId,
      };
      if (action.payload.userId) {
        headers = {
          storeId: action.payload.storeId,
          userId: action.payload.userId,
        };
      }
      return from(
        API.post(
          "/api/onlineorder/addDeliveryAddressAndGetCostByDeliveryDistanceGuest",
          action.payload,
          {
            headers,
          }
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "DELIVERY_LOCATION_ADD_SUCCESS_GUEST",
            payload: {
              ...response.data,
              ...action.payload,
            },

            getPrice: action.getPrice,
            isGuest: action.isGuest,
          })
        ),
        catchError((error) =>
          of({
            type: "DELIVERY_LOCATION_ADD_FAILURE",
            payload: error.response,
          })
        )
      );
    })
  );

export const getCheckOutSectionList = (action$) =>
  action$.pipe(
    ofType("CHECKOUT_SECTION_LIST_REQUEST", "CHECKOUT_SECTION_LIST_REQUEST1"),
    mergeMap((action) =>
      from(
        API.post(
          "/api/onlineorder/getCheckOutSectionList",
          {},
          {
            headers: {
              storeId: action.payload.storeId,
              userId: action.payload.userId,
            },
          }
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "CHECKOUT_SECTION_LIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "CHECKOUT_SECTION_LIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getGuestCheckOutSectionList = (action$) =>
  action$.pipe(
    ofType("GUEST_CHECKOUT_SECTION_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.post("/api/onlineorder/getGuestCheckOutSectionList", {}, {})
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GUEST_CHECKOUT_SECTION_LIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GUEST_CHECKOUT_SECTION_LIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const deleteDeliveryAddress = (action$) =>
  action$.pipe(
    ofType("DELIVERY_LOCATION_DELETE_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(`/api/onlineorder/deleteUserDeliveryAddress`, {
          id: action.payload.id,
        })
      ).pipe(
        mergeMap((response) =>
          of({
            type: "DELIVERY_LOCATION_DELETE_SUCCESS",
            payload: action.payload.id,
          })
        ),
        catchError((error) =>
          of({
            type: "DELIVERY_LOCATION_DELETE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const registerUserEpic = (action$) =>
  action$.pipe(
    ofType("REGISTER_USER_REQUEST", "LOGIN_WITH_GOOGLE_REQUEST"),
    mergeMap((action) =>
      from(
        API.post("/api/account/registercustomer", action.payload, {
          headers: {
            storeId: action.payload.storeId,
          },
        })
      ).pipe(
        mergeMap((response) =>
          of({
            type: "REGISTER_USER_SUCCESS",
            payload: response.data,
            storeId: action.payload.storeId,
            storeState: action.payload.storeState,
            isGoogleLogin: action.payload.IsGoogleLogin ? true : false,
          })
        ),
        catchError((error) =>
          of({
            type: "REGISTER_USER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const getAllOnlineOrderSectionListEpic = (action$) =>
  action$.pipe(
    ofType("GET_ALL_ONLINE_ORDER_SECTION_LIST_REQUEST"),
    mergeMap((action) =>
      from(API.post("/api/onlineorder/placeOrderOrCheckOut")).pipe(
        mergeMap((response) =>
          of({
            type: "GET_ALL_ONLINE_ORDER_SECTION_LIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_ALL_ONLINE_ORDER_SECTION_LIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// getCheckOutSectionList
// getGuestCheckOutSectionList

// Place ORder
export const placeOrderEpic = (action$) =>
  action$.pipe(
    ofType("PLACE_ORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(`/api/onlineorder/placeOrderOrCheckOut`, action.payload, {})
      ).pipe(
        mergeMap((response) =>
          of({
            type: "PLACE_ORDER_SUCCESS",
            payload: {
              data: response.data,
              paymentMethodId: action.payload.PaymentMethodId,
            },
          })
        ),
        catchError((error) =>
          of({
            type: "PLACE_ORDER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const placeOrderEpicGuest = (action$) =>
  action$.pipe(
    ofType("PLACE_ORDER_REQUEST_GUEST"),
    mergeMap((action) =>
      from(
        API.post(
          `/api/onlineorder/placeOrderOrCheckOutGuest`,
          action.payload,
          {}
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "PLACE_ORDER_SUCCESS",
            payload: {
              data: response.data,
              paymentMethodId: action.payload.PaymentMethodId,
            },
          })
        ),
        catchError((error) =>
          of({
            type: "PLACE_ORDER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const paymentEpic = (action$) =>
  action$.pipe(
    ofType("MAKE_PAYMENT_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(`/api/onlineorder/placeOrderMakePayments`, action.payload, {})
      ).pipe(
        mergeMap((response) =>
          of({
            type: "MAKE_PAYMENT_SUCCESS",
            payload: action.payload.id,
          })
        ),
        catchError((error) =>
          of({
            type: "MAKE_PAYMENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const paymentEpicGuest = (action$) =>
  action$.pipe(
    ofType("MAKE_PAYMENT_REQUEST_GUEST"),
    mergeMap((action) =>
      from(
        API.post(
          `/api/onlineorder/placeOrderMakePaymentsGuest`,
          action.payload,
          {}
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "MAKE_PAYMENT_SUCCESS",
            payload: action.payload.id,
          })
        ),
        catchError((error) =>
          of({
            type: "MAKE_PAYMENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const getReservationSectionList = (action$) =>
  action$.pipe(
    ofType("GET_RESERVATION_SECTION_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          "/api/onlineorder/getAllTableReservationAddSectionList",
          {},
          {}
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_RESERVATION_SECTION_LIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_RESERVATION_SECTION_LIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const reserveTableEpic = (action$) =>
  action$.pipe(
    ofType("RESERVE_TABLE_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(`/api/onlineorder/reserveTable`, action.payload, {
          headers: {
            storeId: action.payload.storeId,
            userId: action.payload.userId,
          },
        })
      ).pipe(
        mergeMap((response) =>
          of({
            type: "RESERVE_TABLE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "RESERVE_TABLE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// export const getCountryListEpic = (action$) =>
//   action$.pipe(
//     ofType("GET_COUNTRY_LIST_REQUEST"),
//     mergeMap((action) =>
//       from(API.get("/api/common/listCountry")).pipe(
//         mergeMap((response) =>
//           of({
//             type: "GET_COUNTRY_LIST_SUCCESS",
//             payload: response.data,
//           })
//         ),
//         catchError((error) =>
//           of({
//             type: "GET_COUNTRY_LIST_FAILURE",
//             payload: error.response,
//           })
//         )
//       )
//     )
//   );
export const getUserDetailsEpic = (action$) =>
  action$.pipe(
    ofType("GET_USER_DETAIL_REQUEST"),
    mergeMap((action) =>
      from(
        API.post("/api/account/getUserDetails", action.payload, {
          headers: {},
        })
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_USER_DETAIL_SUCCESS",
            payload: {
              data: response.data,
              storeId: action.payload.storeId,
              storeState: action.payload.storeState,
            },
          })
        ),
        catchError((error) =>
          of({
            type: "GET_USER_DETAIL_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const updateUserDetailsEpic = (action$) =>
  action$.pipe(
    ofType("UDPATE_USER_DETAIL_REQUEST"),
    mergeMap((action) =>
      from(API.post("/api/account/updateUser", action.payload.data, {})).pipe(
        mergeMap((response) =>
          of({
            type: "UPDATE_USER_DETAIL_SUCCESS",
            payload: {
              data: response.data,
              lsUpdateData: action.payload.lsUpdateData,
            },
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_USER_DETAIL_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getAllUserOrdersEpic = (action$) =>
  action$.pipe(
    ofType("GET_ORDER_HISTORY_REQUEST"),
    mergeMap((action) =>
      from(
        API.post("/api/onlineorder/getAllUserOrders", action.payload.data, {})
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_ORDER_HISTORY_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_ORDER_HISTORY_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getOrderDetailEpic = (action$) =>
  action$.pipe(
    ofType("GET_ORDER_DETAIL_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          "/api/onlineorder/getOrderDetailsByOrderId",
          action.payload.data
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_ORDER_DETAIL_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_ORDER_DETAIL_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getOrderDetailGuestEpic = (action$) =>
  action$.pipe(
    ofType("GET_GUEST_ORDER_DETAIL_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          "/api/onlineorder/getOrderDetailsByOrderIdGuest",
          action.payload.data,
          {}
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "GET_ORDER_DETAIL_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_ORDER_DETAIL_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getAllStoresEpic = (action$) =>
  action$.pipe(
    ofType("GET_ALL_STORES_REQUEST"),
    mergeMap((action) =>
      from(API.post("/api/onlineorder/getAllStores")).pipe(
        mergeMap((response) =>
          of({
            type: "GET_ALL_STORES_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_ALL_STORES_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const forgotPasswordEpic = (action$) =>
  action$.pipe(
    ofType("FORGOT_PASSWORD_REQUEST"),
    mergeMap((action) =>
      from(
        API.post("/api/account/sendForgotPasswordChangeLink", {
          ...action.payload,
          ChannelPlatForm: "OnlineOrderWeb",
        })
      ).pipe(
        mergeMap((response) =>
          of({
            type: "FORGOT_PASSWORD_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FORGOT_PASSWORD_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const changePasswordEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_PASSWORD_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          "/api/account/changePassword",
          {
            ...action.payload.data,
            ChannelPlatForm: "OnlineOrderWeb",
          },
          {
            headers: {
              StoreId: action.payload.storeId,
              UserId: action.payload.userId,
            },
          }
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "CHANGE_PASSWORD_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "CHANGE_PASSWORD_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const checkVoucherEpic = (action$) =>
  action$.pipe(
    ofType("CHECK_VOUCHER_REQUEST"),
    mergeMap((action) =>
      from(API.post("api/OnlineOrder/checkVoucherCode", action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "CHECK_VOUCHER_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "CHECK_VOUCHER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const resetPasswordEpic = (action$) =>
  action$.pipe(
    ofType("RESET_PASSWORD_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          "/api/account/resetPassword",
          {
            ...action.payload,
            ChannelPlatForm: "OnlineOrderWeb",
          },
          {
            headers: {
              storeId: action.payload.storeId,
            },
          }
        )
      ).pipe(
        mergeMap((response) => {
          return of({
            type: "RESET_PASSWORD_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((error) =>
          of({
            type: "RESET_PASSWORD_FAILURE",
            payload: error.response.data.message[0].message,
          })
        )
      )
    )
  );
export const sendOTPtoMail = (action$) =>
  action$.pipe(
    ofType("SEND_OTP_REQUEST", "SEND_OTP_REQUEST_RESEND"),
    mergeMap((action) =>
      from(
        API.post("api/account/sendOtpCodeToEmailOnBoarding", {
          ChannelPlatForm: "OnlineOrderWeb",
          ...action.payload,
        })
      ).pipe(
        mergeMap((response) => {
          if (action.payload?.isResend) {
            return of({
              type: "SEND_OTP_SUCCESS_RESEND",
              payload: response.data,
            });
          }
          return of({
            type: "SEND_OTP_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((error) =>
          of({
            type: "SEND_OTP_FAILURE",
            payload: error.response.data.message[0].message,
          })
        )
      )
    )
  );

export const sendOTPForPhoneNumberValidation = (action$) =>
  action$.pipe(
    ofType(
      "SEND_OTP_PHONE_NUMBER_VALIDATION_REQUEST",
      "SEND_OTP_PHONE_NUMBER_VALIDATION_REQUEST_RESEND"
    ),
    mergeMap((action) =>
      from(
        API.post("api/account/sendOtpCodeToPhoneNumber", action.payload)
      ).pipe(
        mergeMap((response) => {
          if (action.payload?.isResend) {
            return of({
              type: "SEND_OTP_PHONE_NUMBER_VALIDATION_SUCCESS_RESEND",
              payload: response.data,
            });
          }

          return of({
            type: "SEND_OTP_PHONE_NUMBER_VALIDATION_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((error) =>
          of({
            type: "SEND_OTP_PHONE_NUMBER_VALIDATION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const validatePhoneNumber = (action$) =>
  action$.pipe(
    ofType("VALIDATE_PHONE_NUMBER_REQUEST"),
    mergeMap((action) =>
      from(
        API.post("api/account/validateOtpCodeToPhoneNumber", action.payload)
      ).pipe(
        mergeMap((response) =>
          of({
            type: "VALIDATE_PHONE_NUMBER_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "VALIDATE_PHONE_NUMBER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
