/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import LoadingScreen from "../../../Components/LoadingScreen/LoadingScreen";
import CheckoutBakery from "../BakeryTemplate/Pages/Checkout";
import CheckoutRestaurant from "../Restaurant/Checkout";

import axios from "axios";
import {
  getCookie,
  getLocalStorage,
  setLocalStorage,
} from "../../../Helpers/FrontendHelper";

const DynamicCheckout = () => {
  const storeState = useSelector(
    (state) => state.storeReducer.storeData.templateName
  );
  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);

  const Token = getCookie(`token-${storeId}`);
  const themeColors = useSelector(
    (state) => state.storeReducer.homePageData?.themeColors
  );
  const paramValue = useParams();
  const dispatch = useDispatch();
  const homePageData = useSelector((state) => state.storeReducer.homePageData);
  const [isCssLoaded, setIsCssLoaded] = useState(false);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  useEffect(() => {
    window.scrollTo(0, 0);

    if (paramValue?.storeName && paramValue?.type) {
      dispatch({
        type: "GET_TEMPLATE_REQUEST",
        payload: {
          Url: "",
          Slug: paramValue?.storeName,
          Type: paramValue?.type,
        },
      });
    } else {
      dispatch({
        type: "GET_TEMPLATE_REQUEST",
        payload: {
          Url: window.location.origin,
          Slug: "",
          Type: "",
        },
      });
    }
  }, []);
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = homePageData?.storeDetails?.image
      ? homePageData?.storeDetails?.image
      : storeState == "restaurant"
      ? "/assets1/dist/image/restaurant.png"
      : storeState == "bakery"
      ? "/assets1/dist/image/bakery.png"
      : storeState == "wine"
      ? "/assets1/dist/image/beverages.png"
      : storeState == "grocery"
      ? "/assets1/dist/image/grocery.png"
      : storeState == "flower"
      ? "/assets1/dist/image/flower.png"
      : storeState == "clothing"
      ? "https://develop.deliverypickup.online/assets1/dist/image/bakery.png"
      : "";
    document.title = homePageData?.storeDetails?.storeName
      ? homePageData?.storeDetails?.storeName
      : "Online Order";
  }, [homePageData, storeState]);
  useEffect(() => {
    if (themeColors?.["theme-color"]) {
      document.documentElement.style.setProperty(
        "--theme-color",
        themeColors?.["theme-color"]
      );
    }
    if (themeColors?.["secondary-color"]) {
      document.documentElement.style.setProperty(
        "--secondary-color",
        themeColors?.["secondary-color"]
      );
    }
    if (themeColors?.["button-color"]) {
      document.documentElement.style.setProperty(
        "--button-color",
        themeColors?.["button-color"]
      );
    }
    if (themeColors?.["icon-color"]) {
      document.documentElement.style.setProperty(
        "--icon-color",
        themeColors?.["icon-color"]
      );
    }
    if (themeColors?.["alert-color-success"]) {
      document.documentElement.style.setProperty(
        "--alert-color-successr",
        themeColors?.["alert-color-success"]
      );
    }
    if (themeColors?.["alert-color-info"]) {
      document.documentElement.style.setProperty(
        "--alert-color-info",
        themeColors?.["alert-color-info"]
      );
    }
    if (themeColors?.["body-bg-color"]) {
      document.documentElement.style.setProperty(
        "--body-bg-color",
        themeColors?.["body-bg-color"]
      );
    }
    if (themeColors?.["alert-color-info"]) {
      document.documentElement.style.setProperty(
        "--cart-number-color",
        themeColors?.["cart-number-color"]
      );
    }
  }, [themeColors]);

  useEffect(() => {
    if (storeId)
      axios({
        method: "POST",
        url: `${
          process.env.NODE_ENV == "production"
            ? process.env.REACT_APP_APP_LIVE_URL
            : process.env.REACT_APP_APP_TEST_URL
        }/api/onlineorder/getAllOnlineOrderStoreSectionList`,
        data: {
          tableId: getLocalStorage(`${storeId}-${storeState}`).tableId
            ? getLocalStorage(`${storeId}-${storeState}`).tableId
            : "",
        },
        headers: {
          Authorization: `Bearer ${Token}`,
          storeId,
        },
      })
        .then((response) => {
          setLocalStorage(`homePageData-${storeId}`, response.data);
          dispatch({
            type: "GET_ALL_ONLINE_ORDER_SECTION_LIST_SUCCESS",
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: "GET_ALL_ONLINE_ORDER_SECTION_LIST_FAILURE",
            payload: error.response,
          });
        });
  }, [storeId]);
  if (storeId && homePageData?.storeDetails?.address) {
    switch (storeState) {
      case "restaurant":
        return <CheckoutRestaurant />;
      case "bakery":
        return <CheckoutBakery />;
      case "wine":
        return <CheckoutBakery />;
      case "flower":
        return <CheckoutBakery />;
      case "clothing":
        return <CheckoutBakery />;
      case "grocery":
        return <CheckoutBakery />;
    }
  } else {
    return <LoadingScreen />;
  }
};

export default DynamicCheckout;
