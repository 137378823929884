import { Radio, Typography } from "antd";
import React from "react";
import LazyImage from "../../Pages/Stores/Restaurant/LazyImage";

const ProductSpiceChoices = ({
  checkedSpiceChoice,
  setCheckedSpiceChoice,
  spiceChoices,
}) => {
  return (
    <div className="row">
      <p className="d-block fw-bold mb-2 light-color-text">Spice Choices</p>
      <div className="d-block fw-bold fs-6 ">
        <Radio.Group
          options={spiceChoices?.map((item) => ({
            label: (
              <div className="spice-choices">
                {/* <LazyImage
                  src={item.imageUrl}
                  alt={item.name}
                  className="me-2"
                /> */}
                <Typography.Text>{item.name}</Typography.Text>
              </div>
            ),
            value: JSON.stringify({
              spiceChoiceId: item.id,
              name: item.name,
            }),
          }))}
          value={checkedSpiceChoice} // Use [0] to get the single selected value
          onChange={(e) => setCheckedSpiceChoice(e.target.value)}
          className="spice-choices-container"
        />
      </div>
    </div>
  );
};

export default ProductSpiceChoices;
