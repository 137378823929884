/** @format */

import TextArea from "antd/lib/input/TextArea";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "../../Helpers/FrontendHelper";
import useOnlineHelper from "../../Helpers/useOnlineHelper";
import { openNotificationWithIcon } from "../Notification/Success";
import CartHalfHalfItem from "./CartHalfHalfItem";
import ProductComponentWithCommas from "./ProductComponentWithCommas";

function MyOrder({
  children,
  deliveryMethod,
  deliveryPrice,
  displayNone,
  activeDeliveryMethod,
  selectedDeliveryLocation,
}) {
  const {
    isPromotionAvailable,
    promotionDiscountPercentage,
    promotionDiscountThreshold,
  } = useOnlineHelper();
  const cartContainerRef = useRef(null);
  const { search } = useLocation();
  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const templateName = useSelector(
    (state) => state.storeReducer.storeData.templateName
  );
  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);
  const [cartData, setCartData] = useState(
    getLocalStorage(`${storeId}-${templateName}`)
  );
  const homePageData = getLocalStorage(`homePageData-${storeId}`);
  const paramValue = useParams();
  const [promoDiscountAmount, setPromoDiscountAmount] = useState();
  const [offerDiscount, setOfferDiscount] = useState();
  const [offerDiscountTax, setOfferDiscountTax] = useState();
  const [discountTax, setDiscountTax] = useState();
  const [deliveryTax, setDeliveryTax] = useState();
  const [newSum, setNewSum] = useState(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState();
  const [finalTotalAmount, setFinalTotalAmount] = useState();
  const [orderDetailCopy, setOrderDetailCopy] = useState();
  const [
    taxAmountWithoutDeliveryAndDiscountTax,
    setTaxAmountWithoutDeliveryAndDiscoutTax,
  ] = useState();
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const dispatch = useDispatch();
  const currencyCode = useSelector(
    (state) => state.storeReducer.storeData.currencyCode
  );
  const currencySymbol = useSelector(
    (state) => state.storeReducer.storeData.currencySymbol
  );
  const storesCartData = useSelector(
    (state) => state.StateManager.storesCartData
  );
  const halfHalfProducts = useSelector(
    (state) => state.StateManager.halfHalfProducts
  );
  const { voucherDiscount } = useSelector((state) => state.checkout);

  useEffect(() => {
    if (storesCartData) {
      setCartData(storesCartData);
    }
  }, [storesCartData]);

  function returnHalfHalfSubtotalAndTaxWithModifiers() {
    const groupedProducts = halfHalfProducts
      .filter((item) => !item.isCancelled)
      ?.reduce((acc, product) => {
        (acc[product.HalfGroupKey] = acc[product.HalfGroupKey] || []).push(
          product
        );
        return acc;
      }, {});

    let totalAmount = 0;

    Object.values(groupedProducts).forEach((group) => {
      let groupTotal = 0;
      let halfGroupAmountAdded = false;

      group.forEach((product) => {
        if (!halfGroupAmountAdded) {
          groupTotal += product.HalfGroupAmount;
          halfGroupAmountAdded = true;
        }

        product?.productVariations?.[0]?.OrderItemsPriceModifierViewModels?.forEach(
          (modifier) => {
            groupTotal += parseFloat(
              modifier.TotalModifierPrice
                ? modifier.TotalModifierPrice
                : modifier.totalModifierPrice
            );
          }
        );
      });

      totalAmount += groupTotal;
    });

    return totalAmount;
  }

  useEffect(() => {
    if (cartContainerRef.current) {
      cartContainerRef.current.scrollTop =
        cartContainerRef.current.scrollHeight;
    }
  }, [cartData?.orderDetails?.length]);
  useEffect(() => {
    if (!cartData) return;

    const taxPercentageValue = 0.1;
    const halfHalfItemsTotal = returnHalfHalfSubtotalAndTaxWithModifiers();
    const halfHalfItemsTax =
      (taxPercentageValue / (1 + taxPercentageValue)) * halfHalfItemsTotal;

    let sum = halfHalfItemsTotal;
    let taxAmount = halfHalfItemsTax;
    const staticTaxPercent = 10;
    let deliveryTax = 0;
    let promoDiscount = 0;
    let offerDiscount = 0;
    let taxDiscount = 0;
    let taxOfferDiscount = 0;

    cartData.orderDetails?.forEach((item) => {
      const variation = item.productVariations[0];
      const price = variation.price * variation.quantity;
      const modifiersTotal =
        variation.OrderItemsPriceModifierViewModels?.reduce(
          (acc, mod) => acc + mod.ModifierPrice * variation.quantity,
          0
        ) || 0;

      sum += price + modifiersTotal;
    });

    const calculateDeliveryTax = () => {
      if (deliveryPrice && deliveryMethod === 2 && selectedDeliveryLocation) {
        return (
          Number(deliveryPrice) * (staticTaxPercent / (100 + staticTaxPercent))
        );
      }
      return 0;
    };

    const calculatePromoDiscount = () => {
      return (
        (Number(voucherDiscount) *
          (Number(sum) +
            Number(selectedDeliveryLocation ? deliveryPrice : 0))) /
        100
      );
    };

    const calculateOfferDiscount = (subTotalAmount) => {
      if (
        isPromotionAvailable &&
        Number(subTotalAmount) > Number(promotionDiscountThreshold)
      ) {
        return (
          (Number(promotionDiscountPercentage) * Number(subTotalAmount)) / 100
        );
      } else {
        return 0;
      }
    };

    const calculateOfferDiscountTax = (subTotalAmount) => {
      const offerDiscountValue = calculateOfferDiscount(subTotalAmount);
      return offerDiscountValue
        ? ((Number(taxAmount) +
            Number(selectedDeliveryLocation ? deliveryTax : 0)) /
            (Number(sum) +
              Number(selectedDeliveryLocation ? deliveryPrice : 0))) *
            offerDiscountValue
        : 0;
    };

    const calculateTaxDiscount = () => {
      const promoDiscountValue = calculatePromoDiscount();
      return promoDiscountValue
        ? ((Number(taxAmount) +
            Number(selectedDeliveryLocation ? deliveryTax : 0)) /
            (Number(sum) +
              Number(selectedDeliveryLocation ? deliveryPrice : 0))) *
            promoDiscountValue
        : 0;
    };

    if (homePageData?.storeTaxSettings?.taxExclusiveInclusiveType === "3") {
      cartData.orderDetails.forEach((item) => {
        const variation = item.productVariations[0];
        const price = variation.price * variation.quantity;
        const modifiersTotal =
          variation.OrderItemsPriceModifierViewModels?.reduce(
            (acc, mod) => acc + mod.ModifierPrice * variation.quantity,
            0
          ) || 0;

        const itemTotal = price + modifiersTotal;
        taxAmount +=
          (itemTotal * Number(item.taxExclusiveInclusiveValue)) / 100;
      });
    } else if (
      homePageData?.storeTaxSettings?.taxExclusiveInclusiveType === "2"
    ) {
      cartData.orderDetails?.forEach((item) => {
        const variation = item.productVariations[0];
        const price = variation.price * variation.quantity;
        const modifiersTotal =
          variation.OrderItemsPriceModifierViewModels?.reduce(
            (acc, mod) => acc + mod.ModifierPrice * variation.quantity,
            0
          ) || 0;

        const itemTotal = price + modifiersTotal;
        taxAmount +=
          (itemTotal * Number(item.taxExclusiveInclusiveValue)) /
          (100 + Number(item.taxExclusiveInclusiveValue));
      });
    } else {
      deliveryTax = 0;
      taxAmount = 0;
    }

    deliveryTax = calculateDeliveryTax();
    promoDiscount = calculatePromoDiscount();
    offerDiscount = calculateOfferDiscount(sum);
    taxOfferDiscount = calculateOfferDiscountTax(sum);
    taxDiscount = calculateTaxDiscount();

    setOfferDiscount(offerDiscount);
    setOfferDiscountTax(taxOfferDiscount);
    setPromoDiscountAmount(promoDiscount);
    setDeliveryTax(deliveryTax);
    setDiscountTax(taxDiscount);
    setNewSum(sum);
    setTaxAmountWithoutDeliveryAndDiscoutTax(taxAmount);
    setTotalTaxAmount(
      taxAmount + deliveryTax - (taxDiscount || 0) - (offerDiscountTax || 0)
    );

    // Calculate final total amount
    const finalAmount =
      promoDiscount || offerDiscount
        ? deliveryPrice &&
          deliveryMethod === 2 &&
          selectedDeliveryLocation &&
          storesCartData?.orderDetails?.length > 0
          ? homePageData?.storeTaxSettings?.taxExclusiveInclusiveType === "2"
            ? (
                sum -
                (promoDiscount + offerDiscount) +
                parseInt(deliveryPrice)
              ).toFixed(2)
            : (
                sum -
                (promoDiscount + offerDiscount) +
                taxAmount +
                parseInt(deliveryPrice)
              ).toFixed(2)
          : homePageData?.storeTaxSettings?.taxExclusiveInclusiveType === "2"
          ? (sum - (promoDiscount + offerDiscount)).toFixed(2)
          : (sum - (promoDiscount + offerDiscount) + taxAmount).toFixed(2)
        : deliveryPrice &&
          deliveryMethod === 2 &&
          selectedDeliveryLocation &&
          storesCartData?.orderDetails?.length > 0
        ? homePageData?.storeTaxSettings?.taxExclusiveInclusiveType === "2"
          ? (sum + parseInt(deliveryPrice)).toFixed(2)
          : (sum + taxAmount + parseInt(deliveryPrice)).toFixed(2)
        : homePageData?.storeTaxSettings?.taxExclusiveInclusiveType === "2"
        ? sum.toFixed(2)
        : (sum + taxAmount).toFixed(2);

    setFinalTotalAmount(finalAmount);

    if (cartData.orderDetails) {
      setLocalStorage(`${storeId}-${templateName}`, cartData);
    }
    // dispatch({
    //   type: "CHANGE_LOCAL_STORAGE_DATA",
    //   payload: {
    //     newData: cartData,
    //   },
    // });
  }, [
    cartData,
    voucherDiscount,
    selectedDeliveryLocation,
    deliveryPrice,
    deliveryMethod,
    halfHalfProducts,
    homePageData,
    storesCartData,
    storeId,
    templateName,
  ]);

  useEffect(() => {
    if (cartData?.orderDetails) {
      setOrderDetailCopy(
        getLocalStorage(`${storeId}-${templateName}`)?.orderDetails?.map(
          (item) => {
            return {
              description: item.description,
              id: item.id,
            };
          }
        )
      );
    }
  }, [cartData?.orderDetails?.length]);

  useEffect(() => {
    if (
      voucherDiscount ||
      cartData?.orderDetails?.length > 0 ||
      halfHalfProducts?.length > 0
    ) {
      dispatch({
        type: "STORE_CHECKOUT_AMOUNTS",
        payload: {
          voucherDiscount:
            Number(promoDiscountAmount ?? 0) + Number(offerDiscount),
          discountTax: Number(discountTax ?? 0) + Number(offerDiscountTax),
          deliveryTax: deliveryTax,
          totalAmount: finalTotalAmount,
          subTotalAmount: newSum,
          deliveryAmount: deliveryPrice,
          taxAmount: totalTaxAmount?.toFixed(2),
          taxAmountWithoutDeliveryAndDiscountTax: taxAmountWithoutDeliveryAndDiscountTax,
        },
      });
    }
  }, [
    promoDiscountAmount,
    deliveryTax,
    discountTax,
    offerDiscount,
    offerDiscountTax,
    newSum,
    totalTaxAmount,
    finalTotalAmount,
  ]);
  const handleChange = useCallback((e, index) => {
    setOrderDetailCopy((prev) => {
      return prev.map((pro, cartIndex) => {
        if (index == cartIndex) {
          return {
            ...pro,
            description: e.target.value,
          };
        } else {
          return {
            ...pro,
          };
        }
      });
    });
  });
  useEffect(() => {
    const timeout = setTimeout(() => {
      setCartData((prev) => {
        return {
          ...prev,
          orderDetails: prev?.orderDetails?.map((order, index) => {
            return {
              ...order,
              description: orderDetailCopy?.[index]?.description,
            };
          }),
        };
      });
    }, [1000]);
    return () => clearTimeout(timeout);
  }, [orderDetailCopy]);

  const handleDecreaseQuantity = (index) => {
    setCartData((prev) => {
      return {
        ...prev,
        orderDetails: prev.orderDetails.map((item1, indexCart) => {
          if (index === indexCart) {
            return {
              ...item1,
              productVariations: item1.productVariations.map((item) => {
                return {
                  ...item,
                  quantity:
                    item.quantity <= 1 ? item.quantity : item.quantity - 1,
                };
              }),
            };
          } else {
            return { ...item1 };
          }
        }),
      };
    });
  };

  const handleIncreaseQuantity = (index, item) => {
    setCartData((prev) => {
      return {
        ...prev,
        orderDetails: prev.orderDetails.map((item1, indexCart) => {
          if (index === indexCart) {
            const maxQuantity = item?.productVariations?.find(
              (item1) => item1.id === item?.productVariations?.[0]?.id
            )?.stockCount;
            if (item1.productVariations[0].quantity === maxQuantity) {
              openNotificationWithIcon(
                "error",
                `Stock Count is only ${
                  item1.productVariations[0].quantity
                } of this product !`
              );
              return { ...item1 };
            } else {
              return {
                ...item1,
                productVariations: item1.productVariations.map((item) => {
                  return {
                    ...item,
                    quantity: item.quantity + 1,
                  };
                }),
              };
            }
          } else {
            return { ...item1 };
          }
        }),
      };
    });
  };

  const handleDeleteItem = (index) => {
    const updatedCartData = {
      ...cartData,
      orderDetails: cartData.orderDetails?.filter(
        (_, cartIndex) => index !== cartIndex
      ),
    };
    setCartData(updatedCartData);
    dispatch({
      type: "CHANGE_LOCAL_STORAGE_DATA",
      payload: {
        newData: updatedCartData,
      },
    });
  };

  return (
    <div
      class="card checkout-card border-0"
      style={displayNone ? { display: "none" } : {}}
    >
      <div class="card-header bg-white fw-bold">
        Your Order{" "}
        <span style={{ color: "Red", fontWeight: "normal", fontSize: "14px" }}>
          {homePageData?.deliveryMethod?.find(
            (item) => item.deliveryMethodValue == activeDeliveryMethod
          )?.message &&
            "(" +
              homePageData?.deliveryMethod?.find(
                (item) => item.deliveryMethodValue == activeDeliveryMethod
              )?.message +
              ")"}{" "}
        </span>
      </div>
      <div class="card-body no-padding">
        <>
          {" "}
          <div
            ref={cartContainerRef}
            className={`card_price pb-md-0 pb-3 ${
              deliveryMethod == 1
                ? `${
                    offerDiscount > 0
                      ? "pickup-order-items-container-offer"
                      : "pickup-order-items-container"
                  }`
                : `${
                    offerDiscount > 0
                      ? "order-items-container-offer"
                      : "order-items-container"
                  }`
            }`}
            style={{
              overflowY: "scroll",
              minHeight: 210,
            }}
          >
            {cartData?.orderDetails?.length > 0 ||
            halfHalfProducts.length > 0 ? (
              cartData?.orderDetails?.map((item, index) => (
                <div className="cat-product-box" key={item.id + index}>
                  <div className="d-flex align-content-center justify-content-between row">
                    <a className="col-md-9 col-9">
                      <p className="fw-bold">{item.productName}</p>{" "}
                      <p>
                        <ProductComponentWithCommas product={item} />
                      </p>
                    </a>

                    <div className="sign col-md-3 col-3 pe-5">
                      <span
                        id="minus"
                        onClick={() => handleDecreaseQuantity(index)}
                      >
                        <i className="fa fa-minus" />
                      </span>{" "}
                      <p id="quantity">
                        {item?.productVariations[0]?.quantity}
                      </p>{" "}
                      <span
                        id="plus"
                        onClick={() => handleIncreaseQuantity(index, item)}
                      >
                        <i className="fa fa-plus" />
                      </span>
                      <div
                        className="delete-btn"
                        onClick={() => handleDeleteItem(index)}
                      >
                        <a className="text-dark-white">
                          <i className="far fa-trash-alt" />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="cat-product border-0">
                    <div className="price">
                      <a className="text-dark-white fw-500">
                        {currencySymbol}
                        {
                          item?.productVariations?.find(
                            (item1) =>
                              item1?.id === item?.productVariations?.[0]?.id
                          )?.price
                        }{" "}
                        {item.productVariations[0]?.name &&
                          `(${item.productVariations[0]?.name})`}
                        <span style={{ color: "#e3201e", marginLeft: "1rem" }}>
                          {currencySymbol}{" "}
                          {(
                            item?.productVariations[0]?.quantity *
                            item?.productVariations?.find(
                              (item1) =>
                                item1.id === item?.productVariations?.[0]?.id
                            )?.price
                          )?.toFixed(2)}
                        </span>
                      </a>
                    </div>

                    <div className="product_desc">
                      <label htmlFor="" />{" "}
                    </div>
                  </div>
                  <TextArea
                    value={orderDetailCopy?.[index]?.description}
                    onChange={(e) => handleChange(e, index)}
                    placeholder="Add Special Instructions"
                    className="form-control rounded-3 desc-input"
                    rows={1}
                    style={{
                      background: "#F6FAFD",
                      borderColor: "#eee",
                    }}
                  />
                </div>
              ))
            ) : (
              <div
                className="d-flex justify-content-center align-items-center h-100"
                style={{ minHeight: 210 }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {/* <img
                    src="Assets/assets/images/empty-cart.png"
                    className="h-30 w-70"
                    style={{ width: "20%" }}
                  /> */}

                  <p className="mt-2 " style={{ color: "#6C757D" }}>
                    Your cart is empty !
                  </p>
                </div>
              </div>
            )}
            {halfHalfProducts?.length > 0 && <CartHalfHalfItem />}
          </div>
          <div class="item-total">
            <div class="total-price border-top d-flex align-items-start justify-content-between">
              <span class="text-dark-white fw-700">Items subtotal:</span>
              <span class="text-dark-white fw-700">
                {currencySymbol} {newSum?.toFixed(2)}
              </span>
            </div>

            {deliveryMethod == 2 &&
              selectedDeliveryLocation &&
              storesCartData?.orderDetails?.length > 0 && (
                <div className="total-price border-top d-flex align-items-start justify-content-between">
                  <span className="text-dark-white fw-700">
                    Delivery Charge:
                  </span>
                  <span className="text-dark-white fw-700">
                    {currencySymbol} {deliveryPrice ? deliveryPrice : "0"}
                  </span>
                </div>
              )}
            {voucherDiscount && (
              <div className="total-price border-top d-flex align-items-start justify-content-between">
                <span className="text-dark-white fw-700">
                  Voucher Discount:
                </span>
                <span className="text-dark-white fw-700">
                  - {currencySymbol} {promoDiscountAmount?.toFixed(2)}
                </span>
              </div>
            )}
            {offerDiscount > 0 && (
              <div className="total-price border-top d-flex align-items-start justify-content-between">
                <span className="text-dark-white fw-700">Offer Discount:</span>
                <span className="text-dark-white fw-700">
                  - {currencySymbol} {offerDiscount?.toFixed(2)}
                </span>
              </div>
            )}

            <div class="total-price border-top d-flex align-items-start justify-content-between">
              <span class="text-dark-white fw-700">Tax Amount:</span>
              <span class="text-dark-white fw-700">
                {currencySymbol} {totalTaxAmount?.toFixed(2)}
              </span>
            </div>

            <div class="total-price border-top d-flex align-items-start justify-content-between">
              {" "}
              <span class="text-dark-white fw-700">Total Amount Inc Tax:</span>
              <span class="text-dark-white fw-700">
                {currencySymbol} {finalTotalAmount}
              </span>
            </div>
          </div>
        </>

        {children && children}
      </div>
    </div>
  );
}

export default MyOrder;
