/** @format */

import { Typography } from "antd";
import OrderItemModifiers from "./OrderItemModifiers";
import OrderItemRemovedIngredients from "./OrderItemRemovedIngredients";
import OrderItemSpiceChoice from "./OrderItemSpiceChoice";

const ProductComponentWithCommas = ({ product }) => {
  const components = [
    {
      condition:
        product?.productVariations?.[0]?.OrderItemsPriceModifierViewModels
          ?.length > 0,
      component: (
        <OrderItemModifiers product={product?.productVariations?.[0]} />
      ),
    },
    {
      condition: product?.productSpiceChoices?.name,
      component: <OrderItemSpiceChoice product={product} />,
    },
    {
      condition:
        product?.productVariations?.[0]?.RemovedOrderItemsIngredientsViewModels
          ?.length > 0,
      component: (
        <OrderItemRemovedIngredients
          product={product?.productVariations?.[0]}
        />
      ),
    },
  ];
  const componentsWithCommas = components
    .filter((item) => item.condition)
    .reduce((acc, item, index, array) => {
      acc.push(item.component);
      if (index < array.length - 1) {
        acc.push(
          <Typography.Text
            key={`separator-${index}`}
            strong
            className="me-1 ms-1"
          >
            |
          </Typography.Text>
        );
      }
      return acc;
    }, []);

  return (
    <div class="w-100 d-flex flex-wrap align-items-center flex-grow-1">
      {componentsWithCommas}
    </div>
  );
};

export default ProductComponentWithCommas;
