/** @format */

import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const MetaTags = () => {
  const { homePageData } = useSelector((state) => state.storeReducer);
  const { metaTags } = homePageData || {};
  // console.log("homepage", homePageData);
  return (
    <Helmet>
      <title>{metaTags?.title}</title>
      <meta name="description" content={metaTags?.description} />
      <meta name="keywords" content={metaTags?.keyWords} />
      <meta property="twitter:title" content={metaTags?.metaTwitterTitle} />
      <meta property="twitter:image" content={metaTags?.metaTwitterImageUrl} />
      <meta property="og:title" content={metaTags?.metaOgTitle} />
      {/* <meta property="og:description" content={description} /> */}

      <meta property="og:image" content={metaTags?.metaOgImageUrl} />
      {/* Other meta tags */}
      {metaTags?.metaGoogleVerificationCode && (
        <meta
          name="google-site-verification"
          content={metaTags?.metaGoogleVerificationCode}
        />
      )}
      {metaTags?.googleAnalyticsCode && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${
            metaTags?.googleAnalyticsCode
          }`}
        />
      )}
      {metaTags?.googleAnalyticsCode && (
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${metaTags?.googleAnalyticsCode}');
          `}
        </script>
      )}
    </Helmet>
  );
};

export default MetaTags;
