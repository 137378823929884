/** @format */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MyOrderDineInSkeleton from "../../../../Components/Skeletons/MyOrderDineInSkeleton";
import { getLocalStorage } from "../../../../Helpers/FrontendHelper";
import useOnlineHelper from "../../../../Helpers/useOnlineHelper";

const MyOrderDinein = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paramValue = useParams();
  const { navigateToHomePage, clearCartItems } = useOnlineHelper();
  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);
  const { orderDetail, getOrderDetailLoading } = useSelector(
    (state) => state.profile
  );
  const [orderTotalSum, setOrderTotalSum] = useState(0);
  const currencySymbol = useSelector(
    (state) => state.storeReducer.storeData.currencySymbol
  );
  const storeState = useSelector(
    (state) => state.storeReducer.storeData.templateName
  );

  const {
    orderIdFromQuery,
    tableIdFromQuery,
    tableNameFromQuery,
  } = useOnlineHelper();

  const loacalStorageData = getLocalStorage(`${storeId}-${storeState}`);

  useEffect(() => {
    dispatch({
      type: "GET_GUEST_ORDER_DETAIL_REQUEST",
      payload: {
        data: {
          OrderId: orderIdFromQuery,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (orderDetail) {
      let orderSum = orderDetail?.productWithPriceDetailsViewModel?.reduce(
        (acc, item) => {
          return acc + parseFloat(item.total);
        },
        0
      );
      setOrderTotalSum(orderSum);
    }
  }, [orderDetail]);

  const backToHomeHandler = () => {
    dispatch({
      type: "REMOVE_CHECK_VOUCHER",
    });
    clearCartItems();
    navigateToHomePage();
  };
  if (getOrderDetailLoading) {
    return <MyOrderDineInSkeleton />;
  }
  return (
    <div className="  dine-in-order-container">
      <div className="card shadow-sm dine-in-custom-card">
        <div className="card-header dine-in-custom-card-header">
          <div className="dine-in-header-content">
            <button
              type="button"
              className="dine-in-back-button"
              onClick={() => backToHomeHandler()}
            >
              <i className="fas fa-arrow-left" />
            </button>
            <h6 className="fw-bold">Your Order Details</h6>
          </div>
        </div>

        <div className="card-body dine-in-card-body d-flex flex-column">
          {/* Table Details */}
          <div className="dine-in-table-details mb-4">
            <h5 className="dine-in-section-title">Table Details</h5>
            <p>
              <strong>Table Name:</strong> {tableNameFromQuery}
            </p>
            <p>
              <strong>Table ID:</strong> # {tableIdFromQuery}
            </p>
          </div>

          {/* Order Details */}
          <div className="dine-in-order-details mb-4 flex-grow-1">
            <h5 className="dine-in-section-title">Order Details</h5>
            <ul className="list-group dine-in-list-group">
              {orderDetail?.productWithPriceDetailsViewModel?.map(
                (item, index) => {
                  return (
                    <li
                      key={index}
                      className="list-group-item dine-in-list-group-item d-flex justify-content-between align-items-center"
                    >
                      <span className="dine-in-product-name">
                        {parseInt(item.quantity)} x {item.name}
                      </span>
                      {/* <span className="dine-in-price">
                        {currencySymbol} {item.total}
                      </span> */}
                    </li>
                  );
                }
              )}
            </ul>
          </div>

          {/* Sticky Total and Button */}
          <div className="dine-in-sticky-bottom mt-auto">
            <div className="dine-in-total-amount text-right mb-4 ms-2">
              <h6>
                Tax Amount: {currencySymbol}{" "}
                {orderDetail?.orderDetailsViewModel?.taxAmount}
              </h6>
              <h6 className="mt-2">
                Total Amount: {currencySymbol}{" "}
                {orderDetail?.orderDetailsViewModel?.totalAmount}
              </h6>
            </div>

            <div className="text-center">
              <button
                onClick={() => backToHomeHandler()}
                type="button"
                className="btn dine-in-custom-btn btn-lg"
              >
                Order More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrderDinein;
