/** @format */

import { Alert, Form, Input, Spin } from "antd";
import { gapi } from "gapi-script";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { GoogleLogin } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Button/Button.jsx";

import { useParams } from "react-router-dom";
import { navigateHelper } from "../../Helpers/NavigateHelper.js";
import ForgotPassword from "./ForgotPassword.jsx";

const Login = ({ showModal, setShowModal, setShowRegisterModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paramValue = useParams();
  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);
  const storeState = useSelector(
    (state) => state.storeReducer.storeData.templateName
  );
  const googleLoginLoading = useSelector(
    (state) => state.authentication.googleLoginLoading
  );

  const bannerImages = useSelector(
    (state) =>
      state.storeReducer.storeData.bannerImagewithBannerTypeListViewModels
  );
  const isUnAuthenticated = useSelector(
    (state) => state.storeReducer.isUnAuthenticated
  );
  const isSessionExpired = useSelector(
    (state) => state.storeReducer.isSessionExpired
  );
  const { showErrorOnForm, message } = useSelector(
    (state) => state.authentication
  );

  const authenticationState = useSelector((state) => state.authentication);
  const [form] = Form.useForm();
  const [isForgotPasswordModalOpen, setIsForgotPasswordOpen] = useState(false);
  useEffect(() => {
    if (authenticationState.isLoggedIn) {
      form.resetFields();
      setShowModal(false);
      if (isSessionExpired) {
        window.location.reload();
      }
      if (
        window.location.pathname ==
          `/${paramValue.type}/${paramValue.storeName}/guestcheckout` ||
        window.location.pathname ==
          `/${paramValue.type}/${paramValue.storeName}/guestCheckout` ||
        window.location.pathname == "/guestcheckout" ||
        window.location.pathname == "/guestCheckout"
      ) {
        if (query.get("TableId")) {
          const deliveryMethod = query.get("deliveryMethod");
          navigate(
            `${navigateHelper(
              paramValue.type,
              paramValue.storeName
            )}/checkout?TableId=${query.get("TableId")}&TableName=${query.get(
              "TableName"
            )}&deliveryMethod=${deliveryMethod}`
          );
        } else {
          const deliveryMethod = query.get("deliveryMethod");
          navigate(
            `${navigateHelper(
              paramValue.type,
              paramValue.storeName
            )}/checkout?deliveryMethod=${deliveryMethod}`
          );
        }
      }
    }
  }, [authenticationState.isLoggedIn]);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_CLIENT_ID,
        scope: "email",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  const onLoginFormSubmit = (values) => {
    dispatch({
      type: "AUTHENTICATE_USER_REQUEST",
      payload: {
        Email: values.Email,
        Password: values.password,
        storeId,
        storeState,
      },
    });
  };

  const onSuccess = (res) => {
    dispatch({
      type: "LOGIN_WITH_GOOGLE_REQUEST",
      payload: {
        FullName: res.profileObj.name,
        Password: "",
        ConfirmPassword: "",
        email: res.profileObj.email,
        PhoneNumber: "",
        GoogleImage: res.profileObj.imageUrl ? res.profileObj.imageUrl : "",
        IsGoogleLogin: true,
        storeId,
        storeState,
      },
    });
  };

  const onFailure = (res) => {};
  useEffect(() => {
    if (isUnAuthenticated) {
      setShowModal(true);
    }
  }, [isUnAuthenticated]);

  return (
    <>
      <Modal
        show={isForgotPasswordModalOpen}
        footer={null}
        width="35vw"
        onHide={() => {
          setIsForgotPasswordOpen(false);
          dispatch({
            type: "CLEAR_SEND_LINK_SUCCESS",
          });
        }}
        className="login-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px" }}>
            Forgot Password ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ForgotPassword setIsForgotPasswordOpen={setIsForgotPasswordOpen} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showModal}
        footer={null}
        onHide={() => setShowModal(false)}
        className="login-modal m-auto"
        size="lg"
      >
        <div class="modal-body login log1">
          {isSessionExpired && (
            <Alert
              message="Your Session has been expired. Please Login Again !"
              type="error"
              showIcon={true}
              style={{ marginBottom: "1rem" }}
            />
          )}
          <div class="card card0 border-0 w-auto">
            <div class="row d-flex">
              <div class="col-lg-5">
                <div class="card1 ">
                  <div class="row px-3 justify-content-center mt-4 mb-5 border-line innerimg d-none d-md-flex">
                    {" "}
                    <img
                      src={
                        bannerImages?.find((item) => item.bannerType == 2)
                          ?.banners[0]?.image
                          ? bannerImages?.find((item) => item.bannerType == 2)
                              ?.banners[0]?.image
                          : "https://images.unsplash.com/photo-1529539795054-3c162aab037a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bG9naW58ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
                      }
                      class="image"
                    />{" "}
                  </div>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="card2 card border-0">
                  <Modal.Header closeButton style={{ padding: "0.5rem" }}>
                    <Modal.Title style={{ fontSize: "18px" }}>
                      Welcome Back !
                    </Modal.Title>
                  </Modal.Header>
                  <div class="d-flex align-items-flex-start justify-content-between mb-4" />
                  {showErrorOnForm && (
                    <Alert
                      message={message}
                      type="error"
                      showIcon={true}
                      style={{ marginBottom: "1.4rem", marginTop: "-2rem" }}
                    />
                  )}

                  <div className="login">
                    <Form
                      style={{ marginTop: "-1rem" }}
                      className="login"
                      name="form"
                      onFinish={onLoginFormSubmit}
                      autoComplete="off"
                      initialValues={{
                        remember: true,
                      }}
                      form={form}
                    >
                      <Form.Item
                        label="Email Address"
                        name="Email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                          {
                            type: "email",
                            message: "Please enter valid email!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Email" class="form-control" />
                      </Form.Item>
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password
                          className="login-password"
                          type={"password"}
                          placeholder="Enter password"
                          class="form-control"
                        />
                      </Form.Item>
                      <div class="row px-3 mb-4 justify-content-between">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <a
                            onClick={() => {
                              setShowModal(false);
                              setIsForgotPasswordOpen(true);
                            }}
                            style={{ color: "#e41f1f" }}
                            class="ml-auto mb-0 text-sm text-red"
                          >
                            Forgot Password?
                          </a>
                        </div>
                      </div>{" "}
                      <Form.Item>
                        <Button
                          icon={
                            <img
                              style={{
                                height: "20px",
                                width: "20px",
                                marginRight: "10px",
                              }}
                              src={"/Assets/assets/images/login.png"}
                            />
                          }
                          data-bs-dismiss={
                            authenticationState.isLoggedIn ? "modal" : ""
                          }
                          type="primary"
                          htmlType="submit"
                          className="primary-button"
                          loading={authenticationState.isLoading}
                        >
                          {" "}
                          {authenticationState.isLoading ? "" : "Login"}
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                  <div class="row px-3 mb-2">
                    <div class="line" />{" "}
                    <small class="or text-center">Or</small>
                    <div class="line" />
                  </div>
                  <GoogleLogin
                    render={(renderProps) => (
                      <a
                        href="#"
                        onClick={renderProps.onClick}
                        class="social-button"
                        id="google-connect"
                      >
                        {" "}
                        <span>
                          {!googleLoginLoading ? (
                            "Login With Google"
                          ) : (
                            <Spin
                              style={{
                                margin: "auto",
                                marginLeft: "6rem",
                              }}
                            />
                          )}
                        </span>
                      </a>
                    )}
                    icon={false}
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Login with Google"
                    onSuccess={onSuccess}
                  />
                  <div class="row mb-4 px-3">
                    <p class="fw-bold">
                      Don't have an account?{" "}
                      <a
                        class="text-danger "
                        onClick={() => {
                          setShowModal(false);
                          setShowRegisterModal(true);
                        }}
                      >
                        Register
                      </a>
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Login;
