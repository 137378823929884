/** @format */

import { Avatar, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getLocalStorage, isAuth } from "../../Helpers/FrontendHelper";
import { navigateHelper } from "../../Helpers/NavigateHelper";
import useOnlineHelper from "../../Helpers/useOnlineHelper";
import Login from "../BakeryComponents/Login";
import Register from "../BakeryComponents/Register";
import ChangePassword from "../ForgotPassword/ChangePassword";

function Navbar({
  homePageData,
  storeState,
  showSearchIcon,
  updatedCartData,
  children,
}) {
  const [storeDetails, setStoreDetails] = useState({});
  const paramValue = useParams();
  const navigate = useNavigate();
  const {
    homePageUrl,
    navigateToHomePage,
    navigateToProfile,
  } = useOnlineHelper();
  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);
  const authenticationState = useSelector((state) => state.authentication);
  const [showModal, setShowModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [updatedLsData, setUpdatedLsData] = useState({});
  const profileData = useSelector((state) => state.profile.userProfileData);
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);

  // useEffect(() => {
  //   setUpdatedLsData(getLocalStorage(`${storeId}-${storeState}`));
  // }, []);
  const dispatch = useDispatch();
  const isSessionExpired = useSelector(
    (state) => state.storeReducer.isSessionExpired
  );
  const isSearchModalOpen = useSelector(
    (state) => state.StateManager.isSearchModalOpen
  );

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  useEffect(() => {
    setStoreDetails(homePageData?.storeDetails);
  }, [homePageData]);

  useEffect(() => {
    dispatch({
      type: "GET_LOCAL_STORAGE_DATA",
      payload: {
        storeId,
        storeState,
      },
    });
  }, []);
  useEffect(() => {
    if (isSessionExpired) {
      setShowModal(true);
    }
  }, [isSessionExpired]);
  useEffect(() => {
    if (query.get("code") && query.get("email")) {
      setIsChangePasswordOpen(true);
    }
  }, [query.get("code"), query.get("email")]);

  useEffect(() => {
    if (
      updatedLsData?.userInformation?.fullName !=
      getLocalStorage(`${storeId}-${storeState}`)?.userInformation?.fullName
    ) {
      setUpdatedLsData(getLocalStorage(`${storeId}-${storeState}`));
    }
  }, [getLocalStorage(`${storeId}-${storeState}`)]);
  useEffect(() => {
    if (isAuth(storeId)) {
      if (
        !getLocalStorage(`${storeId}-${storeState}`)?.userInformation?.fullName
      ) {
        dispatch({
          type: "LOGOUT_REQUEST",
          payload: {
            storeId,
            storeState,
          },
        });
      }
    }
  }, []);

  return (
    <>
      <Modal
        maskClosable={false}
        className="reset-password login-modal"
        title="Change Password"
        open={isChangePasswordOpen}
        onCancel={() => {
          setIsChangePasswordOpen(false);
          navigate(
            `${navigateHelper(paramValue.type, paramValue.storeName, true)}`
          );
        }}
        style={{ top: "2rem", padding: "0" }}
        footer={null}
      >
        <ChangePassword
          setIsChangePasswordOpen={setIsChangePasswordOpen}
          storeId={storeId}
        />
      </Modal>
      <Login
        showModal={showModal}
        setShowModal={setShowModal}
        setShowRegisterModal={setShowRegisterModal}
      />

      <Register
        storeId={storeId}
        storeState={storeState}
        showModal={showRegisterModal}
        setShowModal={setShowRegisterModal}
        setShowLoginModal={setShowModal}
      />

      {/* <AddToCart
        homePageData={homePageData}
        updatedCartData={updatedCartData}
        setShowModal={setShowModal}
      /> */}
      {/* Two Headers : One is for mobile and another is for desktop */}
      <header className="sticky-top  pt-2 pb-2 bg-light  d-lg-none d-md-block  ">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light bg-light p-0">
            <div className="row">
              <div className="col-md-2 col-2">
                <Link className="navbar-brand" to={homePageUrl}>
                  {storeDetails?.image && (
                    <img src={storeDetails?.image} alt="" className="img" />
                  )}
                </Link>
              </div>

              <div className="col-md-10 col-10">
                <div
                  className="collapse navbar-collapse  d-flex justify-content-end"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav m-auto mb-2 mb-lg-0 pt-2 pb-2 d-none d-lg-flex">
                    <li>
                      <div className="topaddress">
                        <div className="d-flex align-items-center">
                          <div className="address-icon align-middle me-2 ms-2">
                            <i className="fa fa-home" aria-hidden="true" />
                          </div>

                          <div className="address-text">
                            Find Us <br />
                            <small className="d-block text-muted">
                              {storeDetails?.address}
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="topaddress">
                        <div className="d-flex align-items-center">
                          <div className="address-icon align-middle me-2 ms-2">
                            <i className="fa fa-phone" aria-hidden="true" />
                          </div>

                          <div className="address-text">
                            Call Us <br />
                            <small className="d-block text-muted">
                              {storeDetails?.phoneNumber}
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="topaddress">
                        <div className="d-flex align-items-center">
                          <div className="address-icon align-middle  me-2 ms-2">
                            <i className="fa fa-envelope" aria-hidden="true" />
                          </div>

                          <div className="address-text">
                            Mail Us <br />
                            <small className="d-block text-muted">
                              {storeDetails?.email}
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  {showSearchIcon && (
                    <div class="newsearch">
                      <a
                        onClick={() => {
                          dispatch({ type: "TOGGLE_SEARCH_MODAL" });
                        }}
                      >
                        <i class="fas fa-search" />
                      </a>
                    </div>
                  )}

                  {/* <div className="cart-btn ms-2 d-flex align-items-center">
                    <a
                      href="#"
                      className="text-light-green fw-700"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvascart"
                      aria-controls="offcanvascart"
                    >
                      {" "}
                      <i className="fas fa-shopping-bag" />
                      <span className="user-alert-cart">
                        {storesCartData?.orderDetails?.length +
                          halfHalfProducts.length / 2}
                      </span>
                    </a>
                  </div> */}
                  <div className=" ">
                    {authenticationState.isLoggedIn ? (
                      <>
                        <div className="logout">
                          <div className="btn-group ms-3 ">
                            {getLocalStorage(`${storeId}-${storeState}`)
                              ?.userInformation?.normalImage ? (
                              <img
                                class="img-xs rounded-circle"
                                src={
                                  getLocalStorage(`${storeId}-${storeState}`)
                                    ?.userInformation?.normalImage
                                }
                                alt="Profile image"
                              />
                            ) : (
                              <Avatar
                                style={{
                                  backgroundColor: "#f56a00",
                                  verticalAlign: "middle",
                                }}
                                size="large"
                              >
                                {
                                  getLocalStorage(`${storeId}-${storeState}`)
                                    ?.userInformation?.fullName?.[0]
                                }
                              </Avatar>
                            )}

                            <button
                              className="btn btn-secondary btn-sm dropdown-toggle bg-transparent border-0 text-red fw-bold"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span className="mobile">
                                {
                                  updatedLsData?.userInformation?.fullName?.split(
                                    " "
                                  )?.[0]
                                }
                              </span>
                              <span className="desktop">
                                {updatedLsData?.userInformation?.fullName}
                              </span>
                            </button>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  dispatch({
                                    type: "CHANGE_ACTIVE_TAB1",
                                  });
                                  navigateToProfile();
                                }}
                              >
                                <i className="fas fa-user me-2" />
                                Profile
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  // if (
                                  //   window.location.pathname ==
                                  //     `/${paramValue.type}/${
                                  //       paramValue.storeName
                                  //     }/checkout` ||
                                  //   window.location.pathname == `/checkout`
                                  // ) {
                                  //   if (query.get("TableId")) {
                                  //     const deliveryMethod = query.get(
                                  //       "deliveryMethod"
                                  //     );
                                  //     navigate(
                                  //       `${navigateHelper(
                                  //         paramValue.type,
                                  //         paramValue.storeName
                                  //       )}/guestcheckout?TableId=${query.get(
                                  //         "TableId"
                                  //       )}&TableName=${query.get(
                                  //         "TableName"
                                  //       )}&deliveryMethod=${deliveryMethod}`
                                  //     );
                                  //   } else {
                                  //     const deliveryMethod = query.get(
                                  //       "deliveryMethod"
                                  //     );
                                  //     navigate(
                                  //       `${navigateHelper(
                                  //         paramValue.type,
                                  //         paramValue.storeName
                                  //       )}/guestcheckout?deliveryMethod=${deliveryMethod}`
                                  //     );
                                  //   }
                                  // }
                                  // if (
                                  //   window.location.pathname ==
                                  //     `/${paramValue.type}/${
                                  //       paramValue.storeName
                                  //     }/profile` ||
                                  //   window.location.pathname == `/profile`
                                  // ) {
                                  //   navigate(
                                  //     `${navigateHelper(
                                  //       paramValue.type,
                                  //       paramValue.storeName,
                                  //       true
                                  //     )}`
                                  //   );
                                  // }

                                  dispatch({
                                    type: "LOGOUT_REQUEST",
                                    payload: {
                                      storeId,
                                      storeState,
                                    },
                                  });
                                  navigateToHomePage();
                                }}
                              >
                                <i className="fas fa-power-off me-2" />
                                Logout
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : isAuth(storeId) ? (
                      <div className="logout">
                        <div className="btn-group ms-3 ">
                          {getLocalStorage(`${storeId}-${storeState}`)
                            ?.userInformation?.normalImage ? (
                            <img
                              class="img-xs rounded-circle"
                              src={
                                getLocalStorage(`${storeId}-${storeState}`)
                                  ?.userInformation?.normalImage
                              }
                              alt="Profile image"
                            />
                          ) : (
                            <Avatar
                              style={{
                                backgroundColor: "#f56a00",
                                verticalAlign: "middle",
                              }}
                              size="large"
                            >
                              {
                                getLocalStorage(`${storeId}-${storeState}`)
                                  ?.userInformation?.fullName?.[0]
                              }
                            </Avatar>
                          )}

                          <button
                            className="btn btn-secondary btn-sm dropdown-toggle bg-transparent border-0 text-red fw-bold"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span className="mobile">
                              {
                                updatedLsData?.userInformation?.fullName?.split(
                                  " "
                                )?.[0]
                              }
                            </span>
                            <span className="desktop">
                              {updatedLsData?.userInformation?.fullName}
                            </span>
                          </button>
                          <div className="dropdown-menu">
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                dispatch({
                                  type: "CHANGE_ACTIVE_TAB1",
                                });
                                navigateToProfile();
                              }}
                            >
                              <i className="fas fa-user me-2" />
                              Profile
                            </a>

                            <a
                              className="dropdown-item"
                              onClick={() => {
                                // if (
                                //   window.location.pathname ==
                                //     `/${paramValue.type}/${
                                //       paramValue.storeName
                                //     }/checkout` ||
                                //   window.location.pathname == `/checkout`
                                // ) {
                                //   if (query.get("TableId")) {
                                //     const deliveryMethod = query.get(
                                //       "deliveryMethod"
                                //     );
                                //     navigate(
                                //       `${navigateHelper(
                                //         paramValue.type,
                                //         paramValue.storeName
                                //       )}/guestcheckout?TableId=${query.get(
                                //         "TableId"
                                //       )}&TableName=${query.get(
                                //         "TableName"
                                //       )}&deliveryMethod=${deliveryMethod}`
                                //     );
                                //   } else {
                                //     const deliveryMethod = query.get(
                                //       "deliveryMethod"
                                //     );
                                //     navigate(
                                //       `${navigateHelper(
                                //         paramValue.type,
                                //         paramValue.storeName
                                //       )}/guestcheckout?deliveryMethod=${deliveryMethod}`
                                //     );
                                //   }
                                // }
                                // if (
                                //   window.location.pathname ==
                                //   `/${paramValue.type}/${
                                //     paramValue.storeName
                                //   }/profile`
                                // ) {
                                //   navigate(
                                //     `${navigateHelper(
                                //       paramValue.type,
                                //       paramValue.storeName,
                                //       true
                                //     )}`
                                //   );
                                // }
                                dispatch({
                                  type: "LOGOUT_REQUEST",
                                  payload: {
                                    storeId,
                                    storeState,
                                  },
                                });
                                navigateToHomePage();
                              }}
                            >
                              <i className="fas fa-power-off me-2" />
                              Logout
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="loginreg ms-4">
                        <a
                          className="fw-bold text-red"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          <i className="fas fa-user" /> Login/Register
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        {children}
      </header>
      <header className="sticky-top  pt-2 pb-2 bg-light  d-md-none d-none d-lg-block ">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light bg-light p-0">
            <Link className="navbar-brand" to={homePageUrl}>
              {storeDetails?.image && (
                <img src={storeDetails?.image} alt="" className="img" />
              )}
            </Link>
            {isAuth(storeId) ? (
              <div class="d-flex align-items-center d-md-none">
                {/* <!-- Small button groups (default and split) --> */}
                <div class="logout">
                  <div class="btn-group ms-3 ">
                    {getLocalStorage(`${storeId}-${storeState}`)
                      ?.userInformation?.normalImage ? (
                      <img
                        class="img-xs rounded-circle"
                        src={
                          getLocalStorage(`${storeId}-${storeState}`)
                            ?.userInformation?.normalImage
                        }
                        alt="Profile image"
                      />
                    ) : (
                      <Avatar
                        style={{
                          backgroundColor: "#f56a00",
                          verticalAlign: "middle",
                        }}
                        size="large"
                      >
                        {
                          getLocalStorage(`${storeId}-${storeState}`)
                            ?.userInformation?.fullName?.[0]
                        }
                      </Avatar>
                    )}

                    <button
                      class="btn btn-secondary btn-sm dropdown-toggle bg-transparent border-0 text-red fw-bold"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {
                        getLocalStorage(`${storeId}-${storeState}`)
                          ?.userInformation?.fullName
                      }
                    </button>
                    <div class="dropdown-menu">
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          dispatch({
                            type: "CHANGE_ACTIVE_TAB1",
                          });
                          navigateToProfile();
                        }}
                      >
                        <i className="fas fa-user me-2" />
                        Profile
                      </a>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          // if (
                          //   window.location.pathname ==
                          //     `/${paramValue.type}/${
                          //       paramValue.storeName
                          //     }/checkout` ||
                          //   window.location.pathname == `/checkout`
                          // ) {
                          //   if (query.get("TableId")) {
                          //     const deliveryMethod = query.get(
                          //       "deliveryMethod"
                          //     );
                          //     navigate(
                          //       `${navigateHelper(
                          //         paramValue.type,
                          //         paramValue.storeName
                          //       )}/guestcheckout?TableId=${query.get(
                          //         "TableId"
                          //       )}&TableName=${query.get(
                          //         "TableName"
                          //       )}&deliveryMethod=${deliveryMethod}`
                          //     );
                          //   } else {
                          //     const deliveryMethod = query.get(
                          //       "deliveryMethod"
                          //     );
                          //     navigate(
                          //       `${navigateHelper(
                          //         paramValue.type,
                          //         paramValue.storeName
                          //       )}/guestcheckout?deliveryMethod=${deliveryMethod}`
                          //     );
                          //   }
                          // }
                          // if (
                          //   // window.location.pathname ==
                          //   // `/${paramValue.type}/${
                          //   //   paramValue.storeName
                          //   // }/profile`
                          //   true
                          // ) {
                          //   navigat
                          // }
                          dispatch({
                            type: "LOGOUT_REQUEST",
                            payload: {
                              storeId,
                              storeState,
                            },
                          });
                          navigateToHomePage();
                        }}
                      >
                        <i className="fas fa-power-off me-2" />
                        Logout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="loginreg ms-4 d-md-none">
                <a
                  className="fw-bold text-red"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <i className="fas fa-user" /> Login/Register
                </a>
              </div>
            )}

            <button
              className="navbar-toggler d-none"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse  d-flex justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto mb-2 mb-lg-0 pt-2 pb-2 d-none d-md-flex justify-content-between">
                <li>
                  <div className="topaddress">
                    <div className="d-flex align-items-center">
                      <div className="address-icon align-middle me-2 ms-2">
                        <i className="fa fa-home" aria-hidden="true" />
                      </div>

                      <div className="address-text">
                        Find Us <br />
                        <small className="d-block text-muted">
                          {storeDetails?.address}
                        </small>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="topaddress">
                    <div className="d-flex align-items-center">
                      <div className="address-icon align-middle me-2 ms-2">
                        <i className="fa fa-phone" aria-hidden="true" />
                      </div>

                      <div className="address-text">
                        Call Us <br />
                        <small className="d-block text-muted">
                          {storeDetails?.phoneNumber}
                        </small>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="topaddress">
                    <div className="d-flex align-items-center">
                      <div className="address-icon align-middle  me-2 ms-2">
                        <i className="fa fa-envelope" aria-hidden="true" />
                      </div>

                      <div className="address-text">
                        Mail Us <br />
                        <small className="d-block text-muted">
                          {storeDetails?.email}
                        </small>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              {showSearchIcon && (
                <div class="newsearch">
                  <a
                    onClick={() => {
                      dispatch({ type: "TOGGLE_SEARCH_MODAL" });
                    }}
                  >
                    <i class="fas fa-search" />
                  </a>
                </div>
              )}

              {/* <div className="cart-btn ms-2 d-flex align-items-center">
                <a
                  href="#"
                  className="text-light-green fw-700"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvascart"
                  aria-controls="offcanvascart"
                >
                  {" "}
                  <i className="fas fa-shopping-bag" />
                  <span className="user-alert-cart">
                    {Number(
                      storesCartData?.orderDetails?.length +
                        halfHalfProducts.length / 2
                    )}
                  </span>
                </a>
              </div> */}
              <div className="d-none d-md-flex align-items-center ">
                {authenticationState.isLoggedIn ? (
                  <>
                    <div className="logout">
                      <div className="btn-group ms-3 ">
                        {getLocalStorage(`${storeId}-${storeState}`)
                          ?.userInformation?.normalImage ? (
                          <img
                            class="img-xs rounded-circle"
                            src={
                              getLocalStorage(`${storeId}-${storeState}`)
                                ?.userInformation?.normalImage
                            }
                            alt="Profile image"
                          />
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: "#f56a00",
                              verticalAlign: "middle",
                            }}
                            size="large"
                          >
                            {
                              getLocalStorage(`${storeId}-${storeState}`)
                                ?.userInformation?.fullName?.[0]
                            }
                          </Avatar>
                        )}

                        <button
                          className="btn btn-secondary btn-sm dropdown-toggle bg-transparent border-0 text-red fw-bold"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {updatedLsData?.userInformation?.fullName}
                        </button>
                        <div className="dropdown-menu">
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              dispatch({
                                type: "CHANGE_ACTIVE_TAB1",
                              });
                              navigateToProfile();
                            }}
                          >
                            <i className="fas fa-user me-2" />
                            Profile
                          </a>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              // if (
                              //   window.location.pathname ==
                              //     `${navigateHelper(
                              //       paramValue.type,
                              //       paramValue.storeName
                              //     )}/checkout` ||
                              //   window.location.pathname == `/checkout`
                              // ) {
                              //   if (query.get("TableId")) {
                              //     const deliveryMethod = query.get(
                              //       "deliveryMethod"
                              //     );
                              //     navigate(
                              //       `${navigateHelper(
                              //         paramValue.type,
                              //         paramValue.storeName
                              //       )}/guestcheckout?TableId=${query.get(
                              //         "TableId"
                              //       )}&TableName=${query.get(
                              //         "TableName"
                              //       )}&deliveryMethod=${deliveryMethod}`
                              //     );
                              //   } else {
                              //     const deliveryMethod = query.get(
                              //       "deliveryMethod"
                              //     );
                              //     navigate(
                              //       `${navigateHelper(
                              //         paramValue.type,
                              //         paramValue.storeName
                              //       )}/guestcheckout?deliveryMethod=${deliveryMethod}`
                              //     );
                              //   }
                              // }
                              // if (
                              //   window.location.pathname ==
                              //     `/${paramValue.type}/${
                              //       paramValue.storeName
                              //     }/profile` ||
                              //   window.location.pathname == `/profile`
                              // ) {
                              //   navigate(
                              //     `${navigateHelper(
                              //       paramValue.type,
                              //       paramValue.storeName,
                              //       true
                              //     )}`
                              //   );
                              // }
                              dispatch({
                                type: "LOGOUT_REQUEST",
                                payload: {
                                  storeId,
                                  storeState,
                                },
                              });
                              navigateToHomePage();
                            }}
                          >
                            <i className="fas fa-power-off me-2" />
                            Logout
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                ) : isAuth(storeId) ? (
                  <div className="logout">
                    <div className="btn-group ms-3 ">
                      {getLocalStorage(`${storeId}-${storeState}`)
                        ?.userInformation?.normalImage ? (
                        <img
                          class="img-xs rounded-circle"
                          src={
                            getLocalStorage(`${storeId}-${storeState}`)
                              ?.userInformation?.normalImage
                          }
                          alt="Profile image"
                        />
                      ) : (
                        <Avatar
                          style={{
                            backgroundColor: "#f56a00",
                            verticalAlign: "middle",
                          }}
                          size="large"
                        >
                          {
                            getLocalStorage(`${storeId}-${storeState}`)
                              ?.userInformation?.fullName?.[0]
                          }
                        </Avatar>
                      )}

                      <button
                        className="btn btn-secondary btn-sm dropdown-toggle bg-transparent border-0 text-red fw-bold"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {updatedLsData?.userInformation?.fullName}
                      </button>
                      <div className="dropdown-menu">
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            dispatch({
                              type: "CHANGE_ACTIVE_TAB1",
                            });
                            navigateToProfile();
                          }}
                        >
                          <i className="fas fa-user me-2" />
                          Profile
                        </a>

                        <a
                          className="dropdown-item"
                          onClick={() => {
                            // if (
                            //   window.location.pathname ==
                            //     `/${paramValue.type}/${
                            //       paramValue.storeName
                            //     }/checkout` ||
                            //   window.location.pathname == `/checkout`
                            // ) {
                            //   if (query.get("TableId")) {
                            //     const deliveryMethod = query.get(
                            //       "deliveryMethod"
                            //     );
                            //     navigate(
                            //       `${navigateHelper(
                            //         paramValue.type,
                            //         paramValue.storeName
                            //       )}/guestcheckout?TableId=${query.get(
                            //         "TableId"
                            //       )}&TableName=${query.get(
                            //         "TableName"
                            //       )}&deliveryMethod=${deliveryMethod}`
                            //     );
                            //   } else {
                            //     const deliveryMethod = query.get(
                            //       "deliveryMethod"
                            //     );
                            //     navigate(
                            //       `${navigateHelper(
                            //         paramValue.type,
                            //         paramValue.storeName
                            //       )}/guestcheckout?deliveryMethod=${deliveryMethod}`
                            //     );
                            //   }
                            // }

                            dispatch({
                              type: "LOGOUT_REQUEST",
                              payload: {
                                storeId,
                                storeState,
                              },
                            });
                            navigateToHomePage();
                          }}
                        >
                          <i className="fas fa-power-off me-2" />
                          Logout
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="loginreg ms-4">
                    <a
                      className="fw-bold text-red"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      <i className="fas fa-user" /> Login/Register
                    </a>
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
        {children}
      </header>
    </>
  );
}

export default Navbar;
