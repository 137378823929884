/** @format */

import Skeleton from "react-loading-skeleton";
import FormItemSkeleton from "./FormItemSkeleton";

const CheckoutLoading = ({ deliveryMethod, query, guestCheckout }) => {
  return (
    <div className="checkout_left">
      {deliveryMethod == 2 && (
        <div className="card newaddress border-0">
          <div className="card-header fw-bold text-red" />
          <div className="card-body d-flex-row">
            <div className="addresss1">
              <div className="row">
                <div className="col-md-12">
                  <div className="bg-light p-3 rounded-3">
                    <blockquote className="blockquote mb-2 pb-2">
                      <p className="mb-0 fw-bold fs-6">
                        <Skeleton width={250} />
                        <span>
                          {" "}
                          <a
                            href=""
                            className="text-red1"
                            data-bs-toggle="modal"
                            data-bs-target="#deliveryModal"
                          >
                            <Skeleton width={250} />
                          </a>
                        </span>
                      </p>
                    </blockquote>

                    <div class="row">
                      <div class="col-md-6">
                        <Skeleton />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="card mt-3 border-0">
        <div className="card-header fw-bold text-red" />
        <div className="card-body d-flex-row">
          <div className="addresss1">
            <div className="row">
              <div className="col-md-12">
                <div className="bg-light p-3 mt-3">
                  <div className="form-details">
                    <div className="row text-start">
                      <div className="col-md-6">
                        <FormItemSkeleton />
                      </div>
                      {guestCheckout && (
                        <>
                          <div className="col-md-6">
                            <FormItemSkeleton />
                          </div>
                          <div className="col-md-6">
                            <FormItemSkeleton />
                          </div>
                          <div className="col-md-6">
                            <FormItemSkeleton />
                          </div>
                          <div className="col-md-6">
                            <FormItemSkeleton />
                          </div>
                          <div className="col-md-6">
                            <FormItemSkeleton />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!guestCheckout && (
        <>
          <div class="card mt-3 border-0">
            <div
              style={{ background: "white" }}
              class="card-header   text-red d-flex align-items-center justify-content-between border-0"
            >
              <h6 class="fw-bold text-danger">
                <Skeleton width={300} height={25} />
              </h6>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                  {" "}
                  <Skeleton width={200} height={25} />
                  <Skeleton />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="card mt-3 border-0">
        <div className="card-header fw-bold text-red" />
        <div className="card-body d-flex-row">
          <div className="addresss1">
            <div className="row">
              <div className="col-md-12">
                <div>
                  <Skeleton
                    count={0.15}
                    inline={true}
                    style={{ marginRight: "10px" }}
                  />
                  <Skeleton count={0.15} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-header fw-bold text-red">
          Instruction Or Special Request
        </div>
        <div className="card-body d-flex-row">
          <div className="addresss1">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <Skeleton height={45} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="mybtn d-flex justify-content-between align-items-start mt-3">
            {/* <span className="text-custom-white rectangle-tag btn-dark btn-lg">
              Back
            </span> */}
            {/* <Form.Item>
              <Button
                style={{
                  background: "#5A4342",
                  border: "none",
                  color: "white",
                }}
              >
                Continue
              </Button>
            </Form.Item> */}
            {/* <a
      href="login.html"
      className="text-custom-white rectangle-tag btn-dark btn-lg ms-3"
      data-bs-toggle="modal"
    >
      Continue
    </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutLoading;
