/** @format */

import { combineEpics } from "redux-observable";
import {
  addDeliveryAddress,
  addDeliveryAddressGuest,
  authenticateUserEpic,
  changePasswordEpic,
  checkVoucherEpic,
  deleteDeliveryAddress,
  forgotPasswordEpic,
  getAllOnlineOrderSectionListEpic,
  getAllStoresEpic,
  getAllUserOrdersEpic,
  getCheckOutSectionList,
  getGuestCheckOutSectionList,
  getOrderDetailEpic,
  getOrderDetailGuestEpic,
  getProductByCategoryEpic,
  getReservationSectionList,
  getTempalateEpic,
  getTemplatesWithDetails1Epic,
  getUserDetailsEpic,
  paymentEpic,
  paymentEpicGuest,
  placeOrderEpic,
  placeOrderEpicGuest,
  registerUserEpic,
  reserveTableEpic,
  resetPasswordEpic,
  sendOTPForPhoneNumberValidation,
  sendOTPtoMail,
  updateUserDetailsEpic,
  validatePhoneNumber,
} from "./myEpics";

export const rootEpic = combineEpics(
  getTempalateEpic,
  authenticateUserEpic,
  registerUserEpic,
  addDeliveryAddress,
  deleteDeliveryAddress,
  getAllOnlineOrderSectionListEpic,
  getCheckOutSectionList,
  getGuestCheckOutSectionList,
  placeOrderEpic,
  paymentEpic,
  paymentEpicGuest,
  placeOrderEpicGuest,
  addDeliveryAddressGuest,
  getReservationSectionList,
  reserveTableEpic,
  getUserDetailsEpic,
  updateUserDetailsEpic,
  getAllUserOrdersEpic,
  getOrderDetailEpic,
  getOrderDetailGuestEpic,
  getAllStoresEpic,
  forgotPasswordEpic,
  changePasswordEpic,
  checkVoucherEpic,
  resetPasswordEpic,
  sendOTPtoMail,
  // getTemplatesWithDetailsEpic,
  getTemplatesWithDetails1Epic,
  getProductByCategoryEpic,
  sendOTPForPhoneNumberValidation,
  validatePhoneNumber
);
