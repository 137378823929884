import React from "react";
import { Alert, Checkbox, Tabs, Typography } from "antd";
import { useSelector } from "react-redux";

const ProductPriceModifiers = ({
  variationId,
  modifierList,
  activeFoodVariations,
  checkedModifier,
  setCheckedModifier,
}) => {
  const isModifierOfActiveVariation =
    activeFoodVariations?.find((item) => item.isChecked)?.id == variationId;
  const currencySymbol = useSelector(
    (state) => state.storeReducer.storeData.currencySymbol
  );
  const productModifiers = modifierList;

  const groupedModifiers = productModifiers?.reduce((acc, modifier) => {
    const { labelName } = modifier;
    if (!acc[labelName]) {
      acc[labelName] = { labelName, modifiers: [] };
    }
    acc[labelName].modifiers.push(modifier);

    return acc;
  }, {});

  const groupedModifiersArray = Object.values(groupedModifiers);

  const handleCheckboxChange = (currentTabModifiers, value) => {
    setCheckedModifier((prev) => {
      const currentTabModifierIds = currentTabModifiers.map((mod) =>
        JSON.stringify({
          id: mod.id,
          price: mod.price,
          name: mod.name,
        })
      );

      const newSelections = value;
      const retainedSelections = prev.filter(
        (mod) => !currentTabModifierIds.includes(mod)
      );
      return [...retainedSelections, ...newSelections];
    });
  };

  return (
    <div className="row">
      {/* {!isModifierOfActiveVariation && (
        <Alert
          type="warning"
          className="mb-1"
          message="Please Choose this variation first to select modifier !"
        />
      )} */}
      <p className="d-block fw-bold  mb-2 light-color-text ">
        Select Modifiers
      </p>
      <span className="d-block fs-6">
        <Tabs
          type="card"
          className="w-100"
          items={groupedModifiersArray?.map((item, index) => {
            // Prepare modifier options for Checkbox.Group
            const options = item.modifiers?.map((modifier) => ({
              label: (
                <>
                  <Typography.Text key={modifier.id}>
                    {modifier.name} {currencySymbol} {modifier.price}{" "}
                  </Typography.Text>
                </>
              ),
              value: JSON.stringify({
                id: modifier.id,
                price: modifier.price,
                name: modifier.name,
              }),
            }));

            return {
              label: item.labelName,
              key: index.toString(),
              children: (
                <>
                  <Checkbox.Group
                    // disabled={!isModifierOfActiveVariation}
                    options={options}
                    value={checkedModifier?.filter((mod) =>
                      options.map((option) => option.value).includes(mod)
                    )}
                    onChange={(value) =>
                      handleCheckboxChange(item.modifiers, value)
                    }
                  />
                </>
              ),
            };
          })}
        />
      </span>
    </div>
  );
};

export default ProductPriceModifiers;
