import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import getStripeInstance from "../../Helpers/stripe";

const StripeWrapper = ({ paymentMethodsData, children }) => {
  const ifCreditCardPresent = paymentMethodsData?.find(
    (option, index) => option.type == "7"
  );
  const publishableKeyStore = paymentMethodsData?.find(
    (option, index) => option.type == "7"
  )?.publishableKey;
  if (ifCreditCardPresent) {
    return (
      <Elements stripe={getStripeInstance(publishableKeyStore ?? "")}>
        {children}
      </Elements>
    );
  } else {
    return (
      <Elements stripe={getStripeInstance(publishableKeyStore ?? "")}>
        {children}
      </Elements>
    );
  }
};

export default StripeWrapper;
