/** @format */

import { Result } from "antd";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Route, Routes } from "react-router-dom";
import ServerErrorPage from "../../Components/Error/ServerError";
import LoadingScreen from "../../Components/LoadingScreen/LoadingScreen";
import { openNotificationWithIcon } from "../../Components/Notification/Success";
import StripeWrapper from "../../Components/StripeWrapper/StripeWrapper";
import { API } from "../../Helpers/BaseUrlProvider";
import {
  getCookie,
  getLocalStorage,
  isAuth,
} from "../../Helpers/FrontendHelper";
import PrivateRoute from "../../Helpers/PrivateRoute/PrivateRoute";
import MyOrderDinein from "../Stores/BakeryTemplate/Pages/MyOrderDinein";
import Checkout from "../Stores/DynamicPages/DynamicCheckout";
import GuestCheckout from "../Stores/DynamicPages/DynamicGuestCheckout";
import Profile from "../Stores/DynamicPages/DynamicProfile";
import NormalTemplate from "../Stores/NormalTemplate";
import RestaurantTemplate from "./RestaurantTemplate";

// import Button from "../../Components/Button/index.tsx";

const RootPage = () => {
  const selectedUrls = [
    "https://deliverypickup.au",
    "https://deliverypickup.ca",
    "https://develop.deliverypickup.online",
    "https://deliverypickup.online",
  ];
  const dispatch = useDispatch();
  const { storeId, hasError, errorMessage, isUnderMaintenance } = useSelector(
    (state) => state.storeReducer.storeData
  );
  const [isCssLoaded, setIsCssLoaded] = useState(false);
  const storeState = useSelector(
    (state) => state.storeReducer.storeData.templateName
  );
  const { checkoutSectionList } = useSelector((state) => state.checkout);
  // Different reducers have properties such as show, type, message and description which has been used for popup notification in the app.

  const { show, type, message, description } = useSelector(
    (state) => state.StateManager
  );

  const {
    show: authShow,
    type: authType,
    message: authMessage,
    description: authDescription,
  } = useSelector((state) => state.authentication);

  const {
    show: storeReducerShow,
    type: storeReducerType,
    message: storeReducerMessage,
    description: storeReducerDescription,
  } = useSelector((state) => state.storeReducer);

  const {
    show: profileShow,
    type: profileType,
    message: profileMessage,
    description: profileDescription,
  } = useSelector((state) => state.profile);

  const {
    show: checkoutShow,
    type: checkoutType,
    message: checkoutMessage,
    description: checkoutDescription,
  } = useSelector((state) => state.checkout);

  useEffect(() => {
    if (selectedUrls.includes(window.location.origin)) {
      return;
    } else {
      if (window.location.pathname.length < 5) {
        dispatch({
          type: "GET_TEMPLATE_REQUEST",
          payload: {
            Url: window.location.origin,
            Slug: "",
            Type: "",
          },
        });
      }
    }
  }, []);

  // Open Notification whenever show is true in profile reducer

  useEffect(() => {
    if (profileShow) {
      setTimeout(() => {
        dispatch(openNotificationWithIcon(profileType, profileMessage));
      }, [800]);
    }
  }, [profileShow, profileType, profileMessage, profileDescription]);

  // Open Notification whenever show is true in store reducer

  useEffect(() => {
    if (storeReducerShow) {
      setTimeout(() => {
        dispatch(
          openNotificationWithIcon(storeReducerType, storeReducerMessage)
        );
      }, [800]);
    }
  }, [
    storeReducerShow,
    storeReducerType,
    storeReducerMessage,
    storeReducerDescription,
  ]);

  // Open Notification whenever show is true in auth reducer

  useEffect(() => {
    if (authShow) {
      dispatch(openNotificationWithIcon(authType, authMessage));
    }
  }, [authShow, authType, authMessage, authDescription]);

  // Open Notification whenever show is true in checkout reducer

  useEffect(() => {
    if (checkoutShow) {
      setTimeout(() => {
        dispatch(openNotificationWithIcon(checkoutType, checkoutMessage));
      }, [800]);
    }
  }, [checkoutShow, checkoutType, checkoutMessage, checkoutDescription]);

  // Closing Notification whenever show is true in the whole app

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        dispatch({
          type: "CLOSE_NOTIFICATION",
        });
      }, [2500]);
    }
  }, [show, type, message, description, dispatch]);
  // Interceptors for Configuration of API Request
  API.interceptors.request.use(
    async (config) => {
      const Token = getCookie(`token-${storeId}`);
      const userId = getLocalStorage(`${storeId}-${storeState}`)
        ?.userInformation?.userId;
      config.headers = Object.assign(
        {
          Authorization: Token ? `Bearer ${Token}` : "",
          storeId: storeId ? storeId : getLocalStorage("activeStoreId"),
          userId: userId && isAuth(storeId) ? userId : "",
          "Cache-Control": "no-cache",
        },
        config.headers
      );
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // Interceptors for session expired check

  API.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response.status == 401) {
        dispatch({
          type: "SESSION_EXPIRED",
        });
        dispatch({
          type: "LOGOUT_REQUEST",
          payload: {
            storeId,
            storeState,
          },
        });
      }
      return Promise.reject(err);
    }
  );

  // Loading different CSS based on different templates

  useEffect(() => {
    var head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "bakery.css";
    // storeState == "restaurant"
    //   ? "/restaurant.css"
    //   : storeState == "bakery" ||
    //     storeState == "wine" ||
    //     storeState == "grocery" ||
    //     storeState == "flower" ||
    //     storeState == "clothing"
    //   ? "/bakery.css"
    //   : "";

    head.appendChild(link);
    setTimeout(() => {
      setIsCssLoaded(true);
    }, []);
    return () => {
      head.removeChild(link);
    };
  }, [storeState]);

  if (isUnderMaintenance) {
    return (
      <ServerErrorPage
        title={"Under Maintenance"}
        errorMessage={errorMessage}
      />
    );
  }

  if (hasError) {
    return (
      <ServerErrorPage title={"Access Denied "} errorMessage={errorMessage} />
    );
  }

  return (
    <>
      {selectedUrls.includes(window.location.origin) ? (
        <RestaurantTemplate />
      ) : (
        <>
          <Modal
            className="reset-password login-modal"
            show={show}
            onHide={() => {
              dispatch({
                type: "CLOSE_NOTIFICATION",
              });
            }}
            footer={null}
          >
            {type == "success" && (
              <div className="main-container">
                <div className="check-container">
                  <div className="check-background">
                    <svg
                      viewBox="0 0 65 51"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 25L27.3077 44L58.5 7"
                        stroke="white"
                        strokeWidth={13}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="check-shadow" />
                </div>
              </div>
            )}
            {type == "error" && <Result status={"error"} />}

            <p className="text-message">{message} !</p>
          </Modal>
          <Outlet />
          <Routes>
            <Route
              path="/"
              element={
                storeId && isCssLoaded ? (
                  <NormalTemplate st={storeId} />
                ) : (
                  <LoadingScreen />
                )
              }
            />

            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  {" "}
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/checkout"
              element={
                <PrivateRoute>
                  {" "}
                  <StripeWrapper paymentMethodsData={checkoutSectionList}>
                    {" "}
                    <Checkout />
                  </StripeWrapper>
                </PrivateRoute>
              }
            />
            <Route
              path="/guestcheckout"
              element={
                <StripeWrapper paymentMethodsData={checkoutSectionList}>
                  <GuestCheckout />
                </StripeWrapper>
              }
            />
            <Route path="/current-order" element={<MyOrderDinein />} />
          </Routes>
        </>
        // window.location.pathname.length < 5 &&
        // storeId?.length > 10 && <Store st={storeId} />
      )}
    </>
  );
};

export default RootPage;
