import React from "react";
import Skeleton from "react-loading-skeleton";
import { Form, Button } from "antd";
import FormItemSkeleton from "./FormItemSkeleton";

const ReserveTableSkeleton = () => {
  return (
    <Form
      className="login reserve-table"
      name="form"
      autoComplete="off"
      initialValues={{
        remember: true,
      }}
    >
      <div
        className="card-body d-flex-row inner_reserve position-relative"
        style={{
          background:
            "url(https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=jpeg,quality=50/media/photos/4721014c-cd88-451d-b101-51b3cf86e627-retina-large-jpeg) no-repeat",
        }}
      >
        <div className="addresss1">
          <div className="overlay1" />
          <div className="row position-relative pr1">
            <div className="col-md-12">
              <div className="form-details bg-white p-4">
                <div className="row text-start">
                  <div className="col-md-12 bg-light p-3 mb-3 heightscroll">
                    <div className="row">
                      <div className="col-md-2">
                        <Skeleton inline={true} />
                        <Skeleton inline={true} />
                      </div>
                      <div className="col-md-2">
                        <Skeleton inline={true} />
                        <Skeleton inline={true} />
                      </div>
                      <div className="col-md-2">
                        <Skeleton inline={true} />
                        <Skeleton inline={true} />
                      </div>
                      <div className="col-md-2">
                        <Skeleton inline={true} />
                        <Skeleton inline={true} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <FormItemSkeleton />
                  </div>
                  <div className="col-md-3">
                    <FormItemSkeleton />
                  </div>
                  <div className="col-md-3">
                    <FormItemSkeleton />
                  </div>
                  <div className="col-md-3">
                    <FormItemSkeleton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-footer fw-bold text-black p-4">
        <div className="row">
          <div className="col-md-6">
            <Skeleton height={25} />
          </div>
          <div className="col-md-6 text-end">
            {/* <Form.Item>
              <Button
                className="btn btn-lg btn-success rounded-3 me-3 myButton"
                type="primary"
                htmlType="submit"
                style={{
                  background: "#198753",
                  border: "none",
                  height: "52.5px",
                }}
              >
                Reserve
              </Button>
            </Form.Item> */}
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ReserveTableSkeleton;
