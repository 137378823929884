/** @format */

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const MyOrderDineInSkeleton = () => {
  return (
    <div className="container my-5 dine-in-order-container">
      <div className="card shadow-sm dine-in-custom-card">
        <div className="card-header dine-in-custom-card-header">
          <div className="dine-in-header-content">
            <button type="button" className="dine-in-back-button">
              <Skeleton circle width={24} height={24} />
            </button>
            <h4>
              <Skeleton width={200} height={24} />
            </h4>
          </div>
        </div>

        <div className="card-body dine-in-card-body d-flex flex-column">
          {/* Table Details Skeleton */}
          <div className="dine-in-table-details mb-4">
            <h5 className="dine-in-section-title">
              <Skeleton width={150} height={20} />
            </h5>
            <p>
              <strong>Table Name:</strong> <Skeleton width={100} height={20} />
            </p>
            <p>
              <strong>Table ID:</strong> <Skeleton width={100} height={20} />
            </p>
          </div>

          {/* Order Details Skeleton */}
          <div className="dine-in-order-details mb-4 flex-grow-1">
            <h5 className="dine-in-section-title">
              <Skeleton width={150} height={20} />
            </h5>
            <ul className="list-group dine-in-list-group">
              {[...Array(3)].map((_, index) => (
                <li
                  key={index}
                  className="list-group-item dine-in-list-group-item d-flex justify-content-between align-items-center"
                >
                  <Skeleton width={200} height={20} />
                  <Skeleton width={50} height={20} />
                </li>
              ))}
            </ul>
          </div>

          {/* Sticky Total and Button Skeleton */}
          <div className="dine-in-sticky-bottom mt-auto">
            <div className="dine-in-total-amount text-right mb-4 ms-2">
              <h5>
                <Skeleton width={150} height={20} />
              </h5>
              <h5 className="mt-2">
                <Skeleton width={150} height={20} />
              </h5>
            </div>

            {/* <div className="text-center">
              <Skeleton
                className="btn dine-in-custom-btn btn-lg"
                height={50}
                width={200}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrderDineInSkeleton;
