/** @format */

import { Button, Checkbox, DatePicker, Form, Input, Select } from "antd";
import { useEffect } from "react";
import Collapse from "react-bootstrap/Collapse";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationWithIcon } from "../../Components/Notification/Success.tsx";
import { getLocalStorage, isAuth } from "../../Helpers/FrontendHelper";

import moment from "moment";
import { useState } from "react";
import ReserveTableSkeleton from "../Skeletons/ReserveTableSkeleton";

const { Option } = Select;
const { TextArea } = Input;
const ReserveTable = ({ setReserverTableModalOpen }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const countryList = useSelector(
    (state) => state.storeReducer.homePageData?.listCountry
  );
  const [selectedPhoneNumberPrefix, setSelectedPhoneNumberPrefix] = useState();

  const dateFormat = useSelector(
    (state) => state.storeReducer.storeData.dateFormat
  );
  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);
  const storeState = useSelector(
    (state) => state.storeReducer.storeData.templateName
  );
  const selectedDate = Form.useWatch("dateTime", form);
  // const countryId = Form.useWatch("country", form);

  const {
    reservationSectionList,
    reservationLoading,
    isOperationSuccessfull,
    isLoading,
    getReservationLoading,
  } = useSelector((state) => state.storeReducer);
  const [selectedTable, setSelectedTable] = useState();
  const [adultCapactiy, setAdultCapacity] = useState();
  const [childCapacity, setChildCapacity] = useState();
  const [staticAdultCapacity, setStaticADultCapacity] = useState(0);
  const [staticChildCapacity, setStaticChildCapacity] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedWeekDay, setSelectedWeekDay] = useState(null);

  // useEffect(() => {
  //   if (!selectedPhoneNumberPrefix) {
  //     form.setFieldValue("prefix", countryId);
  //   }
  // }, [countryId]);

  useEffect(() => {
    if (storeId) {
      dispatch({
        type: "GET_RESERVATION_SECTION_LIST_REQUEST",
        payload: {
          storeId,
        },
      });
    }
  }, [storeId]);
  const reserveTableHandler = (values) => {
    const localStorageData = getLocalStorage(`${storeId}-${storeState}`);

    dispatch({
      type: "RESERVE_TABLE_REQUEST",
      payload: {
        storeId: storeId,
        userId: localStorageData?.userInformation?.userId
          ? localStorageData?.userInformation?.userId
          : null,
        id: "",
        Adult: String(adultCapactiy),
        Child: String(childCapacity),
        TableIds: selectedTable?.map((item) => item.id),
        DateTimeFrom:
          dateFormat == "yyyy/MM/dd HH:mm:ss"
            ? moment(values.dateTime).format("YYYY/MM/DD") + " " + values.time
            : dateFormat == "MM/dd/yyyy HH:mm:ss"
            ? moment(values.dateTime).format("MM/DD/YYYY") + " " + values.time
            : dateFormat == "dd/MM/yyyy HH:mm:ss"
            ? moment(values.dateTime).format("DD/MM/YYYY") + " " + values.time
            : "",
        CustomerViewModel: localStorageData?.userInformation?.userId
          ? null
          : {
              Id: null,
              Name: values.fullName,
              Email: values.email,
              PhoneNumber: values.phone,
              CountryPhoneNumberPrefixId: values.prefix,
              CountryId: values.country,
              PostalCode: values.postalCode,
            },
      },
    });
    // form.resetFields();
  };
  useEffect(() => {
    if (selectedTable?.length > 0) {
      setAdultCapacity(
        selectedTable
          .map((item) => parseInt(item.adultCapacity))
          .reduce((partialSum, a) => partialSum + a, 0)
      );
      setStaticADultCapacity(
        selectedTable
          .map((item) => parseInt(item.adultCapacity))
          .reduce((partialSum, a) => partialSum + a, 0)
      );
      setChildCapacity(
        selectedTable
          .map((item) => parseInt(item.childCapacity))
          .reduce((partialSum, a) => partialSum + a, 0)
      );
      setStaticChildCapacity(
        selectedTable
          .map((item) => parseInt(item.childCapacity))
          .reduce((partialSum, a) => partialSum + a, 0)
      );
    }
  }, [selectedTable]);
  useEffect(() => {
    if (isOperationSuccessfull) {
      form.resetFields();
      setChildCapacity("");
      setAdultCapacity("");
    }
  }, [isOperationSuccessfull]);
  const finishFailedHandler = () => {
    setOpen(true);
  };
  const prefixSelector = (
    <Form.Item name={"prefix"} noStyle>
      <Select
        onChange={(e) => {
          setSelectedPhoneNumberPrefix(e);
        }}
        style={{
          width: 120,
        }}
      >
        {countryList.length > 0 &&
          countryList?.map((item) => (
            <Option value={item.id} key={item.id}>
              {" "}
              <span style={{ fontSize: "14px" }}>
                <img
                  src={item.image}
                  style={{
                    height: "20px",
                    width: "30px",
                    marginRight: "4px",
                  }}
                />
                {item.additionalValue}
              </span>
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
  const getTimeArray = (selectedWeekDay) => {
    let arr = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        arr.push({
          label: `${i < 10 ? "0" + i : i}:${j === 0 ? `00` : 15 * j}`,
          value: `${i < 10 ? "0" + i : i}:${j === 0 ? `00` : 15 * j}:00`,
        });
      }
    }
    let startIndex;
    let endIndex;

    startIndex = arr.findIndex((item) => {
      return (
        item.label ==
        reservationSectionList?.pickUpDeliveryHours?.find(
          (item) => item.weekDayName == selectedWeekDay
        )?.openHours
      );
    });
    endIndex = arr.findIndex((item) => {
      return (
        item.label ==
        reservationSectionList?.pickUpDeliveryHours?.find(
          (item) => item.weekDayName == selectedWeekDay
        )?.closeHours
      );
    });

    arr = arr.filter(
      (item, index) => index >= startIndex && index <= endIndex - 3
    );

    return arr;
  };

  useEffect(() => {
    setSelectedWeekDay(
      moment(selectedDate)
        .format("dddd")
        ?.toLowerCase("")
    );
  }, [selectedDate]);
  return (
    <div>
      <div className="modal-body reserve log1">
        <div className="card ">
          <div className="card-header fw-bold text-red p-4 d-flex justify-content-between align-items-center">
            <h3>Reserve table Now</h3>
            <i
              onClick={() => {
                setReserverTableModalOpen(false);
              }}
              className="fas fa-times btn-close1"
              data-bs-dismiss="modal"
              aria-label="close"
            />
          </div>
          {getReservationLoading ? (
            <ReserveTableSkeleton />
          ) : (
            <Form
              onFinishFailed={finishFailedHandler}
              className="login reserve-table"
              name="form"
              fields={[
                {
                  name: ["adultCapacity"],
                  value: adultCapactiy,
                },
                {
                  name: ["childCapacity"],
                  value: childCapacity,
                },
                {
                  name: ["prefix"],
                  value: countryList?.find((item) => item.isSelected)?.id,
                },
                {
                  name: ["country"],
                  value:
                    countryList.length > 0 &&
                    countryList?.find((item) => item.isSelected)?.id,
                },
              ]}
              onFinish={reserveTableHandler}
              autoComplete="off"
              initialValues={{
                remember: true,
              }}
              form={form}
            >
              <div
                className="card-body d-flex-row inner_reserve position-relative"
                style={{
                  background:
                    "url(https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=jpeg,quality=50/media/photos/4721014c-cd88-451d-b101-51b3cf86e627-retina-large-jpeg) no-repeat",
                }}
              >
                <div className="addresss1">
                  <div className="overlay1" />
                  <div className="row position-relative pr1">
                    <div className="col-md-12">
                      <div className="form-details bg-white p-4">
                        <div className="row text-start">
                          <div className="col-md-12 bg-light p-3 mb-3 heightscroll">
                            <div className="row">
                              {reservationSectionList?.tables?.length > 0 ? (
                                <>
                                  <Form.Item
                                    name="tables"
                                    className="mobile"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please select any of the tables",
                                      },
                                    ]}
                                  >
                                    <label
                                      className="mobile"
                                      style={{ marginBottom: "10px" }}
                                    >
                                      Select Tables
                                    </label>
                                    <Select
                                      className="mobile mobile-select"
                                      onChange={(e) => {
                                        const newdata = e.map((ind) => {
                                          return reservationSectionList?.tables[
                                            ind
                                          ];
                                        });
                                        form.setFieldValue("tables", newdata);
                                        setSelectedTable(newdata);
                                      }}
                                      mode="multiple"
                                      placeholder="Please select tables"
                                      // onChange={handleChange}
                                      style={{
                                        width: "100%",
                                        height: "40px",
                                        marginBottom: "-2rem",
                                      }}
                                      options={reservationSectionList?.tables?.map(
                                        (table, ind) => {
                                          return {
                                            value: ind,
                                            label:
                                              table?.name +
                                              `  ( ${
                                                table.adultCapacity
                                              } Adult ,` +
                                              ` ${
                                                table.childCapacity
                                              } Child  ) `,
                                          };
                                        }
                                      )}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name="tables"
                                    className="desktop"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please select any of the tables",
                                      },
                                    ]}
                                  >
                                    <Checkbox.Group
                                      className="desktop"
                                      value={selectedTable}
                                      onChange={(e) => {
                                        setSelectedTable(e);
                                      }}
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {reservationSectionList?.tables?.map(
                                        (item, index) => {
                                          return (
                                            <div
                                              className="col-md-2 col-6 mb-2"
                                              key={item.id}
                                            >
                                              {" "}
                                              <Checkbox
                                                key={item.id}
                                                value={item}
                                              >
                                                {item.name}
                                              </Checkbox>{" "}
                                              <div className="tablebox">
                                                <p>
                                                  {item.adultCapacity} Adult,{" "}
                                                  {item.childCapacity} Child
                                                </p>
                                                <p>{item.location}</p>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </Checkbox.Group>
                                  </Form.Item>
                                </>
                              ) : (
                                <div className="d-flex align-items-center justify-content-center flex-column">
                                  {" "}
                                  <img
                                    style={{ width: "15%" }}
                                    src={"Assets/assets/image/noorders.png"}
                                  />
                                  <p>No Tables To Reserve At the moment !</p>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-3">
                            <Form.Item
                              label="Date"
                              name="dateTime"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input date !",
                                },
                              ]}
                            >
                              <DatePicker
                                disabledDate={(date) => {
                                  return (
                                    moment(date).format("DD/MM/YYYY") <
                                    moment().format("DD/MM/YYYY")
                                  );
                                }}
                                size="small"
                                autoFocus={false}
                                format={dateFormat.split(" ")[0]?.toUpperCase()}
                                className="checkout-date-picker"
                                placeholder={`Enter Date`}
                                style={{
                                  width: "100%",
                                  height: "45px",
                                }}
                                inputReadOnly={true}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6 col-lg-3">
                            <Form.Item
                              label="Time"
                              name="time"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input  time!",
                                },
                              ]}
                            >
                              <Select
                                disabled={!selectedDate}
                                options={getTimeArray(selectedWeekDay)}
                                placeholder="Select Time"
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6 col-lg-3">
                            <div>
                              <label htmlFor="" className="mt-2">
                                Child Capacity
                              </label>
                              <div className="input-group mb-3">
                                <div class="qty-box">
                                  <div class="quantity buttons_added">
                                    <input
                                      disabled={
                                        staticChildCapacity < 1 ? true : false
                                      }
                                      type="button"
                                      value="-"
                                      class="minus"
                                      onClick={() => {
                                        if (childCapacity > 0) {
                                          setChildCapacity((prev) => prev - 1);
                                        } else {
                                          return;
                                        }
                                      }}
                                    />
                                    <input
                                      disabled={
                                        selectedTable < 1 ? true : false
                                      }
                                      type="text"
                                      style={{
                                        caretColor: "transparent",
                                        cursor: "default",
                                      }}
                                      step="1"
                                      min="1"
                                      max="10"
                                      value={childCapacity}
                                      // onChange={() => {}}
                                      class="qty text"
                                      size="4"
                                      readOnly=""
                                    />
                                    <input
                                      disabled={
                                        staticChildCapacity < 1 ? true : false
                                      }
                                      type="button"
                                      value="+"
                                      class="plus"
                                      onClick={() => {
                                        if (
                                          parseInt(childCapacity) <=
                                          parseInt(staticChildCapacity - 1)
                                        ) {
                                          setChildCapacity((prev) => prev + 1);
                                        } else {
                                          return openNotificationWithIcon(
                                            "info",
                                            `You can't set the capacity more than ${staticChildCapacity}`
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-3 adult-capacity">
                            <div>
                              <label htmlFor="" className="mt-2">
                                Adult Capacity
                              </label>
                              <div className="input-group mb-3">
                                <div class="qty-box">
                                  <div class="quantity buttons_added">
                                    <input
                                      disabled={
                                        staticAdultCapacity < 1 ? true : false
                                      }
                                      type="button"
                                      value="-"
                                      class="minus"
                                      onClick={() => {
                                        if (adultCapactiy > 0) {
                                          setAdultCapacity((prev) => prev - 1);
                                        } else {
                                          return;
                                        }
                                      }}
                                    />
                                    <input
                                      disabled={
                                        selectedTable < 1 ? true : false
                                      }
                                      type="text"
                                      style={{
                                        caretColor: "transparent",
                                        cursor: "default",
                                      }}
                                      step="1"
                                      min="1"
                                      max="10"
                                      value={adultCapactiy}
                                      // onChange={() => {}}
                                      class="qty text"
                                      size="4"
                                      readOnly=""
                                    />
                                    <input
                                      disabled={
                                        setAdultCapacity < 1 ? true : false
                                      }
                                      type="button"
                                      value="+"
                                      class="plus"
                                      onClick={() => {
                                        if (
                                          parseInt(adultCapactiy) <=
                                          parseInt(staticAdultCapacity - 1)
                                        ) {
                                          setAdultCapacity((prev) => prev + 1);
                                        } else {
                                          return openNotificationWithIcon(
                                            "info",
                                            `You can't set the capacity more than ${staticChildCapacity}`
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        {!isAuth(storeId) && (
                          <div className="bg-light">
                            <div className="mt-3">
                              {" "}
                              <a
                                onClick={() => setOpen(!open)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                                style={{ marginBottom: "1rem" }}
                                className="advanced btn btn-lg secondary-button rounded-3 me-3 collapsed"
                              >
                                {" "}
                                Add Contact Details{" "}
                                <i className="fa fa-angle-down" />
                              </a>
                              <Collapse in={open}>
                                <div id="example-collapse-text">
                                  <div className="card card-body">
                                    <div className="row">
                                      <div className="col-md-6 col-lg-4">
                                        <Form.Item
                                          label="Full Name"
                                          name="fullName"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please input your full name!",
                                            },
                                          ]}
                                        >
                                          <Input
                                            type="text"
                                            placeholder="Enter Full Name"
                                            class="form-control"
                                          />
                                        </Form.Item>
                                      </div>
                                      <div className="col-md-6 col-lg-4">
                                        <Form.Item
                                          label="Email"
                                          name="email"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please input your email!",
                                            },
                                            {
                                              type: "email",
                                              message:
                                                "Please enter valid email !",
                                            },
                                          ]}
                                        >
                                          <Input
                                            type="text"
                                            placeholder="Enter email "
                                            class="form-control"
                                          />
                                        </Form.Item>
                                      </div>
                                      <div className="col-md-6 col-lg-4">
                                        <Form.Item
                                          label="Phone Number"
                                          name="phone"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please input your phone!",
                                            },
                                            {
                                              type: "phone",
                                              message:
                                                "Please enter valid phone number!",
                                            },
                                          ]}
                                        >
                                          <Input
                                            addonBefore={prefixSelector}
                                            placeholder="Enter Phone"
                                            class="form-control"
                                          />
                                        </Form.Item>
                                      </div>
                                      <div className="col-md-6 col-lg-4">
                                        <Form.Item
                                          label="Country"
                                          name="country"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please input Country !",
                                            },
                                          ]}
                                        >
                                          <Select
                                            style={{
                                              height: "48px",
                                              paddingBottom: "1rem",
                                            }}
                                          >
                                            {countryList.length > 0 &&
                                              countryList?.map((item) => (
                                                <Select
                                                  value={item.id}
                                                  key={item.id}
                                                >
                                                  {" "}
                                                  <span
                                                    style={{
                                                      fontSize: "14px",
                                                    }}
                                                  >
                                                    <img
                                                      src={item.image}
                                                      style={{
                                                        height: "20px",
                                                        width: "30px",
                                                        marginRight: "4px",
                                                      }}
                                                    />
                                                    {item.value}
                                                  </span>
                                                </Select>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      </div>
                                      <div className="col-md-6 col-lg-4">
                                        <Form.Item
                                          label="Postal Code"
                                          name="postalCode"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter postal code !",
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder="Enter Postal Code"
                                            class="form-control"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-footer fw-bold text-black p-4">
                <div className="row">
                  <div className="col-md-6">
                    {reservationSectionList.tableOccupancyMessage}
                  </div>
                  <div className="col-md-6 text-end">
                    <Form.Item>
                      <Button
                        disabled={
                          reservationSectionList?.tables?.length > 0
                            ? false
                            : true
                        }
                        className="btn btn-lg primary-button rounded-3 me-3 myButton"
                        type="primary"
                        htmlType="submit"
                        loading={reservationLoading}
                        style={{
                          border: "none",
                          height: "52.5px",
                        }}
                      >
                        Reserve
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReserveTable;
