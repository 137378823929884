/** @format */

import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Breadcrumb,
  Button,
  Carousel,
  DatePicker,
  Form,
  Input,
  Modal,
  Pagination,
  Select,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MyOrder from "../../../Components/MyOrder/MyOrder";
import OrderDetail from "../../../Components/OrderDetail/OrderDetail";
import { getLocalStorage } from "../../../Helpers/FrontendHelper";
import { navigateHelper } from "../../../Helpers/NavigateHelper";

import Skeleton from "react-loading-skeleton";
import { Footer } from "../../../Components/Footer/Footer";
import Navbar from "../../../Components/Navbar/Navbar";
import ProfileSkeleton from "../../../Components/Skeletons/ProfileSkeleton";

const { RangePicker } = DatePicker;
const { Option } = Select;

function Profile() {
  const paramValue = useParams();
  const activeTabs = useSelector((state) => state.profile.activeTab);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [changePasswordForm] = Form.useForm();
  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);
  const [activeTab, setActiveTab] = useState(activeTabs);
  const [isOrderDetailVisible, setIsOrderDetailVisible] = useState(false);
  const [activeOrder, setActiveOrder] = useState(null);
  const dateFormat = useSelector(
    (state) => state.storeReducer.storeData.dateFormat
  );
  const bannerImages = useSelector(
    (state) =>
      state.storeReducer.storeData.bannerImagewithBannerTypeListViewModels
  );
  const currencySymbol = useSelector(
    (state) => state.storeReducer.storeData.currencySymbol
  );

  const storeState = useSelector(
    (state) => state.storeReducer.storeData.templateName
  );
  const changePasswordLoading = useSelector(
    (state) => state.authentication.isLoading
  );
  const isPasswordChangedSucces = useSelector(
    (state) => state.authentication.isPasswordChangedSucces
  );
  const {
    userProfileData,
    isLoading,
    isOperationSuccessful,
    userOrderHistory,
    updateUserDetailLoading,
  } = useSelector((state) => state.profile);
  const countryList = useSelector(
    (state) => state.storeReducer.homePageData?.listCountry
  );

  const homePageData = useSelector((state) => state.storeReducer.homePageData);
  const [orderFilterFrom, setOrderFilterFrom] = useState(
    moment().subtract(1, "month")
  );
  const [orderFilterTo, setOrderFilterTo] = useState(moment());
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [userImage, setUserImage] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [isFooterLoaded, setIsFooterLoaded] = useState(false);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  const updatedCartData = getLocalStorage(`${storeId}-${storeState}`);

  useEffect(() => {
    if (updatedCartData?.userInformation?.userId) {
      dispatch({
        type: "GET_USER_DETAIL_REQUEST",
        payload: {
          UserId: updatedCartData?.userInformation?.userId,
          storeId,
          storeState,
        },
      });
    }
  }, [updatedCartData?.userInformation?.userId]);
  useEffect(() => {
    if (isOperationSuccessful) {
      dispatch({
        type: "GET_USER_DETAIL_REQUEST",
        payload: {
          UserId: updatedCartData?.userInformation?.userId,
          storeId,
          storeState,
        },
      });
    }
  }, [isOperationSuccessful]);
  useEffect(() => {
    setTimeout(() => {
      setIsFooterLoaded(true);
    }, [1500]);
  }, []);

  const updateProfileHandler = (values) => {
    let myDataform = new FormData();
    myDataform.append(
      "Request",
      JSON.stringify({
        Id: updatedCartData?.userInformation?.userId,
        FullName: values.fullName,
        Email: values.Email,
        PhoneNumber: values.phone,
        CountryId: values.country,
        CountryPhoneNumberPrefixId: values.prefix,
        PostalCode: values.postalCode,
      })
    );
    myDataform.append("Image", userImage);
    dispatch({
      type: "UDPATE_USER_DETAIL_REQUEST",
      payload: {
        data: myDataform,
        storeId,
        userId: updatedCartData?.userInformation?.userId,
        lsUpdateData: {
          fullName: values.fullName,
          storeId: storeId,
          storeState: storeState,
        },
      },
    });
  };

  useEffect(() => {
    if (activeTab == "second") {
      dispatch({
        type: "GET_ORDER_HISTORY_REQUEST",
        payload: {
          data: {
            Page: currentPage,
            PageSize: pageSize,
            SearchKeywords: "",
            ExternalFilter: {
              OrderedDateFrom:
                moment(orderFilterFrom).format(
                  dateFormat.toUpperCase().split(" ")[0]
                ) + " 00:00:00",
              OrderedDateTo:
                moment(orderFilterTo).format(
                  dateFormat.toUpperCase().split(" ")[0]
                ) + " 00:00:00",
            },
          },
          storeId,
          userId: updatedCartData?.userInformation?.userId,
        },
      });
    }
  }, [activeTab, currentPage, pageSize]);

  const searchHandler = () => {
    dispatch({
      type: "GET_ORDER_HISTORY_REQUEST",
      payload: {
        data: {
          Page: 1,
          PageSize: 10,
          SearchKeywords: orderNumber,
          ExternalFilter: {
            OrderedDateFrom:
              moment(orderFilterFrom).format(
                dateFormat.toUpperCase().split(" ")[0]
              ) + " 00:00:00",
            OrderedDateTo:
              moment(orderFilterTo).format(
                dateFormat.toUpperCase().split(" ")[0]
              ) + " 00:00:00",
          },
        },
        storeId,
        userId: updatedCartData?.userInformation?.userId,
      },
    });
  };
  const onChange = (dates, dateStrings) => {
    if (dates) {
      setOrderFilterFrom(dateStrings[0]);
      setOrderFilterTo(dateStrings[1]);
    }
  };

  const changePasswordHandler = (values) => {
    dispatch({
      type: "CHANGE_PASSWORD_REQUEST",
      payload: {
        userId: updatedCartData?.userInformation?.userId,
        storeId,
        data: {
          Email: updatedCartData?.userInformation?.email,
          OldPassword: values.currentPassword,
          newPassword: values.newPassword,
        },
      },
    });
  };
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 120,
        }}
      >
        {countryList.length > 0 &&
          countryList?.map((item) => (
            <Option value={item.id}>
              {" "}
              <span style={{ fontSize: "14px" }}>
                <img
                  src={item.image}
                  style={{ height: "20px", width: "30px", marginRight: "4px" }}
                />
                {item.additionalValue}
              </span>
            </Option>
          ))}
      </Select>
    </Form.Item>
  );

  function onShowSizeChange(current, pageSize) {
    window.scrollTo(0, 0);
    setCurrentPage(current);
    dispatch({
      type: "GET_ORDER_HISTORY_REQUEST",
      payload: {
        data: {
          Page: current,
          PageSize: pageSize,
          SearchKeywords: orderNumber ? orderNumber : "",
          ExternalFilter: {
            OrderedDateFrom: moment(orderFilterFrom).format(
              "MM/DD/YYYY 00:00:00"
            ),
            OrderedDateTo: moment(orderFilterTo).format("MM/DD/YYYY 00:00:00"),
          },
        },
        storeId,
        userId: updatedCartData?.userInformation?.userId,
      },
    });
  }
  useEffect(() => {
    if (isPasswordChangedSucces) {
      changePasswordForm.resetFields();
    }
  }, [isPasswordChangedSucces]);
  return (
    <>
      <>
        {" "}
        <Navbar
          homePageData={homePageData}
          storeState={storeState}
          updatedCartData={updatedCartData}
        />
        {/* ***** Header Area End ***** */}
        {/* vertical tab */}
        <MyOrder displayNone={true} />
        <>
          <div className="main-body">
            <Modal
              style={{ top: "2rem" }}
              visible={isOrderDetailVisible}
              onCancel={() => {
                setIsOrderDetailVisible(false);
              }}
              className="order-detail-modal"
              width={"60vw"}
              footer={null}
            >
              {activeOrder != null && <OrderDetail activeOrder={activeOrder} />}
            </Modal>
            <div className="container">
              <div className="topslider home_slider pt-8 d-none d-md-block " />
              <Carousel>
                {bannerImages?.find((item) => item.bannerType == 4)?.banners[0]
                  ?.image ? (
                  bannerImages
                    ?.find((item) => item.bannerType == 4)
                    ?.banners.map((item) => {
                      return (
                        <div>
                          <img
                            src={item.image}
                            className="d-block w-100 banner-image"
                            alt="..."
                          />
                        </div>
                      );
                    })
                ) : (
                  <div>
                    <img
                      src={"/Assets/assets/images/profile.png"}
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                )}
              </Carousel>
              <Breadcrumb className="mt-2 d-md-none d-block">
                <Breadcrumb.Item href="">
                  <HomeOutlined />
                  <Link
                    to={
                      query.get("TableId")
                        ? `${navigateHelper(
                            paramValue.type,
                            paramValue.storeName
                          )}?TableId=${query.get("TableId")}`
                        : `${navigateHelper(
                            paramValue.type,
                            paramValue.storeName,
                            true
                          )}`
                    }
                  >
                    Home
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <UserOutlined />
                  <span className="mt-4">Profile</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            {/* vertical tab */}

            <section className="pt-3 pb-3">
              <div className="container">
                <Tab.Container
                  onSelect={(e) => setActiveTab(e)}
                  id="left-tabs-example"
                  defaultActiveKey={activeTab}
                >
                  <Row style={{ display: "flex", flexDirection: "row" }}>
                    <div className="col-md-4">
                      <div className=" profile-card">
                        <div
                          className="card-body"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            className="user-info red-bg"
                            style={{ display: "flex", gap: "1rem" }}
                          >
                            {getLocalStorage(`${storeId}-${storeState}`)
                              ?.userInformation?.normalImage ? (
                              <img
                                class="img-xs rounded-circle"
                                src={
                                  getLocalStorage(`${storeId}-${storeState}`)
                                    ?.userInformation?.normalImage
                                }
                                alt="Profile image"
                              />
                            ) : (
                              <Avatar
                                style={{
                                  backgroundColor: "#f56a00",
                                  verticalAlign: "middle",
                                }}
                                size={50}
                              >
                                {
                                  getLocalStorage(`${storeId}-${storeState}`)
                                    ?.userInformation?.fullName?.[0]
                                }
                              </Avatar>
                            )}

                            <div className="user-info-inner">
                              <h5 itemProp="headline">
                                <a title="" itemProp="url">
                                  {
                                    getLocalStorage(`${storeId}-${storeState}`)
                                      ?.userInformation?.fullName
                                  }
                                </a>
                              </h5>
                              <span>
                                <a title="" itemProp="url">
                                  {
                                    getLocalStorage(`${storeId}-${storeState}`)
                                      ?.userInformation?.email
                                  }
                                </a>
                              </span>
                              <a
                                onClick={() => {
                                  navigate(
                                    `/${navigateHelper(
                                      paramValue.type,
                                      paramValue.storeName
                                    )}`
                                  );
                                  dispatch({
                                    type: "LOGOUT_REQUEST",
                                    payload: {
                                      storeId,
                                      storeState,
                                    },
                                  });
                                }}
                                className="brd-rd3 sign-out-btn black-bg"
                                title=""
                                itemProp="url"
                                style={{
                                  color: "white",
                                  background: "black",
                                }}
                              >
                                <i className="fa fa-sign-out" /> SIGN OUT
                              </a>
                            </div>
                          </div>

                          <Nav
                            variant="pills"
                            className="flex-column"
                            style={{ marginTop: "1rem", gap: "4px" }}
                          >
                            <Nav.Item>
                              <Nav.Link eventKey="first">
                                {" "}
                                <i
                                  className="fas fa-cog"
                                  style={{ marginRight: "5px" }}
                                />{" "}
                                Account Settings
                              </Nav.Link>
                            </Nav.Item>
                            <hr style={{ margin: "0" }} />
                            <Nav.Item>
                              <Nav.Link eventKey="second">
                                {" "}
                                <i
                                  className="fas fa-home"
                                  style={{ marginRight: "5px" }}
                                />{" "}
                                Order History
                              </Nav.Link>
                            </Nav.Item>
                            <hr style={{ margin: "0" }} />

                            <Nav.Item>
                              <Nav.Link eventKey="third">
                                {" "}
                                <i
                                  className="fas fa-key"
                                  style={{ marginRight: "5px" }}
                                />{" "}
                                Change Password
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                      </div>
                    </div>

                    <div className="tab-contents">
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          {isLoading ? (
                            <ProfileSkeleton />
                          ) : (
                            <Form
                              className="login profile"
                              name="form"
                              onFinish={updateProfileHandler}
                              autoComplete="off"
                              initialValues={{
                                remember: true,
                              }}
                              form={form}
                              fields={[
                                {
                                  name: ["country"],
                                  value: userProfileData?.countryId
                                    ? userProfileData?.countryId
                                    : countryList.length > 0 &&
                                      countryList?.find(
                                        (item) => item.isSelected
                                      )?.id,
                                },
                                {
                                  name: ["fullName"],
                                  value: form.getFieldValue("fullName")
                                    ? form.getFieldValue("fullName")
                                    : userProfileData?.name,
                                },
                                {
                                  name: ["Email"],
                                  value: form.getFieldValue("Email")
                                    ? form.getFieldValue("Email")
                                    : userProfileData?.email,
                                },
                                {
                                  name: ["phone"],
                                  value: form.getFieldValue("phone")
                                    ? form.getFieldValue("phone")
                                    : userProfileData?.phoneNumber,
                                },
                                {
                                  name: ["prefix"],
                                  value: form.getFieldValue("prefix")
                                    ? form.getFieldValue("prefix")
                                    : userProfileData?.countryPhoneNumberPrefixId,
                                },
                                {
                                  name: ["postalCode"],
                                  value: form.getFieldValue("postalCode")
                                    ? form.getFieldValue("postalCode")
                                    : userProfileData?.postalCode,
                                },
                              ]}
                            >
                              <div
                                className="tab-pane"
                                id="account-vertical"
                                role="tabpanel"
                                aria-labelledby="account-vertical-tab"
                              >
                                <div className="profile-card">
                                  <div className="card-body">
                                    <div className=" bg-white">
                                      <h4 className="pb-4 border-bottom">
                                        Account settings
                                      </h4>
                                      <div className="d-flex align-items-start py-3 border-bottom">
                                        {" "}
                                        {getLocalStorage(
                                          `${storeId}-${storeState}`
                                        )?.userInformation?.normalImage ? (
                                          <img
                                            class="img-xs rounded-circle"
                                            src={
                                              getLocalStorage(
                                                `${storeId}-${storeState}`
                                              )?.userInformation?.normalImage
                                            }
                                            alt="Profile image"
                                          />
                                        ) : (
                                          <Avatar
                                            style={{
                                              backgroundColor: "#f56a00",
                                              verticalAlign: "middle",
                                            }}
                                            size={70}
                                          >
                                            {
                                              getLocalStorage(
                                                `${storeId}-${storeState}`
                                              )?.userInformation?.fullName?.[0]
                                            }
                                          </Avatar>
                                        )}
                                        <div
                                          className="ps-sm-4 ps-2"
                                          id="img-section"
                                        >
                                          {" "}
                                          <b>Profile Photo</b>
                                          <p>
                                            Accepted file type .png. Less than
                                            1MB
                                          </p>{" "}
                                          <input
                                            type="file"
                                            onChange={(e) =>
                                              setUserImage(e.target.files[0])
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="py-2">
                                        <div className="row py-2">
                                          <div className="col-md-6 pt-md-0 pt-3">
                                            {" "}
                                            <Form.Item
                                              label="Full Name"
                                              name="fullName"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input your full Name!",
                                                },
                                              ]}
                                            >
                                              <Input
                                                style={{
                                                  borderRadius: "10px",
                                                }}
                                                placeholder="Enter Full Name"
                                                className="bg-light form-control"
                                              />
                                            </Form.Item>
                                          </div>
                                          <div className="col-md-6">
                                            {" "}
                                            <Form.Item
                                              label="Email Address"
                                              name="Email"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input your email!",
                                                },
                                                {
                                                  type: "email",
                                                  message:
                                                    "Please enter valid email!",
                                                },
                                              ]}
                                            >
                                              <Input
                                                readOnly
                                                placeholder="Enter Email"
                                                style={{
                                                  borderRadius: "10px",
                                                }}
                                                className="bg-light form-control"
                                              />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row py-2">
                                          <div className="col-md-6 pt-md-0 pt-3">
                                            {" "}
                                            <Form.Item
                                              label="Phone Number"
                                              name="phone"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input your phone number!",
                                                },
                                              ]}
                                            >
                                              <Input
                                                className="phone-profile"
                                                addonBefore={prefixSelector}
                                                placeholder="Enter Phone"
                                                style={{
                                                  borderRadius: "10px",
                                                }}
                                              />
                                            </Form.Item>
                                          </div>
                                          <div className="col-md-6 pt-md-0 pt-3">
                                            {" "}
                                            <Form.Item
                                              label="Country"
                                              name="country"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input your country!",
                                                },
                                              ]}
                                            >
                                              <Select
                                                style={{
                                                  borderRadius: "20px",
                                                }}
                                                className="bg-light"
                                              >
                                                {countryList.length > 0 &&
                                                  countryList?.map((item) => (
                                                    <Select.Option
                                                      value={item.id}
                                                    >
                                                      {" "}
                                                      <span
                                                        style={{
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {item.value}
                                                      </span>
                                                    </Select.Option>
                                                  ))}
                                              </Select>
                                            </Form.Item>
                                          </div>
                                          <div className="row py-2">
                                            <div className="col-md-6">
                                              {" "}
                                              <Form.Item
                                                label="Postal Code"
                                                name="postalCode"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Please input your postal code!",
                                                  },
                                                ]}
                                              >
                                                <Input
                                                  placeholder="Enter Postal Code"
                                                  style={{
                                                    borderRadius: "10px",
                                                  }}
                                                  className="bg-light form-control"
                                                />
                                              </Form.Item>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="">
                                          {" "}
                                          <Form.Item>
                                            <Button
                                              loading={updateUserDetailLoading}
                                              type="primary"
                                              htmlType="submit"
                                              className="primary-button"
                                              style={{ height: "40px" }}
                                            >
                                              Save Changes
                                            </Button>{" "}
                                          </Form.Item>
                                          {/* <button className="btn border button">
                                      Cancel
                                    </button>{" "} */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          {isLoading ? (
                            <div
                              class="tab-pane fade show active profile"
                              id="dashboard-vertical"
                              role="tabpanel"
                              aria-labelledby="dashboard-vertical-tab"
                            >
                              <div class="card card0 border-0 ">
                                <div class="dashboard-title">
                                  <h4 class="fw-bold text-red">
                                    Order History
                                  </h4>
                                </div>
                                <div class="all_restro">
                                  <div class="card mt-3 border-0 bg-light">
                                    <div class="card-body">
                                      <div class="d-block d-md-flex justify-content-start justify-content-sm-start align-items-center pt-2 profile ">
                                        <div class="d-flex flex-wrap">
                                          <div class="d-flex align-items-center flex-nowrap pb-3">
                                            <label
                                              class="text-dark opacity-75 text-nowrap fs-sm me-2 d-none d-sm-block"
                                              for="sorting"
                                            >
                                              Search By:
                                            </label>

                                            <RangePicker
                                              defaultPickerValue={[
                                                moment().subtract(1, "month"),
                                                moment(),
                                              ]}
                                              value={[
                                                moment(orderFilterFrom),
                                                moment(orderFilterTo),
                                              ]}
                                              // showTime={{ format: "HH:mm" }}
                                              format="YYYY/MM/DD"
                                              onChange={onChange}
                                              // onOk={onOk}
                                            />
                                          </div>
                                        </div>

                                        <div class="d-flex flex-wrap ms-0 ms-md-2">
                                          <div class="d-flex align-items-center flex-nowrap pb-3">
                                            <input
                                              type="text"
                                              class="form-control"
                                              placeholder="Order Number"
                                              spellcheck="false"
                                              data-ms-editor="true"
                                              value={orderNumber}
                                              onChange={(e) =>
                                                setOrderNumber(e.target.value)
                                              }
                                            />
                                            <Form.Item>
                                              {/* <Button
                                            disabled={
                                              storesCartData?.orderDetails
                                                ?.length == 0
                                                ? true
                                                : false
                                            }
                                            loading={checkoutLoading}
                                            style={{
                                              background: "#5A4342",
                                              border: "none",
                                              color: "white",
                                            }}
                                          >
                                            Continue
                                          </Button> */}
                                              <a
                                                type="primary"
                                                onClick={searchHandler}
                                                class="btn-first green-btn text-custom-white fw-500 w-100 ms-2 h-100 d-flex align-items-center justify-content-center"
                                              >
                                                Search
                                              </a>
                                            </Form.Item>
                                          </div>
                                        </div>
                                      </div>
                                      <table class="table">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <strong>Order Id</strong>
                                            </td>
                                            <td>
                                              <strong>Order Date</strong>
                                            </td>
                                            <td>
                                              <strong>Order Status</strong>
                                            </td>
                                            <td>
                                              <strong>Total Amount</strong>
                                            </td>
                                            <td>
                                              <strong>Order Details</strong>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                            <td>
                                              <Skeleton />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  {/* <!-- end --> */}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              class="tab-pane fade show active profile"
                              id="dashboard-vertical"
                              role="tabpanel"
                              aria-labelledby="dashboard-vertical-tab"
                            >
                              <div
                                class="profile-card card0 border-0 "
                                style={{ background: "white" }}
                              >
                                <h4
                                  style={{
                                    paddingLeft: "9px",
                                    fontWeight: "bold",
                                  }}
                                  className="pb-3 pl-3 pt-3 border-bottom"
                                >
                                  Order History
                                </h4>
                                <div class="all_restro">
                                  <div class="card mt-3 border-0 bg-light">
                                    <div class="card-body">
                                      <div class="d-block d-md-flex justify-content-start justify-content-sm-start align-items-center pt-2 profile ">
                                        <div class="d-flex flex-wrap">
                                          <div class="d-flex align-items-center flex-nowrap pb-3">
                                            <label
                                              class="text-dark opacity-75 text-nowrap fs-sm me-2 d-none d-sm-block"
                                              for="sorting"
                                            >
                                              Search By:
                                            </label>

                                            <RangePicker
                                              defaultPickerValue={[
                                                moment().subtract(1, "month"),
                                                moment(),
                                              ]}
                                              value={[
                                                moment(orderFilterFrom),
                                                moment(orderFilterTo),
                                              ]}
                                              // showTime={{ format: "HH:mm" }}
                                              format="YYYY/MM/DD"
                                              onChange={onChange}
                                              // onOk={onOk}
                                            />
                                          </div>
                                        </div>

                                        <div class="d-flex flex-wrap ms-0 ms-md-2">
                                          <div class="d-flex align-items-center flex-nowrap pb-3">
                                            <input
                                              type="text"
                                              class="bg-light form-control"
                                              placeholder="Order Number"
                                              spellcheck="false"
                                              data-ms-editor="true"
                                              value={orderNumber}
                                              onChange={(e) =>
                                                setOrderNumber(e.target.value)
                                              }
                                            />
                                            <Form.Item>
                                              <a
                                                type="primary"
                                                onClick={searchHandler}
                                                class="btn-first green-btn text-custom-white fw-500 w-100 ms-2 h-100 d-flex align-items-center justify-content-center"
                                              >
                                                Search
                                              </a>
                                            </Form.Item>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="table-responsive">
                                        <table class="table">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <strong>Order Id</strong>
                                              </td>
                                              <td>
                                                <strong>Order Date</strong>
                                              </td>
                                              <td>
                                                <strong>Order Status</strong>
                                              </td>
                                              <td>
                                                <strong>Total Amount</strong>
                                              </td>
                                              <td>
                                                <strong>Order Details</strong>
                                              </td>
                                            </tr>

                                            {userOrderHistory?.data?.length >
                                            0 ? (
                                              userOrderHistory?.data?.map(
                                                (item) => {
                                                  return (
                                                    <tr>
                                                      <td>
                                                        {item.orderNumber}
                                                      </td>
                                                      <td>{item.orderDate}</td>
                                                      <td>
                                                        <span
                                                          class="badge"
                                                          style={
                                                            item.statusEnumValue ==
                                                            "1"
                                                              ? {
                                                                  background:
                                                                    "red",
                                                                }
                                                              : item.statusEnumValue ==
                                                                "8"
                                                              ? {
                                                                  background:
                                                                    "red",
                                                                }
                                                              : item.statusEnumValue ==
                                                                "7"
                                                              ? {
                                                                  background:
                                                                    "green",
                                                                }
                                                              : item.statusEnumValue ==
                                                                "2"
                                                              ? {
                                                                  background:
                                                                    "orange",
                                                                }
                                                              : item.statusEnumValue ==
                                                                "3"
                                                              ? {
                                                                  background:
                                                                    "black",
                                                                }
                                                              : {
                                                                  background:
                                                                    "blue",
                                                                }
                                                          }
                                                        >
                                                          {item.status}
                                                        </span>
                                                      </td>
                                                      <td>
                                                        {currencySymbol}{" "}
                                                        {item.totalAmount}
                                                      </td>
                                                      <td>
                                                        <a
                                                          class=""
                                                          onClick={() => {
                                                            setActiveOrder(
                                                              item
                                                            );
                                                            setIsOrderDetailVisible(
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            class="fas fa-eye edit"
                                                            aria-hidden="true"
                                                          />
                                                        </a>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <tr>
                                                <td align="center" colSpan={6}>
                                                  {" "}
                                                  <img
                                                    style={{ width: "30%" }}
                                                    src={
                                                      "/Assets/assets/images/noorders.png"
                                                    }
                                                  />
                                                  <p>No Orders Found !</p>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <Pagination
                                          current={currentPage}
                                          total={
                                            userOrderHistory?.total
                                              ? userOrderHistory?.total
                                              : 2
                                          }
                                          showSizeChanger
                                          onShowSizeChange={onShowSizeChange}
                                          onChange={onShowSizeChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {/* <!-- end --> */}
                                </div>
                              </div>
                            </div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <Form
                            className="login profile change-password"
                            name="form"
                            onFinish={changePasswordHandler}
                            autoComplete="off"
                            initialValues={{
                              remember: true,
                            }}
                            form={changePasswordForm}
                          >
                            <div
                              className="tab-pane"
                              id="account-vertical"
                              role="tabpanel"
                              aria-labelledby="account-vertical-tab"
                            >
                              <div className="profile-card acc_setting">
                                <div className="card-body">
                                  <div className=" bg-white">
                                    <h4
                                      style={{}}
                                      className="pb-3 pl-3 border-bottom"
                                    >
                                      Change Password
                                    </h4>
                                    <div className="py-2">
                                      <div className="row py-2">
                                        <div className="col-md-12">
                                          {" "}
                                          <Form.Item
                                            label="Current Password"
                                            name="currentPassword"
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please input your current password!",
                                              },
                                            ]}
                                          >
                                            <Input.Password
                                              type={"password"}
                                              style={{ borderRadius: "10px" }}
                                              placeholder="Enter Current Password"
                                              className="bg-light form-control login-password"
                                            />
                                          </Form.Item>
                                        </div>
                                        <div className="col-md-12 pt-md-0 pt-3">
                                          {" "}
                                          <Form.Item
                                            label="New Password"
                                            name="newPassword"
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please input your new password!",
                                              },
                                            ]}
                                          >
                                            <Input.Password
                                              type={"password"}
                                              style={{ borderRadius: "10px" }}
                                              placeholder="Enter New Password"
                                              className="bg-light form-control login-password"
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          {" "}
                                          <Form.Item
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please input confirm password!",
                                              },
                                              ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                  if (
                                                    !value ||
                                                    getFieldValue(
                                                      "newPassword"
                                                    ) === value
                                                  ) {
                                                    return Promise.resolve();
                                                  }
                                                  return Promise.reject(
                                                    new Error(
                                                      "The two passwords that you entered do not match!"
                                                    )
                                                  );
                                                },
                                              }),
                                            ]}
                                          >
                                            <Input.Password
                                              type={"password"}
                                              placeholder="Enter Confirm Password"
                                              style={{ borderRadius: "10px" }}
                                              className="bg-light form-control login-password"
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>

                                      <div className="">
                                        {" "}
                                        <Form.Item>
                                          <Button
                                            loading={changePasswordLoading}
                                            type="primary"
                                            htmlType="submit"
                                            className="btn btn-primary mr-3 primary-button"
                                            style={{
                                              height: "40px",
                                              marginTop: "13px",
                                            }}
                                          >
                                            Change Password
                                          </Button>{" "}
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Row>
                </Tab.Container>
              </div>
            </section>
          </div>
        </>
        {isFooterLoaded && <Footer />}
      </>
    </>
  );
}

export default Profile;
