/** @format */

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import BakerySkeleton from "../../Components/Skeletons/BakerySkeleton";
import RestaurantSkeleton from "../../Components/Skeletons/RestaurantSkeleton";
import { getCookie, setLocalStorage } from "../../Helpers/FrontendHelper";
import BakeryTemplate from "./BakeryTemplate/BakeryTemplate";
import RestaurantTemplate from "./Restaurant/RestaurantTemplate";

function NormalTemplate({ st }) {
  const dispatch = useDispatch();
  const paramValue = useParams();

  const storeState = useSelector(
    (state) => state.storeReducer.storeData.templateName
  );
  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);
  const themeColors = useSelector(
    (state) => state.storeReducer.homePageData?.themeColors
  );
  const searchKeyword = useSelector(
    (state) => state.StateManager.searchKeyword
  );
  const {
    homePageData,
    getProductByCategoryLoading,
    allTemplateData,
  } = useSelector((state) => state.storeReducer);
  const [isLoadingHome, setIsLoadingHome] = useState(true);
  const [count, setCount] = useState(1);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  // Changing favicon dynamically based on the store state

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = homePageData?.storeDetails?.image
      ? homePageData?.storeDetails?.image
      : storeState == "restaurant"
      ? "/assets1/dist/image/restaurant.png"
      : storeState == "bakery"
      ? "/assets1/dist/image/bakery.png"
      : storeState == "wine"
      ? "/assets1/dist/image/beverages.png"
      : storeState == "grocery"
      ? "/assets1/dist/image/grocery.png"
      : storeState == "flower"
      ? "/assets1/dist/image/flower.png"
      : storeState == "clothing"
      ? "https://develop.deliverypickup.online/assets1/dist/image/bakery.png"
      : "";
    // document.title = homePageData?.storeDetails?.storeName
    //   ? homePageData?.storeDetails?.storeName
    //   : "Online Order";
  }, [homePageData, storeState]);

  useEffect(() => {
    const Token = getCookie(`token-${storeId}`);
    if (!count > 0) {
      setIsLoadingHome(true);
    }
    if (count >= 1 && searchKeyword) {
      return;
    }

    axios({
      method: "POST",
      url: `${
        process.env.NODE_ENV == "production"
          ? process.env.REACT_APP_APP_LIVE_URL
          : process.env.REACT_APP_APP_TEST_URL
      }/api/onlineorder/getAllOnlineOrderStoreSectionList?cacheBuster=${Date.now()}`,
      data: { tableId: query.get("TableId") ? query.get("TableId") : "" },
      headers: {
        Authorization: `Bearer ${Token}`,
        storeId: st,
      },
    })
      .then((response) => {
        setTimeout(() => {
          setIsLoadingHome(false);
        }, []);
        setLocalStorage(`homePageData-${storeId}`, response.data);
        if (count == 1) {
          dispatch({
            type: "GET_ALL_ONLINE_ORDER_SECTION_LIST_SUCCESS",
            payload: response.data,
          });
        }
        if (count > 1) {
          dispatch({
            type: "GET_ALL_UPDATED_ONLINE_ORDER_SECTION_LIST_SUCCESS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        setIsLoadingHome(false);
        dispatch({
          type: "GET_ALL_ONLINE_ORDER_SECTION_LIST_FAILURE",
          payload: error.response,
        });
      });
  }, [count]);

  // Dynamically change the color of the website based on the color types

  useEffect(() => {
    if (themeColors?.["theme-color"]) {
      document.documentElement.style.setProperty(
        "--theme-color",
        themeColors?.["theme-color"]
      );
    }
    if (themeColors?.["secondary-color"]) {
      document.documentElement.style.setProperty(
        "--secondary-color",
        themeColors?.["secondary-color"]
      );
    }
    if (themeColors?.["button-color"]) {
      document.documentElement.style.setProperty(
        "--button-color",
        themeColors?.["button-color"]
      );
    }
    if (themeColors?.["icon-color"]) {
      document.documentElement.style.setProperty(
        "--icon-color",
        themeColors?.["icon-color"]
      );
    }
    if (themeColors?.["alert-color-success"]) {
      document.documentElement.style.setProperty(
        "--alert-color-success",
        themeColors?.["alert-color-success"]
      );
    }
    if (themeColors?.["alert-color-info"]) {
      document.documentElement.style.setProperty(
        "--alert-color-info",
        themeColors?.["alert-color-info"]
      );
    }
    if (themeColors?.["body-bg-color"]) {
      document.documentElement.style.setProperty(
        "--body-bg-color",
        themeColors?.["body-bg-color"]
      );
    }
    if (themeColors?.["alert-color-info"]) {
      document.documentElement.style.setProperty(
        "--cart-number-color",
        themeColors?.["cart-number-color"]
      );
    }
  }, [themeColors]);

  if (isLoadingHome) {
    switch (storeState) {
      case "restaurant":
        return <RestaurantSkeleton />;
      case "bakery":
        return <BakerySkeleton />;
      case "wine":
        return <BakerySkeleton />;
      case "flower":
        return <BakerySkeleton />;
      case "clothing":
        return <BakerySkeleton />;
      case "grocery":
        return <BakerySkeleton />;
    }
  }

  // Rendering different templates based on the data of api

  return (
    <>
      {storeState == "restaurant" && (
        <RestaurantTemplate
          isLoadingHome={isLoadingHome}
          homePageData={homePageData}
          storeState={storeState}
        />
      )}

      {storeState == "bakery" && (
        <BakeryTemplate
          isLoadingHome={isLoadingHome}
          homePageData={homePageData}
          storeState={storeState}
        />
      )}
      {storeState == "wine" && (
        <BakeryTemplate
          isLoadingHome={isLoadingHome}
          homePageData={homePageData}
          storeState={storeState}
        />
      )}
      {storeState == "flower" && (
        <BakeryTemplate
          isLoadingHome={isLoadingHome}
          homePageData={homePageData}
          storeState={storeState}
        />
      )}
      {storeState == "clothing" && (
        <BakeryTemplate
          isLoadingHome={isLoadingHome}
          homePageData={homePageData}
          storeState={storeState}
        />
      )}
      {storeState == "grocery" && (
        <BakeryTemplate
          isLoadingHome={isLoadingHome}
          homePageData={homePageData}
          storeState={storeState}
        />
      )}
    </>
  );
}

export default NormalTemplate;
